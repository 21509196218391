import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { addMedicRecordDetailRequest, eidtMedicRecordDetailRequest, updateMedicRecordDetailRequest } from '../../actions/MedicalRecordDetail/AcMedicalRecordDetail';
import { actFetchMedicRecordCateRequest } from '../../actions/MedicalRecord/AcMedicalRecord';


class MedicRecordDetailActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            RowID: '',
            PatientReceiveID: '',
            ClinicCode: '',
            PatientCode: '',
            MedicalRecordCode: '',
            ItemName: '',
            ItemCode: '',
            Active: '',
            ItemContent: '',
            UnitOfMeasureName: '',
            UsageName: '',
            DateOfIssues: '',
            S_Tr_Ch_T: '',
            Quantity: '',
            Instruction: '',
            UnitPrice: '',
            Amount: '',
            ObjectCode: '',
            isToaVe: '',
            PostingDate: '',
            STT: ''
        }
        this.handleChangePostingDate = this.handleChangePostingDate.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditMedicRecordDetail(id);
        }
        this.props.getCateMedicRecordCategory();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingMedicRecordDetail !== this.props.EditingMedicRecordDetail) {

                this.props.EditingMedicRecordDetail.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        PatientReceiveID: menuItem.PatientReceiveID,
                        ClinicCode: menuItem.ClinicCode,
                        PatientCode: menuItem.PatientCode,
                        MedicalRecordCode: menuItem.MedicalRecordCode,
                        ItemName: menuItem.ItemName,
                        ItemCode: menuItem.ItemCode,
                        Active: menuItem.Active,
                        ItemContent: menuItem.ItemContent,
                        UnitOfMeasureName: menuItem.UnitOfMeasureName,
                        UsageName: menuItem.UsageName,
                        DateOfIssues: menuItem.DateOfIssues,
                        S_Tr_Ch_T: menuItem.S_Tr_Ch_T,
                        Quantity: menuItem.Quantity,
                        Instruction: menuItem.Instruction,
                        UnitPrice: menuItem.UnitPrice,
                        Amount: menuItem.Amount,
                        ObjectCode: menuItem.ObjectCode,
                        isToaVe: menuItem.isToaVe,
                        PostingDate: new Date(Date.parse(moment(menuItem.datePostingDate).format('YYYY-MM-DD') + ' ' + menuItem.timePostingDate.substring(11, 19))),
                        STT: menuItem.STT

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, PatientReceiveID, ClinicCode, PatientCode, MedicalRecordCode, ItemName, ItemCode,
            Active, ItemContent, UnitOfMeasureName, UsageName, DateOfIssues, S_Tr_Ch_T, Quantity,
            Instruction, UnitPrice, Amount, ObjectCode, isToaVe, PostingDate, STT } = this.state;
       // var { history } = this.props;

        const PostingDateID = moment(PostingDate).format('YYYYMMDDHHmmss');

        var PatientReceiveID1 =  this.props.medicrecords
        .filter(commentReply => commentReply.ClinicCode === ClinicCode)
        .map((commentReply, idx) => commentReply.PatientReceiveID );

        var PatientCode1 =  this.props.medicrecords
        .filter(commentReply => commentReply.ClinicCode === ClinicCode)
        .map((commentReply, idx) => commentReply.PatientCode );

        var MedicalRecordCode1 =  this.props.medicrecords
        .filter(commentReply => commentReply.ClinicCode === ClinicCode)
        .map((commentReply, idx) => commentReply.MedicalRecordCode );



        e.preventDefault();

        if (id) {
            var MedicRecordDetailS = {
                RowID: id,
                PatientReceiveID: PatientReceiveID1? PatientReceiveID1 : PatientReceiveID,
                ClinicCode: ClinicCode,
                PatientCode: PatientCode1? PatientCode1 : PatientCode,
                MedicalRecordCode: MedicalRecordCode1? MedicalRecordCode1 : MedicalRecordCode,
                ItemName: ItemName,
                ItemCode: ItemCode,
                Active: Active,
                ItemContent: ItemContent,
                UnitOfMeasureName: UnitOfMeasureName,
                UsageName: UsageName,
                DateOfIssues: DateOfIssues,
                S_Tr_Ch_T: S_Tr_Ch_T,
                Quantity: Quantity,
                Instruction: Instruction,
                UnitPrice: UnitPrice,
                Amount: Amount,
                ObjectCode: ObjectCode,
                isToaVe: isToaVe,
                PostingDate: PostingDateID,
                STT: STT
            }
            this.props.onUpdateMedicRecordDetail(MedicRecordDetailS);
           // history.goBack(window.location.reload(true));

        } else {
            var MedicRecordDetail = {
                RowID: Math.floor(Math.random() * 10000000000),
                PatientReceiveID: PatientReceiveID1,
                ClinicCode: ClinicCode,
                PatientCode: PatientCode1,
                MedicalRecordCode: MedicalRecordCode1,
                ItemName: ItemName,
                ItemCode: ItemCode,
                Active: Active,
                ItemContent: ItemContent,
                UnitOfMeasureName: UnitOfMeasureName,
                UsageName: UsageName,
                DateOfIssues: DateOfIssues,
                S_Tr_Ch_T: S_Tr_Ch_T,
                Quantity: Quantity,
                Instruction: Instruction,
                UnitPrice: UnitPrice,
                Amount: Amount,
                ObjectCode: ObjectCode,
                isToaVe: isToaVe,
                PostingDate: PostingDate ? moment(PostingDate).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                STT: STT
            }
            this.props.onAddMedicRecordDetail(MedicRecordDetail);
           // history.goBack(window.location.reload(true));
        }
         window.location.href = '/medical-record-detail';

    }
    handleChangePostingDate = PostingDate => {
        this.setState({
            PostingDate
        });
    };
    categoryMedicRecord() {
        if (this.props.medicrecords) {
            return (
                this.props.medicrecords.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.MedicalRecordCode}</option>);
                })
            )
        }
    }

    render() {
        var { ClinicCode, ItemName, ItemCode,
            Active, ItemContent, UnitOfMeasureName, UsageName, DateOfIssues, S_Tr_Ch_T, Quantity,
            Instruction, UnitPrice, Amount, ObjectCode, isToaVe, PostingDate, STT } = this.state;


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Chi tiết thuốc</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >LS Khám bệnh</label>
                                                <select value={ClinicCode} className="form-control" onChange={this.onChange} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryMedicRecord()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên</label>
                                                <input type="text"
                                                    name="ItemName"
                                                    className="form-control"
                                                    value={ItemName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã code</label>
                                                <input type="text"
                                                    name="ItemCode"
                                                    className="form-control"
                                                    value={ItemCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Hoạt chất</label>
                                                <input type="text"
                                                    name="Active"
                                                    className="form-control"
                                                    value={Active}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Hàm lượng</label>
                                                <input type="text"
                                                    name="ItemContent"
                                                    className="form-control"
                                                    value={ItemContent}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Đơn vị tính</label>
                                                <input type="text"
                                                    name="UnitOfMeasureName"
                                                    className="form-control"
                                                    value={UnitOfMeasureName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Đường dùng</label>
                                                <input type="text"
                                                    name="UsageName"
                                                    className="form-control"
                                                    value={UsageName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Số ngày cấp</label>
                                                <input type="text"
                                                    name="DateOfIssues"
                                                    className="form-control"
                                                    value={DateOfIssues}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >S-T-C-T</label>
                                                <input type="text"
                                                    name="S_Tr_Ch_T"
                                                    className="form-control"
                                                    value={S_Tr_Ch_T}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Số lượng</label>
                                                <input type="text"
                                                    name="Quantity"
                                                    className="form-control"
                                                    value={Quantity}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Chỉ dẫn</label>
                                                <input type="text"
                                                    name="Instruction"
                                                    className="form-control"
                                                    value={Instruction}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Đơn giá</label>
                                                <input type="text"
                                                    name="UnitPrice"
                                                    className="form-control"
                                                    value={UnitPrice}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tiền</label>
                                                <input type="text"
                                                    name="Amount"
                                                    className="form-control"
                                                    value={Amount}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="form-group">
                                                        <label >Mã đối tượng</label>
                                                        <input type="text"
                                                            name="ObjectCode"
                                                            className="form-control"
                                                            value={ObjectCode}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label >STT</label>
                                                        <input type="text"
                                                            name="STT"
                                                            className="form-control"
                                                            value={STT}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Toa về</label>
                                                <input type="text"
                                                    name="isToaVe"
                                                    className="form-control"
                                                    value={isToaVe}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày kê toa</label>
                                                <DatePicker name="PostingDate" value={PostingDate ? PostingDate : new Date()} onChange={this.handleChangePostingDate}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btnAdd">
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/medical-record-detail" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        medicrecords: state.medicrecords,
        EditingMedicRecordDetail: state.EditingMedicRecordDetail
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddMedicRecordDetail: (medicrecorddetails) => {
            dispatch(addMedicRecordDetailRequest(medicrecorddetails));
        },
        onEditMedicRecordDetail: (id) => {
            dispatch(eidtMedicRecordDetailRequest(id));
        },
        onUpdateMedicRecordDetail: (medicrecorddetails) => {
            dispatch(updateMedicRecordDetailRequest(medicrecorddetails));
        },
        getCateMedicRecordCategory: () => {
            dispatch(actFetchMedicRecordCateRequest());
        },


    }

}
export default connect(mapStateToProps, mapDispatchToProps)(MedicRecordDetailActionPage);