import React, { Component } from 'react';
import DoctorList from './../../components/Doctors/DoctorList'
import DoctorItem from './../../components/Doctors/DoctorItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import { actFetchDoctorRequest,deleteDoctorRequest,updateisLockedDoctorRequest } from '../../actions/Doctors/AcDoctors';


class DoctorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idDoctor:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
        }

    }
    componentDidMount(){
        this.props.fetchAllDoctor();
        this.props.getProfileFetch();
       
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.idDoctor && this.state.idDoctor !== '') {
           
            this.props.fetchAllDoctor();
            this.props.getProfileFetch();
            this.setState({ idDoctor: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idDoctor: id })
        this.props.onUpdateStatusDoctor(id);
    }
  

    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idDoctor: id })
            this.props.onDeleteDoctor(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };

    render() {
        var { doctors } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
      
        var rowsPerPage = [];
        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        rowsPerPage = doctors.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3 className="card-title">Bác sĩ</h3>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/doctor/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
                <div className="pageMainShow">
                <DoctorList>
                    {this.showDoctor(rowsPerPage) }
                </DoctorList>
                <p className="totalPhongKham">
                    Tổng cộng: {doctors.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={doctors.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />
                </div>


            </div>
        );
    }
    showDoctor(doctors) {
        var result = null;
        if (doctors.length > 0) {
         
            result = doctors.map((doctor, index) => {
                return (
                    <DoctorItem
                        key={index}
                        doctor={doctor}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        doctors: state.doctors,
        payload: state.payload,
        clinics: state.clinics
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllDoctor: () => {
            dispatch(actFetchDoctorRequest());
        },
        onDeleteDoctor: (id) => {
            dispatch(deleteDoctorRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
      
        onUpdateStatusDoctor: (id) => {
            dispatch(updateisLockedDoctorRequest(id));
        },
       
      
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPage);