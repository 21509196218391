import * as Types from '../../constants/ServiceRadiologyEntry/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchServiceRadiologyEntryRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('service-radiology-entry', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchServiceRadiologyEntry(res.data));
                }
            })
        }
    }
 
}
export const actFetchServiceRadiologyEntry = (serviceradios) => {
    return {
        type: Types.FETCH_SERVICE_RADIOLOGY_ENTRY,
        serviceradios
    }
}

export const deleteServiceRadiologyEntryRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-service-radiology-entry/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteServiceRadiologyEntry(id));
        })
    }
}
export const deleteServiceRadiologyEntry = (id) => {
    return {
        type: Types.DELETE_SERVICE_RADIOLOGY_ENTRY,
        id
    }
}

export const addServiceRadiologyEntryRequest = (serviceradios) => {
    return (dispatch) => {
        return UrlApi('add-service-radiology-entry', 'POST', serviceradios, authHeader()).then(res => {
            dispatch(addServiceRadiologyEntry(res.data));
        })
    }
}

export const addServiceRadiologyEntry = (serviceradios) => {
    return {
        type: Types.ADD_SERVICE_RADIOLOGY_ENTRY,
        serviceradios
    }
}


export const eidtServiceRadiologyEntryRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`service-radiology-entry/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editServiceRadiologyEntry(res.data));
            }

        })
    }
}
export const editServiceRadiologyEntry = (serviceradios) => {
    return {
        type: Types.EDIT_SERVICE_RADIOLOGY_ENTRY,
        serviceradios
    }
}


export const updateServiceRadiologyEntryRequest = (serviceradios) => {
    return (dispatch) => {
        return UrlApi(`update-service-radiology-entry/${serviceradios.id}`, 'PUT', serviceradios, authHeader()).then(res => {
            dispatch(updateServiceRadiologyEntry(res.data));

        })
    }
}
export const updateServiceRadiologyEntry = (serviceradios) => {
    return {
        type: Types.UPDATE_SERVICE_RADIOLOGY_ENTRY,
        serviceradios
    }
}
