import React, { Component } from 'react';
class MedicRecordDetailList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <div className="Tableauto">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th >Mã TN</th>
                                            <th >Mã PK</th>
                                            <th >Mã BN</th>
                                            <th >Mã LS</th>
                                            <th >Tên</th>
                                            <th >ItemCode</th>
                                            <th >Hoạt chất</th>
                                            <th >Hàm lượng</th>
                                            <th >ĐVT</th>
                                            <th >Đường dùng</th>
                                            <th >Ngày cấp</th>
                                            <th >cách dùng</th>
                                            <th >Số lượng</th>
                                            <th >Chỉ dẫn</th>
                                            <th >Đơn giá</th>
                                            <th >Tiền</th>
                                            <th >Mã đối tượng</th>
                                            <th >isToaVe</th>
                                            <th >Ngày kê toa</th>
                                            <th >STT</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default MedicRecordDetailList;

