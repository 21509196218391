import UrlApi from '../utils/UrlApi';
import { authHeader } from './helpers';

export const userLoginFetch = (user) => {
   
    return (dispatch) => {

        return UrlApi('login', 'POST', user).then(res => {
            if (res === undefined) {
                window.confirm('Không thể kết nối được!');
            } else {
                if (res.data === 'TAIKHOAN_KHONGTONTAI') {
                    dispatch(loginUser(res.data));
                } else {
                    localStorage.setItem("token", JSON.stringify(res.data.accessToken));
                    dispatch(loginUser(res.data));
                    window.location.reload();
                }
            }


        })
    }
}
/*
export const checkAuthentication = () => {
    return (dispatch)=>{
        let refresh_token = JSON.parse(localStorage.getItem('refreshToken'));
        //let refresh_token = localStorage.getItem('refreshToken');
        axios.post('http://192.168.88.244:8090/api/refresh-token/', {
            "refreshToken": refresh_token
        }).then(res => {
            if (res.status === 200)  {
                console.log('sdfsdfdsfsdfdsfsdfsdfsdfsdfsdfdsf');
                localStorage.setItem("token", res.data.accessToken)
               // console.log(res);
              // dispatch(loginSuccess())
            }
        }).catch(error => {
            //dispatch(loginFailed(error))
            console.log('sdfsdfdsfsdfdsfsdfsdfsdfsdfsdfdsf',error);
        })
       


    }
}*/

export const getProfileFetch = () => {
    return (dispatch) => {
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('profile-fetch', 'POST', { "refreshToken": token }, authHeader()).then(res => {
            if (res === undefined) {
                localStorage.removeItem("token")
                window.location.reload(true);
            } else {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(loginUser(res.data))
                }
            }

        })



        /* axios.post('http://192.168.88.244:8090/api/profile-fetch/', {
             "refreshToken": token
         }).then(res => {
             dispatch(loginUser(res.data))
             
         }).catch(error => {
         })*/



    }
}


/*
export const getProfileFetch =() =>{
    return (dispatch)=>{
        const token = localStorage.token;
     
    }
}*/


export const loginUser = payload => ({
    type: 'LOGIN_USER',
    payload: payload
})

export const logoutUser = () => ({
    type: 'LOGOUT_USER'
})