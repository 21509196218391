import * as Types from '../../constants/PatientReceive/ActionTypes';
var initialState = [];

var findIndex = (patientreceives, id) => {
    var result = -1;
    patientreceives.forEach((patientreceive, index) => {
        if (patientreceive.id === id) {
            result = index;
        }
    })
    return result;
}
const patientreceives = (state = initialState, action) => {
    var index = -1;
    var { id, patientreceives } = action;
    switch (action.type) {
        case Types.FETCH_PATIENT_RECEIVE:
            state = action.patientreceives;
            return [...state];
        case Types.DELETE_PATIENT_RECEIVE:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_PATIENT_RECEIVE:
            state.push(action.patientreceives);
            return [...state];

        case Types.UPDATE_PATIENT_RECEIVE:
            index = findIndex(state, patientreceives.id);
            state[index] = patientreceives;
            return [...state];
        case Types.FETCH_PATIENT_RECEIVE_CATE:
            state = action.patientreceives;
            return [...state];
        

        default: return [...state];
    }
}
export default patientreceives;