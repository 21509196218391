import * as Types from '../../constants/HoiDap/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';


export const actFetchHoiDapRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('hoi-dap','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                   
                    dispatch(actFetchHoiDap(res.data))
                }
            }
        })
    }
}

export const actFetchHoiDap = (hoidaps) => {
    return {
        type: Types.FETCH_HOIDAP,
        hoidaps
    }
}

export const deleteHoiDapRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-hoi-dap/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteHoiDap(id));
        })
    }
}
export const deleteHoiDap = (id) => {
    return {
        type: Types.DELETE_HOIDAP,
        id
    }
}



export const addHoiDapRequest = (hoidaps) => {
    return (dispatch) => {
        return UrlApi('add-hoi-dap', 'POST', hoidaps, authHeader()).then(res => {
            dispatch(addHoiDap(res.data));
        })
    }
}

export const addHoiDap = (hoidaps) => {
    return {
        type: Types.ADD_HOIDAP,
        hoidaps
    }
}


export const eidtHoiDapRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`hoi-dap/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editHoiDap(res.data));
            }

        })
    }
}


export const editHoiDap = (hoidaps) => {
    return {
        type: Types.EDIT_HOIDAP,
        hoidaps
    }
}
export const updateHoiDapRequest = (hoidaps) => {
    return (dispatch) => {
        return UrlApi(`update-hoi-dap/${hoidaps.id}`, 'PUT', hoidaps, authHeader()).then(res => {
            dispatch(updateHoiDap(res.data));
            window.location.href = '/hoi-dap';

        })
    }
}

export const updateHoiDap = (hoidaps) => {
    return {
        type: Types.UPDATE_HOIDAP,
        hoidaps
    }
}
export const updateisLockedHoiDapRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-hoi-dap/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedHoiDap(id));
          //  window.location.reload(false);
        })
    }
}
export const updateisLockedHoiDap = (id) => {
    return {
        type: Types.UPDATE_STATUS_HOIDAP,
        id
    }
}




