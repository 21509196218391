import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { addPatientReceiveRequest, eidtPatientReceiveRequest, updatePatientReceiveRequest } from '../../actions/PatientReceive/AcPatientReceive';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
import { actFetchCategoryRequest } from '../../actions/Patients/AcPatients';



class PatientReceiveActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            PatientReceiveID: '',
            PatientCode: '',
            ClinicCode: '',
            Reason: '',
            DatetimeInto: '',
            ObjectCode: '',
            DatetimeOut: '',
            EThnicName: '',
            CareerName: '',
            NationalityName: '',
            ProvincialCode: '',
            DistrictCode: '',
            WardCode: '',
            PatientAddress: '',
            CompanyInfo: '',
            OrderNumber: '',
            PatientType: '',
            Serial: '',
            KCBBDCode: '',
            KCBBDName: '',
            StartDate: '',
            EndDate: '',
            TraiTuyen: ''
        }
        this.handleChangeDatetimeInto = this.handleChangeDatetimeInto.bind(this);
        this.handleChangeDatetimeOut = this.handleChangeDatetimeOut.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditPatientReceive(id);
        }
        this.props.fetchAllCategoryClinic();
        this.props.fetchAllCategoryPatient();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingPatientReceive !== this.props.EditingPatientReceive) {

                this.props.EditingPatientReceive.map((menuItem) => (

                    this.setState({
                        id: menuItem.PatientReceiveID,
                        PatientCode: menuItem.PatientCode,
                        ClinicCode: menuItem.ClinicCode,
                        Reason: menuItem.Reason,
                        DatetimeInto: new Date(Date.parse(moment(menuItem.DatetimeIntoDay).format('YYYY-MM-DD') + ' ' + menuItem.DatetimeIntoTime.substring(11, 19))),
                        ObjectCode: menuItem.ObjectCode,
                        DatetimeOut: new Date(Date.parse(moment(menuItem.DatetimeOutDay).format('YYYY-MM-DD') + ' ' + menuItem.DatetimeOutTime.substring(11, 19))),
                        EThnicName: menuItem.EThnicName,
                        CareerName: menuItem.CareerName,
                        NationalityName: menuItem.NationalityName,
                        ProvincialCode: menuItem.ProvincialCode,
                        DistrictCode: menuItem.DistrictCode,
                        WardCode: menuItem.WardCode,
                        PatientAddress: menuItem.PatientAddress,
                        CompanyInfo: menuItem.CompanyInfo,
                        OrderNumber: menuItem.OrderNumber,
                        PatientType: menuItem.PatientType,
                        Serial: menuItem.Serial,
                        KCBBDCode: menuItem.KCBBDCode,
                        KCBBDName: menuItem.KCBBDName,
                        StartDate: new Date(Date.parse(menuItem.StartDate)),
                        EndDate: new Date(Date.parse(menuItem.EndDate)),
                        TraiTuyen: menuItem.TraiTuyen


                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, ClinicCode, Reason, DatetimeInto, ObjectCode, DatetimeOut,PatientCode,
            EThnicName, CareerName, NationalityName, ProvincialCode, DistrictCode, WardCode, PatientAddress,
            CompanyInfo, OrderNumber, PatientType, Serial, KCBBDCode, KCBBDName, StartDate, EndDate, TraiTuyen } = this.state;
        // var { history } = this.props;
        const DatetimeIntoID = moment(DatetimeInto).format('YYYYMMDDHHmmss');
        const DatetimeOutID = moment(DatetimeOut).format('YYYYMMDDHHmmss');

        const StartDateID = moment(StartDate).format('MM/DD/YYYY');
        const EndDateID = moment(EndDate).format('MM/DD/YYYY');

        e.preventDefault();

        if (id) {
            var patients = {
                PatientReceiveID: id,
                PatientCode: PatientCode,
                ClinicCode: ClinicCode,
                Reason: Reason,
                DatetimeInto: DatetimeIntoID,
                ObjectCode: ObjectCode,
                DatetimeOut: DatetimeOutID,
                EThnicName: EThnicName,
                CareerName: CareerName,
                NationalityName: NationalityName,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                PatientAddress: PatientAddress,
                CompanyInfo: CompanyInfo,
                OrderNumber: OrderNumber,
                PatientType: PatientType,
                Serial: Serial,
                KCBBDCode: KCBBDCode,
                KCBBDName: KCBBDName,
                StartDate: StartDateID,
                EndDate: EndDateID,
                TraiTuyen: TraiTuyen
            }
            this.props.onUpdatePatientReceive(patients);
            window.location.href = '/patient-receive';

        } else {
            var patientreceive = {
                PatientReceiveID: Math.floor(Math.random() * 10000000000),
                PatientCode: PatientCode,
                ClinicCode: ClinicCode,
                Reason: Reason,
                DatetimeInto: DatetimeInto ? moment(DatetimeInto).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                ObjectCode: ObjectCode,
                DatetimeOut: DatetimeOut ? moment(DatetimeOut).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                EThnicName: EThnicName,
                CareerName: CareerName,
                NationalityName: NationalityName,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                PatientAddress: PatientAddress,
                CompanyInfo: CompanyInfo,
                OrderNumber: OrderNumber,
                PatientType: PatientType,
                Serial: Serial,
                KCBBDCode: KCBBDCode,
                KCBBDName: KCBBDName,
                StartDate: StartDate ? moment(StartDate).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY'),
                EndDate: EndDate ? moment(EndDate).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY'),
                TraiTuyen: TraiTuyen

            }
            this.props.onAddPatientReceive(patientreceive);
            window.location.href = '/patient-receive';

        }

    }
    categoryClinic() {
        if (this.props.clinics) {
            return (
                this.props.clinics.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }
    categoryPatientType() {
        var PatientTypes = [
            {
                PatientType: 1,
                lable: 'Khám bệnh'
            },
            {
                PatientType: 2,
                lable: 'Cấp cứu'
            },
            {
                PatientType: 3,
                lable: 'Khám SK Công Ty'
            },
            {
                PatientType: 4,
                lable: 'Khám SK xin Việc - Đi học'
            },
            {
                PatientType: 5,
                lable: 'Khám SK Lái Xe'
            }
        ];

        return (
            PatientTypes.map((cate, index) => {
                return (<option key={index} value={cate.PatientType} >{cate.lable}</option>);
            })
        )

    }
    categoryPatients() {
        if (this.props.patients) {
            return (
                this.props.patients.map((cate, index) => {
                    return (<option key={index} value={cate.PatientCode} >{cate.PatientName}</option>);
                })
            )
        }
    }


    handleChangeDatetimeInto = DatetimeInto => {
        this.setState({
            DatetimeInto
        });
    };
    handleChangeDatetimeOut = DatetimeOut => {
        this.setState({
            DatetimeOut
        });
    };
    handleChangeStartDate = StartDate => {
        this.setState({
            StartDate
        });
    };
    handleChangeEndDate = EndDate => {
        this.setState({
            EndDate
        });
    };

    render() {
        var { ClinicCode, Reason, DatetimeInto, ObjectCode, DatetimeOut, PatientCode,
            EThnicName, CareerName, NationalityName, ProvincialCode, DistrictCode, WardCode, PatientAddress,
            CompanyInfo, OrderNumber, PatientType, Serial, KCBBDCode, KCBBDName, StartDate, EndDate, TraiTuyen } = this.state;

        /* var current=new Date().getFullYear();
         var birthday="04/30/1985";
         birthday=new Date(birthday).getFullYear();
         console.log(current-birthday);
             */
        /* var dateString = '19930701';
         var pattern = /(\d{4})(\d{2})(\d{2})/;
         var formatedDate = dateString.replace(pattern, '$1-$2-$3');
         console.log(formatedDate);*/





        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Tiếp nhận bệnh nhân</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Bệnh nhân</label>
                                                <select value={PatientCode} className="form-control" onChange={this.onChange} name="PatientCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryPatients()}
                                                </select>

                                            </div>

                                        </div>


                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Nghề nghiệp</label>
                                                <input type="text"
                                                    name="CareerName"
                                                    className="form-control"
                                                    value={CareerName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Quốc tịch</label>
                                                <input type="text"
                                                    name="NationalityName"
                                                    className="form-control"
                                                    value={NationalityName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label >Mã Đối tượng</label>
                                                        <input type="text"
                                                            name="ObjectCode"
                                                            className="form-control"
                                                            value={ObjectCode}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                     <div className="form-group">
                                                        <label >Thứ tự</label>
                                                        <input type="text"
                                                            name="OrderNumber"
                                                            className="form-control"
                                                            value={OrderNumber}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                         
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Lý do khám</label>
                                                <textarea type="text"
                                                    name="Reason"
                                                    className="form-control"
                                                    value={Reason}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Thông tin Cty</label>
                                                <textarea type="text"
                                                    name="CompanyInfo"
                                                    className="form-control"
                                                    value={CompanyInfo}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày vào</label>
                                                <DatePicker className="form-control" name="DatetimeInto" selected={DatetimeInto ? DatetimeInto : new Date()} onChange={this.handleChangeDatetimeInto}
                                                    dateFormat="dd/MM/yyyy" />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày ra</label>
                                                <DatePicker className="form-control" name="DatetimeOut" selected={DatetimeOut ? DatetimeOut : new Date()} onChange={this.handleChangeDatetimeOut}
                                                    dateFormat="dd/MM/yyyy" />

                                            </div>
                                        </div>


                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Loại Bệnh nhân</label>
                                                <select value={PatientType} className="form-control" onChange={this.onChange} name="PatientType" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryPatientType()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >EThnicName</label>
                                                <input type="text"
                                                    name="EThnicName"
                                                    className="form-control"
                                                    value={EThnicName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tỉnh thành</label>
                                                <input type="text"
                                                    name="ProvincialCode"
                                                    className="form-control"
                                                    value={ProvincialCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Quận/Huyện</label>
                                                <input type="text"
                                                    name="DistrictCode"
                                                    className="form-control"
                                                    value={DistrictCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Phường/Xã</label>
                                                <input type="text"
                                                    name="WardCode"
                                                    className="form-control"
                                                    value={WardCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Địa chỉ</label>
                                                <input type="text"
                                                    name="PatientAddress"
                                                    className="form-control"
                                                    value={PatientAddress}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Serial</label>
                                                <input type="text"
                                                    name="Serial"
                                                    className="form-control"
                                                    value={Serial}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã KCBBD</label>
                                                <input type="text"
                                                    name="KCBBDCode"
                                                    className="form-control"
                                                    value={KCBBDCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên KCBBD</label>
                                                <input type="text"
                                                    name="KCBBDName"
                                                    className="form-control"
                                                    value={KCBBDName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Trái tuyến</label>
                                                <input type="checkbox"
                                                    name="TraiTuyen"
                                                    className="form-check-input"
                                                    value={TraiTuyen}
                                                    onChange={this.onChange}
                                                    checked={TraiTuyen}
                                                />
                                            </div>
                                        </div>



                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày bắt đầu</label>
                                                <DatePicker className="form-control" name="StartDate" selected={StartDate ? StartDate : new Date()} onChange={this.handleChangeStartDate}
                                                    dateFormat="dd/MM/yyyy HH:mm:ss" />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày kết thúc</label>
                                                <DatePicker className="form-control" name="EndDate" selected={EndDate ? EndDate : new Date()} onChange={this.handleChangeEndDate}
                                                    dateFormat="dd/MM/yyyy" />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <select value={ClinicCode} className="form-control" onChange={this.onChange} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryClinic()}
                                                </select>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/patient-receive" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        patients: state.patients,
        clinics: state.clinics,
        EditingPatientReceive: state.EditingPatientReceive
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddPatientReceive: (patients) => {
            dispatch(addPatientReceiveRequest(patients));
        },
        onEditPatientReceive: (id) => {
            dispatch(eidtPatientReceiveRequest(id));
        },
        onUpdatePatientReceive: (patients) => {
            dispatch(updatePatientReceiveRequest(patients));
        },
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },
        fetchAllCategoryPatient: () => {
            dispatch(actFetchCategoryRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(PatientReceiveActionPage);