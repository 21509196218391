import * as Types from '../../constants/User/ActionTypes';
var initialState = [];

var findIndex = (users, id) => {
    var result = -1;
    users.forEach((user, index) => {
        if (user.id === id) {
            result = index;
        }
    })
    return result;
}
const users = (state = initialState, action) => {
    var index = -1;
    var { id, users } = action;
    switch (action.type) {
        case Types.FETCH_USER:
            state = action.users;
            return [...state];
        case Types.DELETE_USER:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_USER:
            state.push(action.users);
            return [...state];

        case Types.UPDATE_USER:
            index = findIndex(state, users.id);
            state[index] = users;
            return [...state];
        case Types.UPDATE_STATUS_USER:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
        case Types.UPDATE_STATUS_DOCTOR:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
        case Types.CATEGORY_USER:
            state = action.users;
            return [...state];

        default: return [...state];
    }
}
export default users;