import * as Types from '../../constants/TraCuuKetQua/ActionTypes';
var initialState = [];
const tracuuketquaxetnghiem = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_TRACUUKETQUAXN:
           state = action.tracuuketquaxetnghiem;
            return state;

        default: return state;
    }
}
export default tracuuketquaxetnghiem;