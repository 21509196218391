
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class UserItem extends Component {
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }
    onUpdateStatusDoc = (id) => {
        this.props.onUpdateStatusDoc(id);
    }

    render() {
        var { user } = this.props;
        var GioiTinh = user.Sex === 1 ? 'Nam' : 'Nữ';
        var statusname = user.isLocked === true ? 'Hiện' : 'Ẩn';
        var statusclass = user.isLocked === true ? 'btn-success' : 'btn-warning';

        var statusnameDoc = user.isDoctor === true ? 'Hiện' : 'Ẩn';
        var statusclassDoc = user.isDoctor === true ? 'btn-success' : 'btn-warning';

        return (
            <tr>
                <td>{user.UserName}</td>
                <td>{user.FullName}</td>
                <td>{GioiTinh}</td>
                <td>{user.Mobile}</td>

                <td >
                    <span className={`labelIs btn btn-block  btn-xs ${statusclass}`} onClick={() => this.onUpdateStatus(user.RowID)} >
                        {statusname}
                    </span>
                </td>
                <td>
                    <span className={`labelIs btn btn-block  btn-xs ${statusclassDoc}`} onClick={() => this.onUpdateStatusDoc(user.RowID)} >
                        {statusnameDoc}
                    </span>
                </td>
                <td>{user.UserID}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(user.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/user/${user.RowID}/edit`} className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default UserItem;

