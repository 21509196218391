
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class MedicRecordItem extends Component {
 
    onDelete = (id) => {
        
        this.props.onDelete(id);
    }

    render() {
        var { medicrecord } = this.props;

        return (
            <tr>
                <td>{medicrecord.PatientReceiveID}</td>
                <td>{medicrecord.ClinicCode}</td>
                <td>{medicrecord.PatientCode}</td>
                <td>{medicrecord.MedicalRecordCode}</td>
                <td>{medicrecord.Reason_KB}</td>
                <td>{medicrecord.DiagnosisCustom}</td>
                <td>{medicrecord.ICD10_ICD10KT_Ma}</td>
                <td>{medicrecord.ICD10_ICD10KT_Ten}</td>
                <td>{medicrecord.DepartmentName}</td>
                <td>{medicrecord.EmployeeDoctor_Code}</td>
                <td>{medicrecord.EmployeeDoctor_Name}</td>
                <td>{medicrecord.PostingDate}</td>
                <td>{medicrecord.AppointmentDate}</td>
                <td>{medicrecord.AppointmentNote}</td>
                <td>{medicrecord.ObjectCode}</td>
                <td>{medicrecord.Treatments}</td>
                <td>{medicrecord.TackleName}</td>
                <td>{medicrecord.TinhTrangRaVien}</td>
                <td>{medicrecord.Advices_Note}</td>
                <td>{medicrecord.OffDay}</td>
                <td>{medicrecord.OffDay_From}</td>
                <td>{medicrecord.OffDay_To}</td>
                <td>{medicrecord.SuggestedID}</td>
                <td>{medicrecord.Pulse}</td>
                <td>{medicrecord.Temperature}</td>
                <td>{medicrecord.BloodPressure}</td>
                <td>{medicrecord.BloodPressure1}</td>
                <td>{medicrecord.Weight}</td>
                <td>{medicrecord.Hight}</td>
                <td>{medicrecord.Breath}</td>
                <td>{medicrecord.BMI}</td>
                <td>{medicrecord.VongNguc}</td>
                <td>{medicrecord.SPO2}</td>

                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(medicrecord.MedicalRecordCode)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/medical-record/${medicrecord.MedicalRecordCode}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default MedicRecordItem;

