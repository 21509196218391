
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class PatientReceiveItem extends Component {
   
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    render() {
        var { patientreceive } = this.props;
        var StartDate =  moment(new Date(patientreceive.StartDate)).format('DD/MM/YYYY');
        var EndDate =  moment(new Date(patientreceive.EndDate)).format('DD/MM/YYYY');
       // var DatetimeOut = moment(new Date(patientreceive.DatetimeOutDay)).format('DD/MM/YYYY')+ ' '+ patientreceive.DatetimeOutTime.substr(11, 19);
        //var DatetimeInto = moment(new Date(patientreceive.DatetimeIntoDay)).format('DD/MM/YYYY')+ ' '+ patientreceive.DatetimeIntoTime.substring(11, 19);
       
        var DatetimeOut = moment(new Date(patientreceive.DatetimeOutDay)).format('DD/MM/YYYY');
        var DatetimeInto = moment(new Date(patientreceive.DatetimeIntoDay)).format('DD/MM/YYYY');

        return (
            <tr>
                <td>{patientreceive.PatientReceiveID}</td>
                <td>{patientreceive.PatientCode}</td>
                <td>{patientreceive.ClinicCode}</td>
                <td>{patientreceive.Reason}</td>
                <td>{DatetimeInto}</td>
                <td>{DatetimeOut}</td>
                <td>{patientreceive.EThnicName}</td>
                <td>{patientreceive.CareerName}</td>
                <td>{patientreceive.NationalityName}</td>
                <td>{patientreceive.ProvincialCode}</td>
                <td>{patientreceive.DistrictCode}</td>
                <td>{patientreceive.WardCode}</td>
                <td>{patientreceive.PatientAddress}</td>
                <td>{patientreceive.CompanyInfo}</td>
                <td>{patientreceive.OrderNumber}</td>
                <td>{patientreceive.PatientType}</td>

                <td>{patientreceive.Serial}</td>
                <td>{patientreceive.KCBBDCode}</td>
                <td>{patientreceive.KCBBDName}</td>
                <td>{StartDate}</td>
                <td>{EndDate}</td>
                <td>{patientreceive.TraiTuyen}</td>
               

             
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(patientreceive.PatientReceiveID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/patient-receive/${patientreceive.PatientReceiveID}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default PatientReceiveItem;

