import * as Types from '../../constants/Clinic/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchClinicRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('clinic', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchClinic(res.data));
                }
            })
        }
    }
 
}
export const actFetchClinic = (clinics) => {
    return {
        type: Types.FETCH_CLINIC,
        clinics
    }
}

export const deleteClinicRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-clinic/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteClinic(id));
        })
    }
}
export const deleteClinic = (id) => {
    return {
        type: Types.DELETE_CLINIC,
        id
    }
}

export const addClinicRequest = (clinics) => {
    return (dispatch) => {
        return UrlApi('add-clinic', 'POST', clinics, authHeader()).then(res => {
            dispatch(addClinic(res.data));
            window.location.href = '/clinic';
        })
    }
}

export const addClinic = (clinics) => {
    return {
        type: Types.ADD_CLINIC,
        clinics
    }
}


export const eidtClinicRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`clinic/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editClinic(res.data));
            }

        })
    }
}


export const editClinic = (clinics) => {
    return {
        type: Types.EDIT_CLINIC,
        clinics
    }
}


export const updateClinicRequest = (clinics) => {
    return (dispatch) => {
        return UrlApi(`update-clinic/${clinics.id}`, 'PUT', clinics, authHeader()).then(res => {
            dispatch(updateClinic(res.data));
            window.location.href = '/clinic';

        })
    }
}


export const updateClinic = (clinics) => {
    return {
        type: Types.UPDATE_CLINIC,
        clinics
    }
}

export const updateisLockedClinicRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-clinic/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedClinic(id));

        })
    }
}
export const updateisLockedClinic = (id) => {
    return {
        type: Types.UPDATE_STATUS_CLINIC,
        id
    }
}

/*
export const actSearchBacSi = keyword => {
    return {
      type: Types.SEARCH_BACSI,
      keyword
    };
  };
  */

  export const actFetchCateClinicRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('category-clinic', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchCateClinic(res.data));
                }
            })
        }
    }
 
}
export const actFetchCateClinic = (clinics) => {
    return {
        type: Types.FETCH_CATEGORY_CLINIC,
        clinics
    }
}


/*export const DatHenCliniCRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('category-dat-hen-clinic', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(DatHenClinic(res.data));
                }
            })
        }
    }
 
}*/
export const DatHenCliniCRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('category-dat-hen-clinic','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(DatHenClinic(res.data))
                }
            } 
        })

    }
}

export const DatHenClinic = (clinicscatedhs) => {
    return {
        type: Types.FETCH_DATHEN_CLINIC,
        clinicscatedhs
    }
}
export const GetCliniCRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('category-get-clinic','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(GetClinic(res.data))
                }
            } 
        })

    }
}

export const GetClinic = (clinics) => {
    return {
        type: Types.FETCH_GET_CLINIC,
        clinics
    }
}



export const DatHenCliniCUserRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('category-dat-hen-clinic-user','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(DatHenClinicUser(res.data))
                }
            } 
        })

    }
}

export const DatHenClinicUser = (clinicscatedhuser) => {
    return {
        type: Types.FETCH_DATHEN_CLINIC_USER,
        clinicscatedhuser
    }
}



