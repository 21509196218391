import React, { Component } from 'react';
import UserList from './../../components/User/UserList'
import UserItem from './../../components/User/UserItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import SearchControl from '../Search/SearchControl';
import { actFetchUserRequest,deleteUserRequest,updateisLockedUserRequest,updateisdoctorUserRequest } from '../../actions/User/AcUser';
import {actSearchKeyWord } from '../../actions/DSBNAdmin/AcDSBNAdmin';

class UserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idUser:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
        }

    }
    componentDidMount(){
        this.props.fetchAllUser();
        this.props.getProfileFetch();
       
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.idUser && this.state.idUser !== '') {
            this.props.fetchAllUser();
            this.props.getProfileFetch();
            this.setState({ idUser: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idUser: id })
        this.props.onUpdateStatusUser(id);
    }
    onUpdateStatusDoc = (id) => {
        this.setState({ idUser: id })
        this.props.onUpdateStatusDoctor(id);
    }

    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idUser: id })
            this.props.onDeleteUser(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };
    onSearch = (keyword) => {
        this.props.onSearchPK(keyword);
    };

    render() {
        var { users,keyword } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];
        if (keyword) {
            users = users.filter(user => {
                //return user.FullName.toLowerCase().indexOf(keyword) !== -1 || user.Mobile.toLowerCase().indexOf(keyword) !== -1;
                return user.FullName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 || user.Mobile.toLowerCase().indexOf(keyword) !== -1;
            });
        }

        rowsPerPage = users.slice(startIndex, endIndex + 1);
        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3 className="card-title">User</h3>
                                </div>
                                <div className="col-md-7">
                                <SearchControl
                                    onSearch={this.onSearch}
                                    keyword={this.props.keyword}
                                />

                                </div>
                                <div className="col-md-3">
                                    <Link to="/user/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
                <div className="pageMainShow">
                    <UserList>
                        {this.showUser(rowsPerPage) }
                    </UserList>
                    <p className="totalPhongKham">
                        Tổng cộng: {users.length} | Trang {currentPage}/{totalPages}
                    </p>
                    <Pagination
                        totalRecords={users.length}
                        pageLimit={pageLimit || 20}
                        initialPage={1}
                        pagesToShow={10}
                        onChangePage={this.onChangePage}
                    />
                    <ReactJsAlert
                        status={this.state.thongbao}   // true or false
                        type="warning"   // success, warning, error, info
                        title="Tài khoản không tồn tại"   // title you want to display
                        Close={() => this.clickThongBao()}   // callback method for hide
                        button='Đăng nhập'
                    />
                </div>

            </div>
        );
    }
    showUser(users) {
        var result = null;
        if (users.length > 0) {
            result = users.map((user, index) => {
                return (
                    <UserItem
                        key={index}
                        user={user}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                        onUpdateStatusDoc = {this.onUpdateStatusDoc}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        users: state.users,
        payload: state.payload,
        keyword: state.searchkeywordadmin,
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllUser: () => {
            dispatch(actFetchUserRequest());
        },
        onDeleteUser: (id) => {
            dispatch(deleteUserRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
        onUpdateStatusUser: (id) => {
            dispatch(updateisLockedUserRequest(id));
        },
        onUpdateStatusDoctor: (id) => {
            dispatch(updateisdoctorUserRequest(id));
        },
        onSearchPK: (keyword) => {
            dispatch(actSearchKeyWord(keyword));
        },
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);