import React, { Component } from 'react';
class DoctorList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                               
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="70">Mã code</th>
                                                <th  width="70">Mã code HIS</th>
                                                <th  width="250">Tên</th>
                                                <th  width="70">Phone</th>
                                                <th  width="100">Mã CCHN</th>
                                                <th  width="100">Mã CK</th>
                                                <th  width="250">Phòng khám</th>
                                                <th width="50">Hiển thị</th>
                                                <th width="100" >Cập nhật</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.children}
                                        </tbody>

                                    </table>
                               
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default DoctorList;

