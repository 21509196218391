import React, { Component } from 'react';
import DatHenList from './../../components/DatHen/DatHenList'
import DatHenItem from './../../components/DatHen/DatHenItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import {actFetchDatHenRequest,deleteDatHenRequest, actSearchKeyWordDatHen,actSearchCategoryDatHen, updateisLockedDatHenRequest } from '../../actions/DatHen/AcDatHen';
import SearchControl from "./SearchControl";
class DatHenPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idDatHen:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
        }

    }
    componentDidMount(){
        this.props.fetchAllDatHen();
        this.props.getProfileFetch();
       
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.idDatHen && this.state.idDatHen !== '') {
           
            this.props.fetchAllDatHen();
            this.props.getProfileFetch();
            this.setState({ idDatHen: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idDatHen: id })
        this.props.onUpdateStatusDatHen(id);
    }
  


   onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idDatHen: id })
            this.props.onDeleteDatHen(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };
    onSearch = (categoryID,keyword) => {
        this.props.onSearchCate(categoryID);
        this.props.onSearchPK(keyword);
        
    };

    render() {
        var { dathens,keyword,categoryID } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];
        if (keyword) {
            dathens = dathens.filter(dathen => {
                return dathen.Mobile.toLowerCase().indexOf(keyword) !== -1 || dathen.FullName.toLowerCase().indexOf(keyword) !== -1  ;
            });
        }
        if (categoryID) {
            dathens = dathens.filter(dathen => {
                return dathen.ClinicCode === categoryID ;
            });
        }
        if(categoryID && keyword){
            dathens = dathens.filter(dathen => {
                return dathen.ClinicCode === categoryID && dathen.Mobile.toLowerCase().indexOf(keyword) !== -1;
            });
        }


        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        rowsPerPage = dathens.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <h3 className="card-title">Danh sách đặt hẹn</h3>
                                </div>
                                <div className="col-md-9">
                                <SearchControl
                                    onSearch={this.onSearch}
                                    keyword={this.props.keyword}
                                    categoryID={this.props.categoryID}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
                <div className="pageMainShow">
                <DatHenList>
                    {this.showDatHen(rowsPerPage) }
                </DatHenList>
                <p className="totalPhongKham">
                    Tổng cộng: {dathens.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={dathens.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />
                </div>


            </div>
        );
    }
    showDatHen(dathens) {
        var result = null;
        if (dathens.length > 0) {
            result = dathens.map((dathen, index) => {
                return (
                    <DatHenItem
                        key={index}
                        dathen={dathen}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus ={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        dathens: state.dathens,
        payload: state.payload,
        keyword: state.searchkeyworddathen,
        categoryID: state.searchcatedathen
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllDatHen: () => {
            dispatch(actFetchDatHenRequest());
        },
        onDeleteDatHen: (id) => {
            dispatch(deleteDatHenRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
        onSearchPK: (keyword) => {
            dispatch(actSearchKeyWordDatHen(keyword));
        },
        onSearchCate: (categoryID) => {
            dispatch(actSearchCategoryDatHen(categoryID));
        },
        onUpdateStatusDatHen: (id) => {
            dispatch(updateisLockedDatHenRequest(id));
        },
      
       
      
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatHenPage);