
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
import { actFetchUserRequest } from '../../actions/User/AcUser';
import {updateDatHenRequest} from '../../actions/DatHen/AcDatHen';



class DatHenItem extends Component {
   
    componentDidMount(){
        this.props.fetchAllCategoryClinic();
        this.props.fetchAllUser();
    }

    onDelete = (id) => {
       /* if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }*/
        this.props.onDelete(id);
    }
    
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }

    render() {
        var { dathen } = this.props;
 
       /* var cateClinic  = this.props.clinics
        .filter(commentReply => commentReply.ClinicCode === dathen.ClinicCode)
        .map((commentReply, idx) => <span key={idx}>{commentReply.ClinicName}</span>);
        */
        /* var HoTen  = this.props.users
        .filter(commentReply => commentReply.Mobile === dathen.Mobile)
        .map((commentReply, idUserN) => <span key={idUserN}>{commentReply.FullName}</span>);
*/
        var DatetimeInto1 = moment(new Date(dathen.DatetimeIntoDay)).format('DD/MM/YYYY');

        var statusname = dathen.StatusDH === true ? 'Thực hiện' : 'Chưa thực hiện';
        var statusclass = dathen.StatusDH === true ? 'btn-success' : 'btn-warning';
       
        return (
            <tr>
                <td>{dathen.FullName}</td>
                <td>{dathen.ClinicName}</td>
                <td>{dathen.ChuyenKhoaName}</td>
                <td>{DatetimeInto1}</td>
                <td>{dathen.TimeInto}</td>
                <td>{dathen.Mobile}</td>
                <td>
                    {dathen.StatusDH === true? 
                          <span className={`labelIs btn btn-block  btn-xs ${statusclass}`}  >
                                 {statusname}
                         </span> 
                        :
                          <span className={`labelIs btn btn-block  btn-xs ${statusclass}`}  >
                                {statusname}
                         </span> 
                    }
                   </td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(dathen.RowID)} className="btn btn-block bg-gradient-primary btn-xs btndelete"><i className="fas fa-trash-alt"></i> Xóa</button>
                       {/* <Link to={`/dat-hen/${dathen.RowID}/edit`} className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>*/}
                    </div>
                </td>

            </tr>

        );
    }
}
const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        users: state.users
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
       
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },
        fetchAllUser: () => {
            dispatch(actFetchUserRequest());
        },
        onUpdateClinic: (dathens) => {
            dispatch(updateDatHenRequest(dathens));
        }
      
       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DatHenItem);

