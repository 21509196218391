
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class DSBNAdminItem extends Component {

    render() {
        var { dsbnadmin } = this.props;
       // var ngaysinh =  moment(new Date(dsbnadmin.PatientBirthDay)).format('DD/MM/YYYY');
        var GioiTinhActive = dsbnadmin.PatientGender === 0 ? 'Nữ' : 'Nam';
        return (
            <tr>
                <td>{dsbnadmin.PatientName}</td>
                <td>{GioiTinhActive}</td>
                <td>{dsbnadmin.PatientBirthDay}</td>
                <td>{dsbnadmin.PatientMobile}</td>
                <td>{dsbnadmin.PatientAddress}</td>
                <td>{dsbnadmin.ClinicName}</td>
                <td>
                    <div className="btnCapNhatList">
                        <Link to={`/show-benh-nhan/pk_${dsbnadmin.ClinicCode}/${dsbnadmin.PatientCode}`}  className=" btnXem btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Xem</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default DSBNAdminItem;

