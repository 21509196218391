import * as Types from '../../constants/User/ActionTypes';

var initialState = "";

var searchusers = (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_USER:
      return action.keyword;

    default:
      return state;
  }
};

export default searchusers;
