import * as Types from '../../constants/Location/ActionTypes';
var initialState = [];
const chuyenkhoa = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_CHUYENKHOA:
            state = action.chuyenkhoa;
            return [...state];
        default: return state;
    }
}
export default chuyenkhoa;