import * as Types from '../../constants/MapUserClinic/ActionTypes';
var initialState = [];

var findIndex = (usersclinics, id) => {
    var result = -1;
    usersclinics.forEach((userclinic, index) => {
        if (userclinic.id === id) {
            result = index;
        }
    })
    return result;
}
const usersclinics = (state = initialState, action) => {
    var index = -1;
    var { id, usersclinics } = action;
    switch (action.type) {
        case Types.FETCH_MAP_USER_CLINIC:
            state = action.usersclinics;
            return [...state];
        case Types.DELETE_MAP_USER_CLINIC:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_MAP_USER_CLINIC:
            state.push(action.usersclinics);
            return [...state];

        case Types.UPDATE_MAP_USER_CLINIC:
            index = findIndex(state, usersclinics.id);
            state[index] = usersclinics;
            return [...state];
       
        default: return [...state];
    }
}
export default usersclinics;