import * as Types from '../../constants/HoiDap/ActionTypes';
var initialState = [];

var findIndex = (hoidaps, id) => {
    var result = -1;
    hoidaps.forEach((hoidap, index) => {
        if (hoidap.id === id) {
            result = index;
        }
    })
    return result;
}
const hoidaps = (state = initialState, action) => {
    var index = -1;
    var { id, hoidaps } = action;
    switch (action.type) {
        case Types.FETCH_HOIDAP:
            state = action.hoidaps;
            return [...state];


        case Types.DELETE_HOIDAP:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_HOIDAP:
            state.push(action.hoidaps);
            return [...state];

        case Types.UPDATE_HOIDAP:
            index = findIndex(state, hoidaps.id);
            state[index] = hoidaps;
            return [...state];

        case Types.UPDATE_STATUS_HOIDAP:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];

        default: return [...state];
    }
}
export default hoidaps;