
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SuggServiceItem extends Component {
   
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    render() {
        var { suggservice } = this.props;
        return (
            <tr>
                <td>{suggservice.SuggestedID}</td>
                <td>{suggservice.PatientReceiveID}</td>
                <td>{suggservice.PatientCode}</td>
                <td>{suggservice.ClinicCode}</td>
                <td>{suggservice.ServiceCode}</td>
                <td>{suggservice.ServiceName}</td>
                <td>{suggservice.ServicePrice}</td>
                <td>{suggservice.Quantity}</td>
                <td>{suggservice.DisparityPrice}</td>
                <td>{suggservice.Status_}</td>
                <td>{suggservice.WorkDate}</td>
                <td>{suggservice.Paid}</td>
                <td>{suggservice.EmployeeDoctorOrder_Code}</td>
                <td>{suggservice.EmployeeDoctorOrder_Name}</td>
                <td>{suggservice.WorkDateOrder}</td>
                <td>{suggservice.EmployeeDoctorDone_Code}</td>

                <td>{suggservice.EmployeeDoctorDone_Name}</td>
                <td>{suggservice.WorkDateDone}</td>
                <td>{suggservice.Note}</td>
                <td>{suggservice.UnitOfMeasureName}</td>
                <td>{suggservice.ServiceCategoryName}</td>
                <td>{suggservice.ServiceGroupName}</td>
                <td>{suggservice.ServiceModuleCode}</td>

               

             
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(suggservice.SuggestedID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/suggested-service/${suggservice.SuggestedID}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default SuggServiceItem;

