import * as Types from '../../constants/DatHen/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchDatHenRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('dat-hen','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchDatHen(res.data))
                }
            }
        })
    }
}
export const actFetchDatHen = (dathens) => {
    return {
        type: Types.FETCH_DATHEN,
        dathens
    }
}

export const deleteDatHenRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-dat-hen/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteDatHen(id));
        })
    }
}
export const deleteDatHen = (id) => {
    return {
        type: Types.DELETE_DATHEN,
        id
    }
}

export const addDatHenRequest = (dathens) => {
    return (dispatch) => {
        return UrlApi('add-dat-hen', 'POST', dathens, authHeader()).then(res => {
            if(res.data.message === false){
                alert('Không thành công! Bạn đã đăng ký trước đó, Vui lòng liên hệ với Quản trị để xóa đăng ký lại !');
            }else{
                alert('Đặt hẹn thành công!');
                dispatch(addDatHen(res.data));
                window.location.href = '/';
            }
           
        })
    }
}

export const addDatHen = (dathens) => {
    return {
        type: Types.ADD_DATHEN,
        dathens
    }
}

export const eidtDatHenRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`dat-hen/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editDatHen(res.data));
            }

        })
    }
}
export const editDatHen = (dathens) => {
    return {
        type: Types.EDIT_DATHEN,
        dathens
    }
}
export const updateDatHenRequest = (dathens) => {
    return (dispatch) => {
        return UrlApi(`update-dat-hen/${dathens.id}`, 'PUT', dathens, authHeader()).then(res => {
            dispatch(updateDatHen(res.data));

        })
    }
}
export const updateDatHen = (dathens) => {
    return {
        type: Types.UPDATE_DATHEN,
        dathens
    }
}
export const actFetchCateDatHenPhongKhamRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('cate-phong-kham-dat-hen','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchCatePhongKhamDatHen(res.data))
                }
            }
            
        })
    }
}
export const actFetchCatePhongKhamDatHen = (catesearchphongkhams) => {
    return {
        type: Types.FETCH_SEARCH_CATE_DATHEN,
        catesearchphongkhams
    }
}

export const actSearchKeyWordDatHen = keyword => {
    return {
      type: Types.SEARCH_KEYWORD_DATHEN,
      keyword
    };
  };
  export const actSearchCategoryDatHen = categoryID => {
    return {
      type: Types.SEARCH_CATE_DATHEN,
      categoryID
    };
  };



  export const updateisLockedDatHenRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-dat-hen/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedDatHen(id));

        })
    }
}
export const updateisLockedDatHen = (id) => {
    return {
        type: Types.UPDATE_STATUS_DATHEN,
        id
    }
}


