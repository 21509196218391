export const FETCH_NEWS ='FETCH_NEWS';
export const ADD_NEWS ='ADD_NEWS';
export const UPDATE_NEWS ='UPDATE_NEWS';
export const DELETE_NEWS ='DELETE_NEWS';
export const EDIT_NEWS ='EDIT_NEWS';
export const UPDATE_STATUS_NEWS ='UPDATE_STATUS_NEWS';
export const SEARCH_NEWS ='SEARCH_NEWS';



