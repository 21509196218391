export const FETCH_PATIENT_RECEIVE ='FETCH_PATIENT_RECEIVE';
export const ADD_PATIENT_RECEIVE ='ADD_PATIENT_RECEIVE';
export const UPDATE_PATIENT_RECEIVE ='UPDATE_PATIENT_RECEIVE';
export const DELETE_PATIENT_RECEIVE ='DELETE_PATIENT_RECEIVE';
export const EDIT_PATIENT_RECEIVE ='EDIT_PATIENT_RECEIVE';
export const SEARCH_PATIENT_RECEIVE ='SEARCH_PATIENT_RECEIVE';
export const FETCH_PATIENT_RECEIVE_CATE ='FETCH_PATIENT_RECEIVE_CATE';



