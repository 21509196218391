import * as Types from '../../constants/MapUserClinic/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchMapUserClinicRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('map-user-clinic', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchMap(res.data));
                }
            })
        }
    }
 
}
export const actFetchMap = (usersclinics) => {
    return {
        type: Types.FETCH_MAP_USER_CLINIC,
        usersclinics
    }
}

export const deleteMapUserClinicRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-map-user-clinic/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteMapUserClinic(id));
        })
    }
}
export const deleteMapUserClinic = (id) => {
    return {
        type: Types.DELETE_MAP_USER_CLINIC,
        id
    }
}

export const addMapUserClinicRequest = (doctors) => {
    return (dispatch) => {
        return UrlApi('add-map-user-clinic', 'POST', doctors, authHeader()).then(res => {
            console.log(res.data)
            if(res.data.status === 0){
                alert('Thêm không thành công ! User hoặc phòng khám đã tồn tại')
            }else{
             dispatch(addUserClinic(res.data));
             window.location.href = '/map-user-clinic';
            }
        })
    }
}

export const addUserClinic = (usersclinics) => {
    return {
        type: Types.ADD_MAP_USER_CLINIC,
        usersclinics
    }
}


export const eidtMapUserClinicRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`map-user-clinic/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editMapUserClinic(res.data));
            }

        })
    }
}
export const editMapUserClinic = (usersclinics) => {
    return {
        type: Types.EDIT_MAP_USER_CLINIC,
        usersclinics
    }
}


export const updateMapUserClinicRequest = (usersclinics) => {
    return (dispatch) => {
        return UrlApi(`update-map-user-clinic/${usersclinics.id}`, 'PUT', usersclinics, authHeader()).then(res => {
            dispatch(updateMapUserClinic(res.data));
            window.location.href = '/map-user-clinic';
        })
    }
}
export const updateMapUserClinic = (usersclinics) => {
    return {
        type: Types.UPDATE_MAP_USER_CLINIC,
        usersclinics
    }
}

