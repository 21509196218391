import React from "react";
import ReactLoading from "react-loading";
//import { Section, Title, Article, Prop, list } from "./Generic";
/*
const Loading = () => (
  <Section>
    <Title>React Loading</Title>
    {list.map(l => (
      <Article key={l.prop}>
        <ReactLoading type={l.prop} color="#fff" />
        <Prop>{l.name}</Prop>
      </Article>
    ))}
  </Section>
);

export default Loading;
*/
const Loading = ({ type, color }) => (
    <ReactLoading type="spinningBubbles" color="#ff0000"    />
);
export default Loading;