import React, { Component } from 'react';
import NotifiList from './../../components/Notification/NotifiList'
import NotifiItem from './../../components/Notification/NotifiItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';

import { actFetchNotifiRequest, deleteNotifiRequest, updateisLockedNotifiRequest } from '../../actions/Notification/AcNotification';
class NotifiPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idNotifi: '',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""

        }

    }
    componentDidMount() {
        this.props.fetchAllNotifi();
        this.props.getProfileFetch();

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.idNotifi && this.state.idNotifi !== '') {
            this.props.fetchAllNotifi();
            this.props.getProfileFetch();
            this.setState({ idNotifi: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idNotifi: id })
        this.props.onUpdateStatusNotifi(id);
    }
    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idNotifi: id })
            this.props.onDeleteNotifi(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };

    render() {
        var { notifications } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];

        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => { this.setState({ thongbaoxoa: false }); localStorage.removeItem("token"); window.location.reload(true); }}
                    button='Đăng nhập'
                />

            );
        }
        rowsPerPage = notifications.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3 className="card-title">Thông báo</h3>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/thong-bao/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="pageMainShow">
                    <NotifiList>
                        {this.showNotifi(rowsPerPage)}
                    </NotifiList>
                    <p className="totalPhongKham">
                        Tổng cộng: {notifications.length} | Trang {currentPage}/{totalPages}
                    </p>
                    <Pagination
                        totalRecords={notifications.length}
                        pageLimit={pageLimit || 20}
                        initialPage={1}
                        pagesToShow={20}
                        onChangePage={this.onChangePage}
                    />

                    <ReactJsAlert
                        status={this.state.thongbao}   // true or false
                        type="warning"   // success, warning, error, info
                        title="Tài khoản không tồn tại"   // title you want to display
                        Close={() => this.clickThongBao()}   // callback method for hide
                        button='Đăng nhập'
                    />
                </div>
            </div>
        );
    }
    showNotifi(notifications) {

        var result = null;
        if (notifications.length > 0) {
            result = notifications.map((notifi, index) => {
                return (
                    <NotifiItem
                        key={index}
                        notifi={notifi}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;

    }

}
const mapStateToProps = state => {
    return {
        notifications: state.notifications,
        payload: state.payload
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllNotifi: () => {
            dispatch(actFetchNotifiRequest());

        },
        onDeleteNotifi: (id) => {
            dispatch(deleteNotifiRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },

        onUpdateStatusNotifi: (id) => {
            dispatch(updateisLockedNotifiRequest(id));
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifiPage);