import React, { Component } from 'react';
class DocImageList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <table className="table table-condensed table-striped">
                                    <thead>
                                        <tr>
                                            <th>Mã TN</th>
                                            <th >Mã PK</th>
                                            <th>Mã BN</th>
                                            <th >Nghi chú</th>
                                            <th >STT</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default DocImageList;

