
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class HoiDapItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao:true,
        }

    }

    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
        this.setState({thongbao:false})

    }

    render() {
        var { hoidap } = this.props;

        var ngay =  moment(new Date(hoidap.IUDate)).format('DD/MM/YYYY');
        var statusname = hoidap.isLocked === true ? 'Hiện' : 'Ẩn';
        var statusclass = hoidap.isLocked === true ? 'btn-success' : 'btn-warning';

      //  var statusname = hoidap.isLocked === this.state.thongbao ? 'Hiện' : 'Ẩn';
       // var statusclass = hoidap.isLocked === this.state.thongbao ? 'btn-success' : 'btn-warning';



        return (
            <tr>
                <td>{hoidap.TieuDe}</td>
                <td>{hoidap.NoteHoi}</td>
                <td>{hoidap.ChuyenKhoaName}</td>
                <td>{hoidap.ClinicName}</td>
                <td>{ngay}</td>
                <td >
                    <span className={`labelIs btn btn-block  btn-xs ${statusclass}`} onClick={() => this.onUpdateStatus(hoidap.RowID)} >
                        {statusname}
                    </span>
                </td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(hoidap.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/hoi-dap/${hoidap.RowID}/edit`}  className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Trả lời</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default HoiDapItem;

