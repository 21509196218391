import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";


import { getProfileFetch } from './../../actions/AcLogin';
import { DatHenCliniCRequest } from './../../actions/Clinic/AcClinic';
import { actDatHenDocTorsRequest } from './../../actions/Doctors/AcDoctors';
import { updateDatHenRequest, eidtDatHenRequest } from './../../actions/DatHen/AcDatHen';





class DatHenActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ClinicCode: '',
            RowIDDoctor: '',
            ChuyenKhoa: '',
            DatetimeInto: '',
            TimeInto: '',
            StatusDH:''
        }
        this.handleChangeDatetimeInto = this.handleChangeDatetimeInto.bind(this);
        this.handleChangeTimeInto = this.handleChangeTimeInto.bind(this);

    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    onChangePK = (e) => {
        var target = e.target;
        var name = target.name;

        var value = target.type === 'checkbox' ? target.checked : target.value;
        localStorage.setItem('ClinicCode', value);
        this.setState({
            [name]: value
        })
    }
    categoryClinic() {
        if (this.props.clinicscatedhs) {
            return (
                this.props.clinicscatedhs.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }
    categoryDoctor() {
        if (this.props.catedoctordathens) {
            return (
                this.props.catedoctordathens.map((cate, index) => {
                    return (<option key={index} value={cate.DoctorName} >{cate.DoctorName}</option>);
                })
            )
        }
    }
    handleChangeDatetimeInto = DatetimeInto => {
        this.setState({
            DatetimeInto
        });
    };
    handleChangeTimeInto = TimeInto => {
        this.setState({
            TimeInto
        });
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditDatHen(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.itemEditingDatHen !== this.props.itemEditingDatHen) {

                this.props.itemEditingDatHen.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        ClinicCode: menuItem.ClinicCode,
                        RowIDDoctor: menuItem.DoctorName,
                        DatetimeInto: new Date(Date.parse(moment(menuItem.DatetimeIntoDay).format('YYYY-MM-DD'))),
                        ChuyenKhoa: menuItem.ChuyenKhoa,
                        TimeInto: menuItem.TimeInto

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, ClinicCode, RowIDDoctor, ChuyenKhoa, DatetimeInto, TimeInto } = this.state;
        // var { history } = this.props;

        e.preventDefault();
        const DatetimeIntoID = moment(DatetimeInto).format('YYYYMMDD');

        if (id) {
            var dathens = {
                RowID: id,
                ClinicCode: ClinicCode,
                DoctorName: RowIDDoctor,
                DatetimeInto: DatetimeIntoID,
                ChuyenKhoa: ChuyenKhoa,
                TimeInto: TimeInto


            }
            this.props.onUpdateDatHen(dathens);
            window.location.href = '/danh-sach-dat-hen';

        }

    }



    render() {
        const { ClinicCode, RowIDDoctor, ChuyenKhoa, DatetimeInto, TimeInto } = this.state;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">User</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <select value={ClinicCode} className="form-control" onChange={this.onChangePK} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryClinic()}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Bác sĩ</label>
                                                {ClinicCode === '' ?
                                                    <select value="" className="form-control" onChange={this.onChange} name="" >
                                                        <option value="">Chọn</option>

                                                    </select>
                                                    :
                                                    <select value={RowIDDoctor} className="form-control" onChange={this.onChange} name="RowIDDoctor" >
                                                        <option value="">Chọn</option>
                                                        {this.categoryDoctor()}

                                                    </select>
                                                }

                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Chuyên khoa</label>
                                                <select value={ChuyenKhoa} className="form-control" onChange={this.onChange} name="ChuyenKhoa" >
                                                    <option value="">Chọn</option>
                                                    <option value="Khoa Nội">Khoa Nội</option>
                                                    <option value="Khoa Ngoại">Khoa Ngoại</option>
                                                    <option value="Khoa Nhi">Khoa Nhi</option>
                                                    <option value="Khoa Da Liễu">Khoa Da Liễu</option>
                                                    <option value="Khoa Răng Hàm Mặt">Khoa Răng Hàm Mặt</option>
                                                    <option value="Khoa Tai Mũi Họng">Khoa Tai Mũi Họng</option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày</label>
                                                <DatePicker className="form-control" name="DatetimeInto" selected={DatetimeInto ? DatetimeInto : new Date()} onChange={this.handleChangeDatetimeInto}
                                                    dateFormat="dd/MM/yyyy" />
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Thời gian</label>
                                                <input type="text"
                                                    name="TimeInto"
                                                    className="form-control"
                                                    value={TimeInto}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                        </div>
                                    
                                    </div>






                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/danh-sach-dat-hen" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        payload: state.payload,
        clinicscatedhs: state.clinicscatedhs,
        catedoctordathens: state.catedoctordathens,
        itemEditingDatHen: state.itemEditingDatHen
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        getProfileFetch: () => {

            dispatch(getProfileFetch());
        },
        onEditDatHen: (id) => {
            dispatch(eidtDatHenRequest(id));
        },
        onUpdateDatHen: (dathens) => {
            dispatch(updateDatHenRequest(dathens));
        },
        DatHenClinic: () => {
            dispatch(DatHenCliniCRequest());
        },
        DatHenDoctor: () => {
            dispatch(actDatHenDocTorsRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DatHenActionPage);