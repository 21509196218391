import * as Types from '../../constants/Location/ActionTypes';
var initialState = [];
const quanhuyen = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_QUANHUYEN:
            state = action.quanhuyen;
            return [...state];
        default: return state;
    }
}
export default quanhuyen;