/*import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = {};
const itemEditingAdmin = (state = initialState, action) => {
   switch (action.type) {
        case Types.VIEW_BN_ADMIN:
            return action.dsbnadmins;
        default: return state;
    }
}
export default itemEditingAdmin;

*/

import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const itemEditingAdmin = (state = initialState, action) => {
    switch (action.type) {
        case Types.VIEW_BN_ADMIN:
            state = action.dsbnadmins;
            return [...state];

        default: return [...state];
    }
}
export default itemEditingAdmin;