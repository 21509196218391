import * as Types from '../../constants/ChuyenKhoa/ActionTypes';
var initialState = [];

var findIndex = (chuyenkhoas, id) => {
    var result = -1;
    chuyenkhoas.forEach((chuyenkhoa, index) => {
        if (chuyenkhoa.id === id) {
            result = index;
        }
    })
    return result;
}
const chuyenkhoas = (state = initialState, action) => {
    var index = -1;
    var { id, chuyenkhoas } = action;
    switch (action.type) {
        case Types.FETCH_CHUYENKHOAList:
            state = action.chuyenkhoas;
            return [...state];
        case Types.DELETE_CHUYENKHOA:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_CHUYENKHOA:
            state.push(action.chuyenkhoas);
            return [...state];

        case Types.UPDATE_CHUYENKHOA:
            index = findIndex(state, chuyenkhoas.id);
            state[index] = chuyenkhoas;
            return [...state];
        case Types.UPDATE_STATUS_CHUYENKHOA:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
      
        default: return state;
    }
}
export default chuyenkhoas;