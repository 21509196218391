import React, { Component } from 'react';
import SloganList from './../../components/Slogan/SloganList'
import SloganItem from './../../components/Slogan/SloganItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import { actFetchSloganRequest, deleteSloganRequest, updateisLockedSloganRequest } from '../../actions/Slogan/AcSlogan';


class SloganPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idSlogan:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
           
        }

    }
    componentDidMount(){
        this.props.fetchAllSlogan();
        this.props.getProfileFetch();
        
    }
    componentDidUpdate(prevProps, prevState) {
       
        if (prevState.idSlogan && this.state.idSlogan !== '') {
            this.props.fetchAllSlogan();
            this.props.getProfileFetch();
            this.setState({ idSlogan: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idSlogan: id })
        this.props.onUpdateStatusSlogan(id);
    }

    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idSlogan: id })
            this.props.onDeleteSlogan(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };

    render() {
       
        var { slogans } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];

        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        rowsPerPage = slogans.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3 className="card-title">Slogan</h3>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/slogan/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
               <div className="pageMainShow">
                <SloganList>
                    {this.showSlogan(rowsPerPage) }
                </SloganList>
                <p className="totalPhongKham">
                    Tổng cộng: {slogans.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={slogans.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />
                </div>


            </div>
        );
    }
    showSlogan(slogans) {
        
        var result = null;
        if (slogans.length > 0) {
            result = slogans.map((slogan, index) => {
                return (
                    <SloganItem
                        key={index}
                        slogan={slogan}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        slogans: state.slogans,
        payload: state.payload
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllSlogan: () => {
            dispatch(actFetchSloganRequest());
            
        },
        onDeleteSlogan: (id) => {
            dispatch(deleteSloganRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
      
        onUpdateStatusSlogan: (id) => {
            dispatch(updateisLockedSloganRequest(id));
        },
      
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SloganPage);