import * as Types from '../../constants/TraCuuKetQua/ActionTypes';
var initialState = [];
const clinictracuus = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_CLINIC_TRACUU:
            state = action.clinictracuus;
            return [...state];

        default: return [...state];
    }
}
export default clinictracuus;