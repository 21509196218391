import * as Types from '../../constants/DSBNAdmin/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';
/*export const actFetchDSBNAdminRequest = () => {
    return (dispatch)=>{
        return UrlApi('danh-sach-benh-nhan-theo-admin','GET', null, authHeader() ).then(res =>{
            if(res.data === 'TOKEN_KHONG_HOP_LE'){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                dispatch(actFetchDSBNAdmin(res.data))
            }
            
            
        })
    }
}
*/

export const actFetchDSBNAdminRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('danh-sach-benh-nhan-theo-admin','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchDSBNAdmin(res.data))
                }
            }
        })
    }
}


export const actFetchDSBNAdmin = (dsbnadmins) => {
    return {
        type: Types.FETCH_DSBN_ADMIN,
        dsbnadmins
    }
}
export const actFetchCateAdminRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('cate-admin','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchCateAdmin(res.data))
                }
            }
            
        })
    }
}


export const actFetchCateAdmin = (cateadmins) => {
    return {
        type: Types.FETCH_ADMIN,
        cateadmins
    }
}

export const actSearchKeyWord = keyword => {
    return {
      type: Types.SEARCH_KEYWORD,
      keyword
    };
  };
  export const actSearchCategory = categoryID => {
    return {
      type: Types.SEARCH_CATEGORY,
      categoryID
    };
  };
  export const showBNAdminRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`benh-nhan-theo-admin/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showBNAdmin(res.data));
            }

        })
    }
}
export const showBNAdmin = (dsbnadmins) => {
    return {
        type: Types.VIEW_BN_ADMIN,
        dsbnadmins
    }
}


export const showCTThuocAdminRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`thuoc-benh-nhan-theo-admin/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showCTThuocAdmin(res.data));
            }

        }) 
    }
}
export const showCTThuocAdmin = (chitietthuocAd) => {
    return {
        type: Types.FETCH_THUOC_ADMIN,
        chitietthuocAd
    }
}

export const showDVKTAdminRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`dvkt-benh-nhan-theo-admin/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showDVTKAdmin(res.data));
            }

        })
    }
}
export const showDVTKAdmin = (dichvukythuatAd) => {
    return {
        type: Types.FETCH_DVKT_ADMIN,
        dichvukythuatAd
    }
}


export const showMedicAdminRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`medical-benh-nhan-theo-admin/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showMedicAdmin(res.data));
            }

        })
    }
}
export const showMedicAdmin = (medicalAd) => {
    return {
        type: Types.FETCH_MEDIC_ADMIN,
        medicalAd
    }
}

export const showThongTinUserRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`thong-tin-user/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showthongtinuser(res.data));
            }

        })
    }
}
export const showthongtinuser = (thongtinuser) => {
    return {
        type: Types.FETCH_THONGTIN_USER,
        thongtinuser
    }
}

/*
export const showKetQuaCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-ket-qua-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showKetQuaAdmin(res.data));
            }

        })
    }
}
export const showKetQuaAdmin = (ketquacls) => {
    return {
        type: Types.KETQUA_CLS_ADMIN,
        ketquacls
    }
}

export const showPKCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-phong-kham-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showPKCLSAdmin(res.data));
            }

        })
    }
}
export const showPKCLSAdmin = (phongkhamcls) => {
    return {
        type: Types.PHONGKHAM_CLS_ADMIN,
        phongkhamcls
    }
}
export const showBNCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-benh-nhan-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showBNCLSAdmin(res.data));
            }

        })
    }
}
export const showBNCLSAdmin = (benhnhancls) => {
    return {
        type: Types.BENHNHAN_CLS_ADMIN,
        benhnhancls
    }
}

export const showTDCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-tieu-de-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showtdCLSAdmin(res.data));
            }

        })
    }
}
export const showtdCLSAdmin = (tieudecls) => {
    return {
        type: Types.TIEUDE_CLS_ADMIN,
        tieudecls
    }
}

export const showXNCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-xet-nghiem-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showXNCLSAdmin(res.data));
            }

        })
    }
}
export const showXNCLSAdmin = (ketquaxetnghiem) => {
    return {
        type: Types.XETNGHIEM_CLS_ADMIN,
        ketquaxetnghiem
    }
}

export const showXNKQCLSAdminRequest = (idBN,id) => {
    return (dispatch) => {
        return UrlApi(`show-xet-nghiem-ket-qua-cls/${idBN}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showXNKQCLSAdmin(res.data));
            }

        })
    }
}
export const showXNKQCLSAdmin = (ketquaxetnghiemresult) => {
    return {
        type: Types.XETNGHIEM_CLS_KQ_ADMIN,
        ketquaxetnghiemresult
    }
}

*/



