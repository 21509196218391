import * as Types from '../../constants/Slogan/ActionTypes';
var initialState = [];

var findIndex = (slogans, id) => {
    var result = -1;
    slogans.forEach((slogan, index) => {
        if (slogan.id === id) {
            result = index;
        }
    })
    return result;
}
const slogans = (state = initialState, action) => {
    var index = -1;
    var { id, slogans } = action;
    switch (action.type) {
        case Types.FETCH_SLOGAN:
            state = action.slogans;
            return [...state];


        case Types.DELETE_SLOGAN:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_SLOGAN:
            state.push(action.slogans);
            return [...state];

        case Types.UPDATE_SLOGAN:
            index = findIndex(state, slogans.id);
            state[index] = slogans;
            return [...state];
      
        case Types.UPDATE_STATUS_SLOGAN:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
       
       

        default: return [...state];
    }
}
export default slogans;