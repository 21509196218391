export const FETCH_DSBN_PHONGKHAM ='FETCH_DSBN_PHONGKHAM';
export const FETCH_PHONGKHAM ='FETCH_PHONGKHAM';
export const SEARCH_KEYWORD ='SEARCH_KEYWORD';
export const SEARCH_CATEGORY ='SEARCH_CATEGORY';
export const VIEW_BN_PK ='VIEW_BN_PK';
export const FETCH_THUOC_PHONGKHAM ='FETCH_THUOC_PHONGKHAM';
export const FETCH_DVKT_PHONGKHAM ='FETCH_DVKT_PHONGKHAM';
export const FETCH_MEDIC_PHONGKHAM ='FETCH_MEDIC_PHONGKHAM';



