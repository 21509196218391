import * as Types from '../../constants/ChuyenKhoa/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchCKhoaRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('chuyen-khoa', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchCKhoa(res.data));
                }
            })
        }
    }
 
}
export const actFetchCKhoa = (chuyenkhoas) => {
    return {
        type: Types.FETCH_CHUYENKHOAList,
        chuyenkhoas
    }
}

export const deleteCKhoaRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-chuyen-khoa/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteCKhoa(id));
        })
    }
}
export const deleteCKhoa = (id) => {
    return {
        type: Types.DELETE_CHUYENKHOA,
        id
    }
}

export const addCKhoaRequest = (chuyenkhoas) => {
    return (dispatch) => {
        return UrlApi('add-chuyen-khoa', 'POST', chuyenkhoas, authHeader()).then(res => {
            dispatch(addCKhoa(res.data));
            window.location.href = '/chuyen-khoa';
        })
    }
}

export const addCKhoa = (chuyenkhoas) => {
    return {
        type: Types.ADD_CHUYENKHOA,
        chuyenkhoas
    }
}

export const eidtCKhoaRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`chuyen-khoa/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editCKhoa(res.data));
               
            }

        })
    }
}


export const editCKhoa = (chuyenkhoas) => {
    return {
        type: Types.EDIT_CHUYENKHOA,
        chuyenkhoas
    }
}


export const updateCKhoaRequest = (chuyenkhoas) => {
    return (dispatch) => {
        return UrlApi(`update-chuyen-khoa/${chuyenkhoas.id}`, 'PUT', chuyenkhoas, authHeader()).then(res => {

           //dispatch(updateCKhoa(res.data));
           window.location.href = '/chuyen-khoa';
        })
    }
}


export const updateCKhoa = (chuyenkhoas) => {
    return {
        type: Types.UPDATE_CHUYENKHOA,
        chuyenkhoas
    }
}

export const updateisLockedCKhoaRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-chuyen-khoa/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedCKhoa(id));

        })
    }
}
export const updateisLockedCKhoa = (id) => {
    return {
        type: Types.UPDATE_STATUS_CHUYENKHOA,
        id
    }
}

