import * as Types from '../../constants/ReceiveDocumentImage/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchDocImageRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('document-image', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchDocImage(res.data));
                }
            })
        }
    }
 
}
export const actFetchDocImage = (docimages) => {
    return {
        type: Types.FETCH_DOC_IMAGE,
        docimages
    }
}

export const deleteDocImageRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-document-image/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteDocImage(id));
        })
    }
}
export const deleteDocImage = (id) => {
    return {
        type: Types.DELETE_DOC_IMAGE,
        id
    }
}

export const addDocImageRequest = (docimages) => {
    return (dispatch) => {
        return UrlApi('add-document-image', 'POST', docimages, authHeader()).then(res => {
            dispatch(addDocImage(res.data));
        })
    }
}

export const addDocImage = (docimages) => {
    return {
        type: Types.ADD_DOC_IMAGE,
        docimages
    }
}


export const eidtDocImageRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`document-image/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editDocImage(res.data));
            }

        })
    }
}
export const editDocImage = (docimages) => {
    return {
        type: Types.EDIT_DOC_IMAGE,
        docimages
    }
}


export const updateDocImageRequest = (docimages) => {
    return (dispatch) => {
        return UrlApi(`update-document-image/${docimages.id}`, 'PUT', docimages, authHeader()).then(res => {
            dispatch(updateDocImage(res.data));

        })
    }
}
export const updateDocImage = (docimages) => {
    return {
        type: Types.UPDATE_DOC_IMAGE,
        docimages
    }
}

