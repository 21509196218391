import * as Types from '../../constants/DatHen/ActionTypes';
var initialState = [];
const catesearchphongkhams = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_SEARCH_CATE_DATHEN:
            state = action.catesearchphongkhams;
            return [...state];

        default: return [...state];
    }
}
export default catesearchphongkhams;