import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

import UserPage from './pages/User/UserPage';
import UserActionPage from './pages/User/UserActionPage';

import ClinicPage from './pages/Clinic/ClinicPage';
import ClinicActionPage from './pages/Clinic/ClinicActionPage';

import DoctorPage from './pages/Doctors/DoctorPage';
import DoctorActionPage from './pages/Doctors/DoctorActionPage';

import MapUserClinicPage from './pages/MapUserClinic/MapUserClinicPage';
import MapUserClinicActionPage from './pages/MapUserClinic/MapUserClinicActionPage';

import PatientsPage from './pages/Patients/PatientsPage';
import PatientsActionPage from './pages/Patients/PatientsActionPage';

import PatientReceivePage from './pages/PatientReceive/PatientReceivePage';
import PatientReceiveActionPage from './pages/PatientReceive/PatientReceiveActionPage';

import SuggServicePage from './pages/SuggestedServiceReceipt/SuggServicePage';
import SuggServiceActionPage from './pages/SuggestedServiceReceipt/SuggServiceActionPage';

import DocImagePage from './pages/ReceiveDocumentImage/DocImagePage';
import DocImageActionPage from './pages/ReceiveDocumentImage/DocImageActionPage';

import MedicRecordPage from './pages/MedicalRecord/MedicRecordPage';
import MedicRecordActionPage from './pages/MedicalRecord/MedicRecordActionPage';

import MedicRecordDetailPage from './pages/MedicalRecordDetail/MedicRecordDetailPage';
import MedicRecordDetailActionPage from './pages/MedicalRecordDetail/MedicRecordDetailActionPage';

import ServiceRadiologyEntryPage from './pages/ServiceRadiologyEntry/ServiceRadiologyEntryPage';
import ServiceRadiologyEntryActionPage from './pages/ServiceRadiologyEntry/ServiceRadiologyEntryActionPage';


import ServiceLaboratoryEntryPage from './pages/ServiceLaboratoryEntry/ServiceLaboratoryEntryPage';
import ServiceLaboratoryEntryActionPage from './pages/ServiceLaboratoryEntry/ServiceLaboratoryEntryActionPage';

import DSBNPhongKhamPage from './pages/DSBNPhongKham/DSBNPhongKhamPage';
import DSBNPKViewPage from './pages/DSBNPhongKham/DSBNPKViewPage';

import DSBNAdminPage from './pages/DSBNAdmin/DSBNAdminPage';
import DSBNAdminViewPage from './pages/DSBNAdmin/DSBNAdminViewPage';
import KetQuaCLSPage from './pages/DSBNAdmin/KetQuaCLSPage';

import DatHenPage from './pages/DatHen/DatHenPage';
import DatHenActionPage from './pages/DatHen/DatHenActionPage';

import NewsPage from './pages/News/NewsPage';
import NewsActionPage from './pages/News/NewsActionPage';

import AboutsPage from './pages/Abouts/AboutsPage';
import AboutsActionPage from './pages/Abouts/AboutsActionPage';

import SloganPage from './pages/Slogan/SloganPage';
import SloganActionPage from './pages/Slogan/SloganActionPage';


import HoiDapPage from './pages/HoiDap/HoiDapPage';
import HoiDapActionPage from './pages/HoiDap/HoiDapActionPage';

import TraLoiPage from './pages/TraLoi/TraLoiPage';
import TraLoiActionPage from './pages/TraLoi/TraLoiActionPage';

import ChuyenKhoaPage from './pages/ChuyenKhoa/ChuyenKhoaPage';
import ChuyenKhoaActionPage from './pages/ChuyenKhoa/ChuyenKhoaActionPage';

import KetQuaXNActionPage from './pages/TraCuuKetQua/KetQuaXNActionPage';


import NotifiPage from './pages/Notification/NotifiPage';
import NotifiActionPage from './pages/Notification/NotifiActionPage';


const routes = [
    {
        path: '/',
        exact: true,
        main: () => <HomePage />
    },
    {
        path: '/user/add',
        exact: false,
        main: ({ history }) => <UserActionPage history={history} />
    },
    {
        path: '/user/:id/edit',
        exact: false,
        main: ({ match, history }) => <UserActionPage match={match} history={history} />
    },
    {
        path: '/user',
        exact: false,
        main: () => <UserPage />
    },
 
     /*============*/
    {
        path: '/clinic/add',
        exact: false,
        main: ({ history }) => <ClinicActionPage history={history} />
    },
    {
        path: '/clinic/:id/edit',
        exact: false,
        main: ({ match, history }) => <ClinicActionPage match={match} history={history} />
    },
    {
        path: '/clinic',
        exact: false,
        main: () => <ClinicPage />
    },
    /*============*/
    {
        path: '/doctor/add',
        exact: false,
        main: ({ history }) => <DoctorActionPage history={history} />
    },
    {
        path: '/doctor/:id/edit',
        exact: false,
        main: ({ match, history }) => <DoctorActionPage match={match} history={history} />
    },
    {
        path: '/doctor',
        exact: false,
        main: () => <DoctorPage />
    },
    /*============*/
    {
        path: '/map-user-clinic/add',
        exact: false,
        main: ({ history }) => <MapUserClinicActionPage history={history} />
    },
    {
        path: '/map-user-clinic/:id/edit',
        exact: false,
        main: ({ match, history }) => <MapUserClinicActionPage match={match} history={history} />
    },
    {
        path: '/map-user-clinic',
        exact: false,
        main: () => <MapUserClinicPage />
    },

    /*============*/
    {
        path: '/patients/add',
        exact: false,
        main: ({ history }) => <PatientsActionPage history={history} />
    },
    {
        path: '/patients/:id/edit',
        exact: false,
        main: ({ match, history }) => <PatientsActionPage match={match} history={history} />
    },
    {
        path: '/patients',
        exact: false,
        main: () => <PatientsPage />
    },

    /*============*/
    {
        path: '/patient-receive/add',
        exact: false,
        main: ({ history }) => <PatientReceiveActionPage history={history} />
    },
    {
        path: '/patient-receive/:id/edit',
        exact: false,
        main: ({ match, history }) => <PatientReceiveActionPage match={match} history={history} />
    },
    {
        path: '/patient-receive',
        exact: false,
        main: () => <PatientReceivePage />
    },

    /*============*/
    {
        path: '/suggested-service/add',
        exact: false,
        main: ({ history }) => <SuggServiceActionPage history={history} />
    },
    {
        path: '/suggested-service/:id/edit',
        exact: false,
        main: ({ match, history }) => <SuggServiceActionPage match={match} history={history} />
    },
    {
        path: '/suggested-service',
        exact: false,
        main: () => <SuggServicePage />
    },

    /*============*/
    {
        path: '/document-image/add',
        exact: false,
        main: ({ history }) => <DocImageActionPage history={history} />
    },
    {
        path: '/document-image/:id/edit',
        exact: false,
        main: ({ match, history }) => <DocImageActionPage match={match} history={history} />
    },
    {
        path: '/document-image',
        exact: false,
        main: () => <DocImagePage />
    },

    /*============*/
    {
        path: '/medical-record/add',
        exact: false,
        main: ({ history }) => <MedicRecordActionPage history={history} />
    },
    {
        path: '/medical-record/:id/edit',
        exact: false,
        main: ({ match, history }) => <MedicRecordActionPage match={match} history={history} />
    },
    {
        path: '/medical-record',
        exact: false,
        main: () => <MedicRecordPage />
    },
    /*============*/
    {
        path: '/medical-record-detail/add',
        exact: false,
        main: ({ history }) => <MedicRecordDetailActionPage history={history} />
    },
    {
        path: '/medical-record-detail/:id/edit',
        exact: false,
        main: ({ match, history }) => <MedicRecordDetailActionPage match={match} history={history} />
    },
    {
        path: '/medical-record-detail',
        exact: false,
        main: () => <MedicRecordDetailPage />
    },
    /*============*/
    {
        path: '/service-radiology-entry/add',
        exact: false,
        main: ({ history }) => <ServiceRadiologyEntryActionPage history={history} />
    },
    {
        path: '/service-radiology-entry/:id/edit',
        exact: false,
        main: ({ match, history }) => <ServiceRadiologyEntryActionPage match={match} history={history} />
    },
    {
        path: '/service-radiology-entry',
        exact: false,
        main: () => <ServiceRadiologyEntryPage />
    },
     /*============*/
     {
        path: '/service-laboratory-entry/add',
        exact: false,
        main: ({ history }) => <ServiceLaboratoryEntryActionPage history={history} />
    },
    {
        path: '/service-laboratory-entry/:id/edit',
        exact: false,
        main: ({ match, history }) => <ServiceLaboratoryEntryActionPage match={match} history={history} />
    },
    {
        path: '/service-laboratory-entry',
        exact: false,
        main: () => <ServiceLaboratoryEntryPage />
    },
    /* danh sach benh nhan theo phong kham */
    {
        path: '/danh-sach-benh-nhan-phong-kham',
        exact: false,
        main: () => <DSBNPhongKhamPage />
    },
    {
        path: '/benh-nhan/pk_:idpk/:id',
        exact: false,
        main: ({ match, history }) => <DSBNPKViewPage match={match} history={history} />
    },
     /* danh sach benh nhan theo phong kham  admin*/
     {
        path: '/danh-sach-benh-nhan',
        exact: false,
        main: () => <DSBNAdminPage />
    },
    {
        path: '/show-benh-nhan/pk_:idpk/:id',
        exact: false,
        main: ({ match, history }) => <DSBNAdminViewPage match={match} history={history} />
    },
    {
        path: '/ket-qua-cls/:idBN/:id',
        exact: false,
        main: ({ match, history }) => <KetQuaCLSPage match={match} history={history} />
    },

     /* danh sach dat hen*/
     {
        path: '/danh-sach-dat-hen',
        exact: false,
        main: () => <DatHenPage />
    },
    {
        path: '/dat-hen/:id/edit',
        exact: false,
        main: ({ match, history }) => <DatHenActionPage match={match} history={history} />
    },
    


    /* danh sach dat hen*/
    {
        path: '/news/add',
        exact: false,
        main: ({ history }) => <NewsActionPage history={history} />
    },
    {
        path: '/news/:id/edit',
        exact: false,
        main: ({ match, history }) => <NewsActionPage match={match} history={history} />
    },
    {
        path: '/news',
        exact: false,
        main: () => <NewsPage />
    },

     /*============*/
     {
        path: '/abouts/add',
        exact: false,
        main: ({ history }) => <AboutsActionPage history={history} />
    },
    {
        path: '/abouts/:id/edit',
        exact: false,
        main: ({ match, history }) => <AboutsActionPage match={match} history={history} />
    },
    {
        path: '/abouts',
        exact: false,
        main: () => <AboutsPage />
    },


      /*============*/
      {
        path: '/slogan/add',
        exact: false,
        main: ({ history }) => <SloganActionPage history={history} />
    },
    {
        path: '/slogan/:id/edit',
        exact: false,
        main: ({ match, history }) => <SloganActionPage match={match} history={history} />
    },
    {
        path: '/slogan',
        exact: false,
        main: () => <SloganPage />
    },
    /*============*/
    {
        path: '/hoi-dap/add',
        exact: false,
        main: ({ history }) => <HoiDapActionPage history={history} />
    },
    {
        path: '/hoi-dap/:id/edit',
        exact: false,
        main: ({ match, history }) => <HoiDapActionPage match={match} history={history} />
    },
    {
        path: '/hoi-dap',
        exact: false,
        main: () => <HoiDapPage />
    },
     /*============*/
     {
        path: '/tra-loi/add',
        exact: false,
        main: ({ history }) => <TraLoiActionPage history={history} />
    },
    {
        path: '/tra-loi/:id/edit',
        exact: false,
        main: ({ match, history }) => <TraLoiActionPage match={match} history={history} />
    },
    {
        path: '/tra-loi',
        exact: false,
        main: () => <TraLoiPage />
    },
    /*============*/
    {
        path: '/chuyen-khoa/add',
        exact: false,
        main: ({ history }) => <ChuyenKhoaActionPage history={history} />
    },
    {
        path: '/chuyen-khoa/:id/edit',
        exact: false,
        main: ({ match, history }) => <ChuyenKhoaActionPage match={match} history={history} />
    },
    {
        path: '/chuyen-khoa',
        exact: false,
        main: () => <ChuyenKhoaPage />
    },

     /*============*/
     {
        path: '/tra-cuu-ket-qua-xet-nghiem',
        exact: false,
        main: ({ match,history }) => <KetQuaXNActionPage match={match} history={history} />
    },
    /*============*/
    {
        path: '/thong-bao/add',
        exact: false,
        main: ({ history }) => <NotifiActionPage history={history} />
    },
    {
        path: '/thong-bao/:id/edit',
        exact: false,
        main: ({ match, history }) => <NotifiActionPage match={match} history={history} />
    },
    {
        path: '/thong-bao',
        exact: false,
        main: () => <NotifiPage />
    },
   
    {
        path: '',
        exact: false,
        main: () => <NotFoundPage />
    }


];
export default routes;

