import * as Types from '../../constants/MedicalRecordDetail/ActionTypes';
var initialState = [];

var findIndex = (medicrecorddetails, id) => {
    var result = -1;
    medicrecorddetails.forEach((medicrecorddetail, index) => {
        if (medicrecorddetail.id === id) {
            result = index;
        }
    })
    return result;
}
const medicrecorddetails = (state = initialState, action) => {
    var index = -1;
    var { id, medicrecorddetails } = action;
    switch (action.type) {
        case Types.FETCH_MEDIC_RECORD_DETAIL:
            state = action.medicrecorddetails;
            return [...state];
        case Types.DELETE_MEDIC_RECORD_DETAIL:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_MEDIC_RECORD_DETAIL:
            state.push(action.medicrecorddetails);
            return [...state];

        case Types.UPDATE_MEDIC_RECORD_DETAIL:
            index = findIndex(state, medicrecorddetails.id);
            state[index] = medicrecorddetails;
            return [...state];
        
        default: return [...state];
    }
}
export default medicrecorddetails;