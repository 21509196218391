import * as Types from '../../constants/DSBNPhongKham/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';
export const actFetchDSBNPKRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('danh-sach-benh-nhan-theo-phong-kham','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchDSBN(res.data))
                }
            }
        })
    }
}
export const actFetchDSBN = (dsbnphongkhams) => {
    return {
        type: Types.FETCH_DSBN_PHONGKHAM,
        dsbnphongkhams
    }
}

export const actFetchCatePhongKhamRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('cate-phong-kham','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchCatePhongKham(res.data))
                }
            }
            
        })
    }
}
export const actFetchCatePhongKham = (catephongkhams) => {
    return {
        type: Types.FETCH_PHONGKHAM,
        catephongkhams
    }
}

export const actSearchKeyWord = keyword => {
    return {
      type: Types.SEARCH_KEYWORD,
      keyword
    };
  };
  export const actSearchCategory = categoryID => {
    return {
      type: Types.SEARCH_CATEGORY,
      categoryID
    };
  };


  export const showBNPhongKhamRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`benh-nhan-theo-phong-kham/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showBNPhongKham(res.data));
            }

        })
    }
}
export const showBNPhongKham = (dsbnphongkhams) => {
    return {
        type: Types.VIEW_BN_PK,
        dsbnphongkhams
    }
}


export const showCTThuocPhongKhamRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`thuoc-benh-nhan-theo-phong-kham/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showCTThuocPhongKham(res.data));
            }

        })
    }
}
export const showCTThuocPhongKham = (chitietthuocpsk) => {
    return {
        type: Types.FETCH_THUOC_PHONGKHAM,
        chitietthuocpsk
    }
}
export const showDVKTPhongKhamRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`dvkt-benh-nhan-theo-phong-kham/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showDVTKPhongKham(res.data));
            }

        })
    }
}
export const showDVTKPhongKham = (dichvukythuatpsk) => {
    return {
        type: Types.FETCH_DVKT_PHONGKHAM,
        dichvukythuatpsk
    }
}


export const showMedicalPhongKhamRequest = (idpk,id) => {
    return (dispatch) => {
        return UrlApi(`medical-benh-nhan-theo-phong-kham/${idpk}/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showMedicalPhongKham(res.data));
            }

        })
    }
}
export const showMedicalPhongKham = (medicPKS) => {
    return {
        type: Types.FETCH_MEDIC_PHONGKHAM,
        medicPKS
    }
}




