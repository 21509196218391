import * as Types from '../../constants/DatHen/ActionTypes';
var initialState = "";
var searchkeyworddathen = (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_KEYWORD_DATHEN:
      return action.keyword;
    default:
      return state;
  }
};

export default searchkeyworddathen;
