import * as Types from '../../constants/SuggestedServiceReceipt/ActionTypes';
var initialState = [];

var findIndex = (suggestedservices, id) => {
    var result = -1;
    suggestedservices.forEach((suggestedservice, index) => {
        if (suggestedservice.id === id) {
            result = index;
        }
    })
    return result;
}
const suggestedservices = (state = initialState, action) => {
    var index = -1;
    var { id, suggestedservices } = action;
    switch (action.type) {
        case Types.FETCH_SUGGESTED_SERVICE:
            state = action.suggestedservices;
            return [...state];
        case Types.DELETE_SUGGESTED_SERVICE:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_SUGGESTED_SERVICE:
            state.push(action.suggestedservices);
            return [...state];

        case Types.UPDATE_SUGGESTED_SERVICE:
            index = findIndex(state, suggestedservices.id);
            state[index] = suggestedservices;
            return [...state];

        case Types.FETCH_SUGGESTED_SERVICE_CATE:
            state = action.suggestedservices;
            return [...state];

        default: return [...state];
    }
}
export default suggestedservices;