import React, { Component } from 'react';
class SuggServiceList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="Tableauto">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Mã TN</th>
                                            <th >Mã BN</th>
                                            <th>Mã PK</th>
                                            <th >Mã DV</th>
                                            <th >Tên</th>
                                            <th >Giá</th>
                                            <th >Số lượng</th>
                                            <th>Giá chênh lệch</th>
                                            <th>Trạng thái</th>
                                            <th>Ngày làm việc</th>
                                            <th>Thanh toán</th>
                                            <th>Mã BS - NV</th>
                                            <th>Tên BS - NV</th>
                                            <th>Ngày Chỉ định</th>
                                            <th>Mã BS - NV</th>
                                            <th>Tên BS - NV</th>
                                            <th>Ngày Trả KQ</th>

                                            <th>Ghi chứ</th>
                                            <th>Tên Đơn vị ĐL</th>
                                            <th>Loại dịch vụ</th>
                                            <th>Nhóm DV</th>
                                            <th>Mã Nhóm</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
                           
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default SuggServiceList;

