import * as Types from '../../constants/DSBNPhongKham/ActionTypes';
var initialState = [];
const chitietthuocpsk = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_THUOC_PHONGKHAM:
           state = action.chitietthuocpsk;
            return [...state];

        default: return [...state];
    }
}
export default chitietthuocpsk;