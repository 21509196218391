import React, { Component } from "react";
class SearchControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      showSearchInfo: false
    };
  }
  /*componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.keyword) {
      this.setState({
        keyword: nextProps.keyword,
        showSearchInfo: true
      });
    }
  }*/
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.keyword) {
      this.setState({
        keyword: nextProps.keyword,
        showSearchInfo: true
      });
    }

  }


  onHandleChange = event => {
    var target = event.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      [name]: value
    });
  };


  onSearch = e => {
    e.preventDefault();
    this.props.onSearch(this.state.keyword);
  };



  onClearSearch = () => {
    this.props.onSearch("");
    this.setState({
      keyword: "",
      showSearchInfo: false
    });
  };

  render() {
    return (
      <div className="col-xs-12 box_search">
        <form onSubmit={this.onSearch}>
          <div className="search_wrp mb-15">
            
              <div className="row">
                <div className="col-md-8">
                  <input
                    type="text"
                    name="keyword"
                    className="form-control"
                    placeholder="Nhập tên, Điện thoại..."
                    value={this.state.keyword}
                 
                    onChange={this.onHandleChange}
                  
                  />
                </div>
                <div className="col-md-4">
                  <div className="input-group">

                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.onSearch}
                      >
                        <i className="fa fa-search" />
                        Tìm
                      </button>
                      <button
                        className="btn btn-primary clearBtn"
                        type="button"
                        onClick={this.onClearSearch}
                      >
                        <i className="fa fa-times"></i>
                         Clear
                      </button>
                      
                    </span>
                  </div>
                  
                </div>
              </div>
            
          </div>

        </form>
      </div>
    );
  }
}

export default SearchControl;
