import {combineReducers} from 'redux';
import payload from './Login/reLogin';

import users from './User/reUser';
import ItemEditingUser from './User/ItemEditingUser';
import searchusers from './User/SearchUser';

import clinics from './Clinic/reClinic';
import EditingClinic from './Clinic/EditingClinic';

import doctors from './Doctors/reDoctor';
import EditingDoctors from './Doctors/EditingDoctor';

import usersclinics from './MapUserClinic/reMapUserClinic';
import EditingMapUserClinic from './MapUserClinic/EditingMapUserClinic';

import patients from './Patients/rePatients';
import EditingPatients from './Patients/EditingPatients';

import patientreceives from './PatientReceive/rePatientReceive';
import EditingPatientReceive from './PatientReceive/EditingPatientReceive';

import suggestedservices from './SuggestedServiceReceipt/reSuggService';
import EditingSuggService from './SuggestedServiceReceipt/EditingSuggService';

import docimages from './ReceiveDocumentImage/reDocImage';
import EditingDocImage from './ReceiveDocumentImage/EditingDocImage';

import medicrecords from './MedicalRecord/reMedicRecord';
import EditingMedicRecord from './MedicalRecord/EditingMedicRecord';

import medicrecorddetails from './MedicalRecordDetail/reMedicRecordDetail';
import EditingMedicRecordDetail from './MedicalRecordDetail/EditingMedicRecordDetail';

import serviceradios from './ServiceRadiologyEntry/reServiceRadiologyEntry';
import EditingServiceRadiologyEntry from './ServiceRadiologyEntry/EditingServiceRadiologyEntry';

import servicelaboratorys from './ServiceLaboratoryEntry/reServiceLaboratoryEntry';
import EditingServiceLaboratoryEntry from './ServiceLaboratoryEntry/EditingServiceLaboratoryEntry';

import lichsukhambenhs from './View/reViewLSKB';
import chitietthuocs from './View/reViewCTThuoc';
import dichvukythuats from './View/reViewDVKyThuat';
import medicalrecords from './View/reViewMedicalRecord';

import dsbnphongkhams from './DSBNPhongKham/reDSBNPhongKham';
import catephongkhams from './DSBNPhongKham/reCatePhongKham';
import searchkeywordphongkham from './DSBNPhongKham/reSearchKeyword';
import searchcatephongkham from './DSBNPhongKham/reSearchCategory';
import reShowBNPhongKham from './DSBNPhongKham/reShowBNPhongKham';
import chitietthuocpsk from './DSBNPhongKham/reViewCTThuocPhongKham';
import dichvukythuatpsk from './DSBNPhongKham/reViewDVKTPhongKham';
import medicPKS from './DSBNPhongKham/reViewMedicalPhongKham';



import dsbnadmins from './DSBNAdmin/reDSBNAdmin';
import cateadmins from './DSBNAdmin/reCateAdmin';
import searchkeywordadmin from './DSBNAdmin/reSearchKeywordAdmin';
import searchcateadmin from './DSBNAdmin/reSearchCategoryAdmin';
import reShowBNAdmin from './DSBNAdmin/reShowBNAdmin';
import chitietthuocAd from './DSBNAdmin/reViewCTThuocAdmin';
import dichvukythuatAd from './DSBNAdmin/reViewDVKTAdmin';
import medicalAd from './DSBNAdmin/reViewMedicalAdmin';
import ketquacls from './DSBNAdmin/reKetQuaCLSAdmin';
import phongkhamcls from './DSBNAdmin/rePhongKhamCLSAdmin';
import benhnhancls from './DSBNAdmin/reBenhNhanCLSAdmin';
import tieudecls from './DSBNAdmin/reTieuDeCLSAdmin';
import ketquaxetnghiem from './DSBNAdmin/reXetNghiemAdmin';
import ketquaxetnghiemresult from './DSBNAdmin/reKetQuaXNResultAdmin';
import thongtinuser from './DSBNAdmin/reViewThongTinUser';

import dathens from './DatHen/reDatHen';
import itemEditingDatHen from './DatHen/EditingDatHen';
import catedoctordathens from './DatHen/reCateDatHen';
import clinicscatedhs from './DatHen/reCateClinic';
import clinicscatedhuser from './DatHen/reCateClinicUser';

import catesearchphongkhams from './DatHen/reFetchClinicSearch';
import searchcatedathen from './DatHen/reSearchCateDatHen';
import searchkeyworddathen from './DatHen/reSearchKeyworDatHen';


import news from './News/reNews';
import EditingNews from './News/EditingNews';


import abouts from './Abouts/reAbouts';
import itemEditingAbouts from './Abouts/EditingAbouts';

import slogans from './Slogan/reSlogan';
import itemEditingSlogan from './Slogan/EditingSlogan';


import hoidaps from './HoiDap/reHoiDap';
import itemEditingHoiDap from './HoiDap/EditingHoiDap';

import tralois from './TraLoi/reTraLoi';
import EditingTraLoi from './TraLoi/EditingTraLoi';

import tinhthanh from './Location/reTinhThanh';
import quanhuyen from './Location/reQuanHuyen';
import phuongxa from './Location/rePhuongXa';
import chuyenkhoa from './Location/reChuyenKhoa';


import chuyenkhoas from './ChuyenKhoa/reChuyenKhoa';
import EditChuyenKhoa from './ChuyenKhoa/EditingChuyenKhoa';
import tracuuketquaxetnghiem from './TraCuuKetQua/reViewKetQuaXetNghiem';
import clinictracuus from './TraCuuKetQua/reClinicTraCuu';

import notifications from './Notification/reNotifi';
import itemEditingNotifi from './Notification/EditingNotifi';
import clinicnotifi from './Notification/reClinicNotifi';

const appReducers = combineReducers({
    tinhthanh,
    quanhuyen,
    phuongxa,
    chuyenkhoa,
    payload,
    users,
    ItemEditingUser,
    searchusers,
    clinics,
    EditingClinic,
    doctors,
    EditingDoctors,
    usersclinics,
    EditingMapUserClinic,
    patients,
    EditingPatients,
    patientreceives,
    EditingPatientReceive,
    suggestedservices,
    EditingSuggService,
    docimages,
    EditingDocImage,
    medicrecords,
    EditingMedicRecord,
    medicrecorddetails,
    EditingMedicRecordDetail,
    serviceradios,
    EditingServiceRadiologyEntry,
    servicelaboratorys,
    EditingServiceLaboratoryEntry,
    lichsukhambenhs,
    chitietthuocs,
    dichvukythuats,
    medicalrecords,

    dsbnphongkhams,
    catephongkhams,
    searchkeywordphongkham,
    searchcatephongkham,
    reShowBNPhongKham,
    chitietthuocpsk,
    dichvukythuatpsk,
    medicPKS,
    thongtinuser,

    dsbnadmins,
    cateadmins,
    searchkeywordadmin,
    searchcateadmin,
    reShowBNAdmin,
    chitietthuocAd, 
    dichvukythuatAd,
    ketquacls,
    benhnhancls,
    phongkhamcls,
    tieudecls,
    ketquaxetnghiem,
    ketquaxetnghiemresult,
    medicalAd,

    dathens,
    itemEditingDatHen,
    catedoctordathens,
    clinicscatedhs,
    clinicscatedhuser,
    catesearchphongkhams,
    searchcatedathen,
    searchkeyworddathen,
    news,
    EditingNews,

    abouts,
    itemEditingAbouts,

    slogans,
    itemEditingSlogan,
    
    hoidaps,
    itemEditingHoiDap,
     tralois,
    EditingTraLoi, 
    EditChuyenKhoa,
    chuyenkhoas,
    tracuuketquaxetnghiem,
    clinictracuus,
    notifications,
    itemEditingNotifi,
    clinicnotifi

});
export default appReducers;