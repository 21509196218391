
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
class DoctorItem extends Component {
    componentDidMount(){
        this.props.fetchAllCategoryClinic();
    }

    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }

    render() {
        var { doctor } = this.props;
        var statusname = doctor.isLocked === true ? 'Hiện' : 'Ẩn';
        var statusclass = doctor.isLocked === true ? 'btn-success' : 'btn-warning';
        var cate  = this.props.clinics
        .filter(commentReply => commentReply.value === doctor.ClinicCode)
        .map((commentReply, idx) => <span key={idx}>{commentReply.label}</span>);
       
        return (
            <tr>
                <td>{doctor.DoctorCode}</td>
                <td>{doctor.DoctorCode_HIS}</td>
                <td>{doctor.DoctorName}</td>
                <td>{doctor.Mobile}</td>
                <td>{doctor.MaCCHN}</td>
                <td>{doctor.MaChuyenKhoa}</td>
                <td>{cate}</td>

                <td >
                    <span className={`labelIs btn btn-block  btn-xs ${statusclass}`} onClick={() => this.onUpdateStatus(doctor.RowID)} >
                        {statusname}
                    </span>
                </td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(doctor.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/doctor/${doctor.RowID}/edit`}  className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
const mapStateToProps = state => {
    return {
        clinics: state.clinics
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
       
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },
      
       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DoctorItem);

