import * as Types from '../../constants/Doctors/ActionTypes';
var initialState = [];
const catedoctordathens = (state = initialState, action) => {
    switch (action.type) {
        case Types.DATHEN_DOCTORS:
                state = action.catedoctordathens;
                return [...state];
        
        default: return [...state];
    }
}
export default catedoctordathens;