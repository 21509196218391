import * as Types from '../../constants/MedicalRecordDetail/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchMedicRecordDetailRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('medical-record-detail', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchMedicRecordDetail(res.data));
                }
            })
        }
    }
 
}
export const actFetchMedicRecordDetail = (medicrecorddetails) => {
    return {
        type: Types.FETCH_MEDIC_RECORD_DETAIL,
        medicrecorddetails
    }
}

export const deleteMedicRecordDetailRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-medical-record-detail/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteMedicRecordDetail(id));
        })
    }
}
export const deleteMedicRecordDetail = (id) => {
    return {
        type: Types.DELETE_MEDIC_RECORD_DETAIL,
        id
    }
}

export const addMedicRecordDetailRequest = (medicrecorddetails) => {
    return (dispatch) => {
        return UrlApi('add-medical-record-detail', 'POST', medicrecorddetails, authHeader()).then(res => {
            dispatch(addMedicRecordDetail(res.data));
        })
    }
}

export const addMedicRecordDetail = (medicrecorddetails) => {
    return {
        type: Types.ADD_MEDIC_RECORD_DETAIL,
        medicrecorddetails
    }
}


export const eidtMedicRecordDetailRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`medical-record-detail/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editMedicRecordDetail(res.data));
            }

        })
    }
}
export const editMedicRecordDetail = (medicrecorddetails) => {
    return {
        type: Types.EDIT_MEDIC_RECORD_DETAIL,
        medicrecorddetails
    }
}


export const updateMedicRecordDetailRequest = (medicrecorddetails) => {
    return (dispatch) => {
        return UrlApi(`update-medical-record-detail/${medicrecorddetails.id}`, 'PUT', medicrecorddetails, authHeader()).then(res => {
            dispatch(updateMedicRecordDetail(res.data));

        })
    }
}
export const updateMedicRecordDetail = (medicrecorddetails) => {
    return {
        type: Types.UPDATE_MEDIC_RECORD_DETAIL,
        medicrecorddetails
    }
}

