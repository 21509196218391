
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class TraLoiItem extends Component {
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }
    render() {
        var { traloi } = this.props;
       // var statusname = traloi.isLocked === true ? 'Hiện' : 'Ẩn';
       // var statusclass = traloi.isLocked === true ? 'btn-success' : 'btn-warning';

        return (
            <tr>
                <td>{traloi.TieuDe}</td>
                <td>{traloi.NoteDap}</td>
                <td>{traloi.ShowIUDate}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(traloi.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/tra-loi/${traloi.RowID}/edit`}  className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default TraLoiItem;

