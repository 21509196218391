import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const ketquaxetnghiem = (state = initialState, action) => {
    switch (action.type) {
        case Types.XETNGHIEM_CLS_ADMIN:
           state = action.ketquaxetnghiem;
           return [...state];
           //return state;

        default: return [...state];
        //default: return state;;
    }
}
export default ketquaxetnghiem;