import React, { Component } from 'react';
import DSBNAdminList from './../../components/DSBNAdmin/DSBNAdminList'
import DSBNAdminItem from './../../components/DSBNAdmin/DSBNAdminItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import { actFetchDSBNAdminRequest,actSearchKeyWord,actSearchCategory } from '../../actions/DSBNAdmin/AcDSBNAdmin';
import SearchControl from "./SearchControl";

class DSBNAdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
        }

    }
    componentDidMount() {
        this.props.fetchAllDSBN();
        this.props.getProfileFetch();

    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    onSearch = (categoryID,keyword) => {
        this.props.onSearchPK(keyword);
        this.props.onSearchCate(categoryID);
    };
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };


    render() {
        var { dsbnadmins,keyword,categoryID } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];

        if (keyword) {
            dsbnadmins = dsbnadmins.filter(dsbnadmin => {
                return dsbnadmin.PatientName.toLowerCase().indexOf(keyword) !== -1 || dsbnadmin.PatientMobile.toLowerCase().indexOf(keyword) !== -1;
            });
        }
        if (categoryID) {
            dsbnadmins = dsbnadmins.filter(dsbnadmin => {
                return dsbnadmin.ClinicCode === categoryID ;
            });
        }
        if(categoryID && keyword){
            dsbnadmins = dsbnadmins.filter(dsbnadmin => {
                return dsbnadmin.ClinicCode === categoryID && ( dsbnadmin.PatientName.toLowerCase().indexOf(keyword) !== -1 || dsbnadmin.PatientMobile.toLowerCase().indexOf(keyword) !== -1);
            });
        }
        rowsPerPage = dsbnadmins.slice(startIndex, endIndex + 1);

        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => { this.setState({ thongbaoxoa: false }); localStorage.removeItem("token"); window.location.reload(true); }}
                    button='Đăng nhập'
                />

            );
        }

        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <SearchControl
                                onSearch={this.onSearch}
                                keyword={this.props.keyword}
                                categoryID={this.props.categoryID}
                            />

                        </div>
                    </div>
                </div>


                <DSBNAdminList>
                    {this.showDanhSach(rowsPerPage)}
                </DSBNAdminList>
                <p className="totalPhongKham">
                    Tổng cộng: {dsbnadmins.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={dsbnadmins.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />


            </div>
        );
    }
    showDanhSach(dsbnadmins) {
        var result = null;
        if (dsbnadmins.length > 0) {
            result = dsbnadmins.map((dsbnadmin, index) => {
                return (
                    <DSBNAdminItem
                        key={index}
                        dsbnadmin={dsbnadmin}
                        index={index}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        dsbnadmins: state.dsbnadmins,
        payload: state.payload, 
        keyword: state.searchkeywordadmin,
        categoryID: state.searchcateadmin
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllDSBN: () => {
            dispatch(actFetchDSBNAdminRequest());
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
        onSearchPK: (keyword) => {
            dispatch(actSearchKeyWord(keyword));
        },
        onSearchCate: (categoryID) => {
            dispatch(actSearchCategory(categoryID));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DSBNAdminPage);