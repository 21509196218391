import * as Types from '../../constants/News/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchNewsRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('news', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchNews(res.data));
                }
            })
        }
    }
 
}
export const actFetchNews = (news) => {
    return {
        type: Types.FETCH_NEWS,
        news
    }
}

export const deleteNewsRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-news/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteNews(id));
        })
    }
}
export const deleteNews = (id) => {
    return {
        type: Types.DELETE_NEWS,
        id
    }
}

export const addNewsRequest = (news) => {
    return (dispatch) => {
        return UrlApi('add-news', 'POST', news, authHeader()).then(res => {
            dispatch(addNews(res.data));
            window.location.href = '/news';
        })
    }
}

export const addNews = (news) => {
    return {
        type: Types.ADD_NEWS,
        news
    }
}


export const eidtNewsRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`news/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editNews(res.data));
            }

        })
    }
}


export const editNews = (news) => {
    return {
        type: Types.EDIT_NEWS,
        news
    }
}


export const updateNewsRequest = (news) => {
    return (dispatch) => {
        return UrlApi(`update-news/${news.id}`, 'PUT', news, authHeader()).then(res => {
            dispatch(updateNews(res.data));
            window.location.href = '/news';

        })
    }
}


export const updateNews = (news) => {
    return {
        type: Types.UPDATE_NEWS,
        news
    }
}

export const updateisLockedNewsRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-news/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedNews(id));

        })
    }
}
export const updateisLockedNews = (id) => {
    return {
        type: Types.UPDATE_STATUS_NEWS,
        id
    }
}

/*
export const actSearchBacSi = keyword => {
    return {
      type: Types.SEARCH_BACSI,
      keyword
    };
  };
  */