import * as Types from '../../constants/View/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchViewMedicalRecordRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('show-medicalrecords','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchViewMedicalRecord(res.data))
                }
            }
            
        })
    }
}
export const actFetchViewMedicalRecord = (medicalrecords) => {
    return {
        type: Types.FETCH_MEDICRE,
        medicalrecords
    }
}

