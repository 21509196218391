import * as Types from '../../constants/View/ActionTypes';
var initialState = [];
const chitietthuocs = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_VIEW_CTThuoc:
           state = action.chitietthuocs;
            return [...state];

        default: return [...state];
    }
}
export default chitietthuocs;