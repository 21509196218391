import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { showBNAdminRequest, showCTThuocAdminRequest, showDVKTAdminRequest, showMedicAdminRequest, showThongTinUserRequest } from '../../actions/DSBNAdmin/AcDSBNAdmin';
import Loading from '../Loading/Loading';

import { APIURL } from '../../constants/urlIp';
class DSBNAdminViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            thongbao: true,
            hidden: false,
            hiddenID: '',
            isCompleted: false,
            index: 10,
            timeSatus: true
        }
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            var idpk = match.params.idpk;
            setTimeout(() => this.setState({ timeSatus: false }), 3500);
            this.props.onShowBN(idpk, id)
            this.props.ShowMedical(idpk, id);
            this.props.ShowChiTietThuoc(idpk, id);
            this.props.ShowDichVuKyThuat(idpk, id);
            this.props.ShowThongTinUser(idpk, id);
        }
    }
    componentWillUnmount() {
        this.setState({ timeSatus: true })
    }
    ClickShow(id) {
        if (id) {
            this.setState({
                hidden: true,
                hiddenID: id
            })
        } else {
            this.setState({
                hidden: false
            })
        }
    }
    Clickhidden() {
        this.setState({
            hidden: false,
        })
    }
    loadMore = () => {
        this.setState({
            index: this.state.index + 10
        })
        if (this.state.index >= this.props.reShowBNAdmin.length) {
            this.setState({
                isCompleted: true
            })
        } else {
            this.setState({
                isCompleted: false
            })
        }
    }



    render() {
        console.log(this.props.dichvukythuatAd);
        var { reShowBNAdmin, thongtinuser } = this.props;
        const initialPosts = reShowBNAdmin.slice(0, this.state.index);
        if (this.state.timeSatus === true) {
            return (
                <div className='LoadingStatus'><Loading /></div>
            );
        } else {
            return (
                <div className="homeMain">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='leftview'>
                                {
                                    thongtinuser.map((item, index) => {
                                        return (
                                            <div className='leftviewitem' key={index}>
                                                <div className='itemuser'><span>Họ & tên:</span> {item.PatientName}</div>
                                                <div className='itemuser'><span>Ngày sinh:</span> {item.PatientBirthDay}</div>
                                                <div className='itemuser'><span>Giới tính:</span> {item.PatientGender ===0 ? 'Nữ': 'Nam'}</div>
                                                <div className='itemuser'><span>Điện thoại:</span> {item.PatientMobile}</div>
                                                <div className='itemuser'><span>Địa chỉ:</span> {item.PatientAddress}</div>
                                            </div>

                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='rightview'>
                                <div className="container">
                                    <div> {this.showLSKB(initialPosts)}</div>
                                    {reShowBNAdmin.length <= 10 ?
                                        null
                                        :
                                        <div className='loadmore'>
                                            {this.state.isCompleted ? (
                                                null
                                            ) : (
                                                <button onClick={this.loadMore} type="button" className="btn btndanger">
                                                    Xem thêm
                                                </button>
                                            )}
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            );
        }
    }

    showLSKB(reShowBNAdmin) {
        var result = null;
        var iThuoc = 1;
        var iDichVu = 1;
        if (reShowBNAdmin.length > 0) {
            var classMain = reShowBNAdmin.length === 1 ? 'mainRow1' : 'mainRow';

            result = reShowBNAdmin.map((LSKB, index) => {
                return (
                    <div className={classMain} key={index}>
                        <div className="mainRowNgay">
                            {this.state.hidden && this.state.hiddenID === LSKB.DatetimeInto ?
                                <span onClick={() => this.Clickhidden()}>Ngày khám: {moment(LSKB.datePostingDate).format('DD-MM-YYYY')} {LSKB.timePostingDate.substr(11, 5)} </span>
                                :
                                <span onClick={() => this.ClickShow(LSKB.DatetimeInto)}>Ngày khám: {moment(LSKB.datePostingDate).format('DD-MM-YYYY')} {LSKB.timePostingDate.substr(11, 5)}</span>
                            }

                        </div>
                        {this.state.hidden && this.state.hiddenID === LSKB.DatetimeInto ?
                            <div className="RowNgayKham">
                                <div className="ClinicName">{LSKB.ClinicName}</div>
                                {

                                    this.props.medicalAd.map((medic, indexdic) => {
                                        // var date = new Date(medic.AppDate);
                                        // console.log(medic.AppDate)


                                        return (
                                            medic.PatientReceiveID === LSKB.PatientReceiveID && medic.PatientCode === LSKB.PatientCode && medic.ClinicCode === LSKB.ClinicCode ?
                                                <div key={indexdic}>
                                                    <div className="mainRowThongTin">
                                                        <div className="mainRowIcon"><span className="iconUser"><i className="fa fa-user"></i></span></div>
                                                        <div className="mainRowContent">
                                                            <div className="mainRowBacSi">
                                                                <span className="colorName">Bác sỹ </span><span> {medic.EmployeeDoctor_Name}</span>
                                                                <span className="colorName"> - Ngày</span><span> {moment(medic.datePostingDate).format('DD-MM-YYYY')}</span>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">Mạch(lần/phút):</span>
                                                                        <span>{medic.Pulse}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">Nhiệt độ:</span>
                                                                        <span>{medic.Temperature}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">HA(mmHg):</span>
                                                                        <span>{medic.BloodPressure}/{medic.BloodPressure1}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">Ch.Cao(cm):</span>
                                                                        <span>{medic.Hight}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">Căn nặng(kg):</span>
                                                                        <span>{medic.Weight}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <span className="lableName">Nhịp thở:</span>
                                                                        <span>{medic.Breath}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Lý do khám:</div>
                                                                <div className="thongtinFooter_R">{medic.Reason_KB}</div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Chuẩn đoán:</div>
                                                                <div className="thongtinFooter_R">{medic.DiagnosisCustom}</div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Bệnh chính:</div>
                                                                <div className="thongtinFooter_R">({medic.ICD10_ICD10KT_Ma}) {medic.ICD10_ICD10KT_Ten}</div>
                                                            </div>

                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Xử trí:</div>
                                                                <div className="thongtinFooter_R">{medic.TackleName}</div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Lời dặn:</div>
                                                                <div className="thongtinFooter_R">{medic.Advices_Note}</div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Ngày tái khám:</div>
                                                                <div className="thongtinFooter_R">{medic.AppointmentDate === '' ? null : moment(medic.AppDate).format('DD-MM-YYYY')}</div>
                                                            </div>
                                                            <div className="thongtinFooter">
                                                                <div className="thongtinFooter_L">Nội dung hẹn:</div>
                                                                <div className="thongtinFooter_R">{medic.AppointmentNote}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mainRowThongTin">
                                                        <div className="mainRowIcon"><span className="iconplus"><i className="fa fa-plus"></i></span></div>
                                                        <div className="mainRowContentTable">
                                                            <div className="titleToa">Toa thuốc</div>
                                                            <div className="tablePading">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">STT</th>
                                                                            <th scope="col">Thuốc - VTTH</th>
                                                                            <th scope="col">ĐVT</th>
                                                                            <th scope="col">Số lượng</th>
                                                                            <th scope="col">Cách dùng</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.props.chitietthuocAd.map((chitietthuoc, indext) => {
                                                                            return (
                                                                                chitietthuoc.MedicalRecordCode === medic.MedicalRecordCode && chitietthuoc.PatientReceiveID === medic.PatientReceiveID && chitietthuoc.PatientCode === medic.PatientCode && chitietthuoc.ClinicCode === medic.ClinicCode ?
                                                                                    <tr key={indext}>
                                                                                        <td align='center'>{this.state.hiddenID ? iThuoc++ : null}</td>
                                                                                        <td>{chitietthuoc.ItemName}</td>
                                                                                        <td align='center'>{chitietthuoc.UnitOfMeasureName}</td>
                                                                                        <td align='center'>{chitietthuoc.Quantity}</td>
                                                                                        <td>{chitietthuoc.Instruction}: {chitietthuoc.Instruction}</td>
                                                                                    </tr>
                                                                                    : null
                                                                            )
                                                                        })
                                                                        }



                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                : null
                                        )


                                    })



                                }


                                <div className="mainRowThongTin">
                                    <div className="mainRowIcon"><span className="iconright"><i className="fa fa-arrow-right" ></i></span></div>
                                    <div className="mainRowContentTable">
                                        <div className="titleToa">Dịch vụ - Cận lâm sàng</div>
                                        <div className="tablePading">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">STT</th>
                                                        <th scope="col">Dịch vụ</th>
                                                        <th scope="col">ĐVT</th>
                                                        <th scope="col">Loại DV</th>
                                                        <th scope="col">Nhóm DV</th>
                                                        <th scope="col">T.Trạng</th>
                                                        <th scope="col">Kết quả</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.dichvukythuatAd.map((dvkt, indext) => {
                                                        return (
                                                            dvkt.PatientReceiveID === LSKB.PatientReceiveID ?
                                                                <tr key={indext} >
                                                                    <td align='center' >{this.state.hiddenID ? iDichVu++ : null}</td>
                                                                    <td>{dvkt.ServiceName}</td>
                                                                    <td>{dvkt.UnitOfMeasureName}</td>
                                                                    <td>{dvkt.ServiceCategoryName}</td>
                                                                    <td>{dvkt.ServiceGroupName}</td>
                                                                    <td>{dvkt.Status_ === 1 ? 'Đã thực hiện' : 'Chưa thực hiện'}</td>
                                                                    <td align='center'>
                                                                        {
                                                                            dvkt.UrlFile === null ? <span><i className="fa fa-eye-slash" ></i></span> :
                                                                                <span className="onclickPdf" onClick={() => window.open(`${APIURL}/${dvkt.UrlFile}`, "_blank")}> <i className="fa fa-eye" ></i></span>
                                                                        }

                                                                    </td>

                                                                </tr>

                                                                : null
                                                        )

                                                    })

                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            : null}

                    </div>
                )
            })

        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        reShowBNAdmin: state.reShowBNAdmin,
        chitietthuocAd: state.chitietthuocAd,
        dichvukythuatAd: state.dichvukythuatAd,
        medicalAd: state.medicalAd,
        thongtinuser: state.thongtinuser
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowBN: (idpk, id) => {
            dispatch(showBNAdminRequest(idpk, id));
        },
        ShowChiTietThuoc: (idpk, id) => {
            dispatch(showCTThuocAdminRequest(idpk, id));
        },
        ShowDichVuKyThuat: (idpk, id) => {
            dispatch(showDVKTAdminRequest(idpk, id));
        },
        ShowMedical: (idpk, id) => {
            dispatch(showMedicAdminRequest(idpk, id));
        },
        ShowThongTinUser: (idpk, id) => {
            dispatch(showThongTinUserRequest(idpk, id));
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DSBNAdminViewPage);
