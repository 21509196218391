import * as Types from '../../constants/Clinic/ActionTypes';
var initialState = [];
const clinicscatedhuser = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_DATHEN_CLINIC_USER:
            state = action.clinicscatedhuser;
            return [...state];

        default: return [...state];
    }
}
export default clinicscatedhuser;