import * as Types from '../../constants/Abouts/ActionTypes';
var initialState = [];

var findIndex = (abouts, id) => {
    var result = -1;
    abouts.forEach((clinic, index) => {
        if (clinic.id === id) {
            result = index;
        }
    })
    return result;
}
const abouts = (state = initialState, action) => {
    var index = -1;
    var { id, abouts } = action;
    switch (action.type) {
        case Types.FETCH_ABOUTS:
            state = action.abouts;
            return [...state];


        case Types.DELETE_ABOUTS:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_ABOUTS:
            state.push(action.abouts);
            return [...state];

        case Types.UPDATE_ABOUTS:
            index = findIndex(state, abouts.id);
            state[index] = abouts;
            return [...state];
      
        case Types.UPDATE_STATUS_ABOUTS:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
       
       

        default: return [...state];
    }
}
export default abouts;