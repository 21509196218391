export const FETCH_CLINIC ='FETCH_CLINIC';
export const ADD_CLINIC ='ADD_CLINIC';
export const UPDATE_CLINIC ='UPDATE_CLINIC';
export const DELETE_CLINIC ='DELETE_CLINIC';
export const EDIT_CLINIC ='EDIT_CLINIC';
export const UPDATE_STATUS_CLINIC ='UPDATE_STATUS_CLINIC';
export const SEARCH_CLINIC ='SEARCH_CLINIC';
export const FETCH_CATEGORY_CLINIC ='FETCH_CATEGORY_CLINIC';
export const FETCH_DATHEN_CLINIC ='FETCH_DATHEN_CLINIC';
export const FETCH_DATHEN_CLINIC_USER ='FETCH_DATHEN_CLINIC_USER';
export const FETCH_GET_CLINIC ='FETCH_GET_CLINIC';


