import * as Types from '../../constants/View/ActionTypes';
var initialState = [];
const dichvukythuats = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_DVKT:
           state = action.dichvukythuats;
            return [...state];

        default: return [...state];
    }
}
export default dichvukythuats;