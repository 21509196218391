
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class DSBNPhongKhamItem extends Component {

    render() {
        var { dsbnphongkham } = this.props;
        //var ngaysinh =  moment(new Date(dsbnphongkham.PatientBirthDay)).format('DD/MM/YYYY');
        var GioiTinhActive = dsbnphongkham.PatientGender === 0 ? 'Nữ' : 'Nam';

        return (
            <tr>
                <td>{dsbnphongkham.PatientName}</td>
                <td>{GioiTinhActive}</td>
                <td>{dsbnphongkham.PatientBirthDay}</td>
                <td>{dsbnphongkham.PatientMobile}</td>
                <td>{dsbnphongkham.PatientAddress}</td>
                <td>{dsbnphongkham.ClinicName}</td>
                <td>
                    <div className="btnCapNhatList">
                        <Link to={`/benh-nhan/pk_${dsbnphongkham.ClinicCode}/${dsbnphongkham.PatientCode}`} className=" btnXem btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Xem</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default DSBNPhongKhamItem;

