import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const thongtinuser = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_THONGTIN_USER:
           state = action.thongtinuser;
            return [...state];

        default: return [...state];
    }
}
export default thongtinuser;