
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { logoutUser, getProfileFetch } from './../../actions/AcLogin';
import { DatHenCliniCRequest } from './../../actions/Clinic/AcClinic';
import { TinhThanhRequest, QuanHuyenRequest, PhuongXaRequest, ChuyenKhoaRequest } from './../../actions/Location/AcLocation';
import { actDatHenDocTorsRequest } from './../../actions/Doctors/AcDoctors';
import { addDatHenRequest } from './../../actions/DatHen/AcDatHen';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ClinicCode: '',
            RowIDDoctor: '',
            ChuyenKhoa: '',
            DatetimeInto: '',
            TimeInto: '',
            ProvincialCode: '',
            DistrictCode: '',
            WardCode: '',
            Address: '',
            PatientCode: '',
            TrieuChung: ''

        }
        this.handleChangeDatetimeInto = this.handleChangeDatetimeInto.bind(this);
        this.handleChangeTimeInto = this.handleChangeTimeInto.bind(this);


    }

    componentDidMount() {

        this.props.getProfileFetch();
        this.props.DatHenClinic();
        this.props.DatHenDoctor();
        this.props.getTinhThanh();
        this.props.getQuanHuyen();
        this.props.getPhuongXa();
        this.props.getChuyenKhoa();


    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {

            /*if (prevProps.catedoctordathens !== this.props.catedoctordathens) {
                this.props.DatHenDoctor();
            }*/


        }
    }

    logout = event => {
        event.preventDefault()
        localStorage.removeItem("token")
        this.props.logoutUser();
        window.location.href = '/';

    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    onChangePK = (e) => {
        var target = e.target;
        var name = target.name;

        var value = target.type === 'checkbox' ? target.checked : target.value;
        localStorage.setItem('ClinicCode', value);
        this.setState({
            [name]: value
        })
    }
    onChangeTT = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            ProvincialCode: value
        })
    }
    onChangeQH = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }


    categoryTinhThanh() {
        if (this.props.tinhthanh) {
            return (
                this.props.tinhthanh.map((cate, index) => {
                    return (<option key={index} value={cate.ProvincialCode}  >{cate.ProvincialName}</option>);

                })
            )
        }
    }

    categoryClinic() {
        if (this.props.clinicscatedhs) {
            return (
                this.props.clinicscatedhs.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }

    }
    categoryDoctor() {
        if (this.props.catedoctordathens) {
            return (
                this.props.catedoctordathens.map((cate, index) => {
                    return (<option key={index} value={cate.DoctorName} >{cate.DoctorName}</option>);
                })
            )
        }
    }
    categoryChuyenKhoa() {
        if (this.props.chuyenkhoa) {
            return this.props.chuyenkhoa
                .filter(item => item.ClinicCode === this.state.ClinicCode)
                .map((item, idx) => <option key={idx} value={item.MapHis} >{item.ChuyenKhoaName}</option>);

        }


    }

    handleChangeDatetimeInto = DatetimeInto => {
        this.setState({
            DatetimeInto
        });
    };
    handleChangeTimeInto = TimeInto => {
        this.setState({
            TimeInto
        });
    }
    onThanhCong() {
        alert('Đặt lịch hẹn thành công!');
    }
    onSave = (e) => {
        var { ClinicCode, RowIDDoctor, ChuyenKhoa, DatetimeInto, TimeInto, ProvincialCode, WardCode, DistrictCode, Address, TrieuChung } = this.state;
        e.preventDefault();
        if (ClinicCode === '' || ChuyenKhoa === '' || TimeInto === '' || TrieuChung === '') {
            alert('Vui lòng nhập đầy đủ thông tin !');
        } else {
            var dathen = {
                RowID: moment(new Date()).format('YYYYMMDD') + Math.floor(Math.random() * 1000),
                ClinicCode: ClinicCode,
                DoctorName: RowIDDoctor,
                DatetimeInto: DatetimeInto ? moment(DatetimeInto).format('YYYYMMDD') : moment(new Date()).format('YYYYMMDD'),
                ChuyenKhoa: ChuyenKhoa,
                TimeInto: TimeInto ? moment(TimeInto).format('HH:mm') : moment(new Date()).format('HH:mm'),
                Mobile: this.props.payload.currentUser.Mobile,
                FullName: this.props.payload.currentUser.FullName,
                Sex: this.props.payload.currentUser.Sex,
                BirthDay: this.props.payload.currentUser.BirthDay,
                ProvincialCode: ProvincialCode === '' ? this.props.payload.currentUser.ProvincialCode : ProvincialCode,
                WardCode: WardCode === '' ? this.props.payload.currentUser.WardCode : WardCode,
                DistrictCode: DistrictCode === '' ? this.props.payload.currentUser.DistrictCode : DistrictCode,
                Address: Address === '' ? this.props.payload.currentUser.Address : Address,
                PatientCode: this.props.payload.currentUser.UserID,
                TrieuChung: TrieuChung

            }
            this.props.onAddDatHen(dathen);
        }
        // this.onThanhCong();
        //window.location.href = '/';


    }
    render() {
        const { ClinicCode, ChuyenKhoa, DatetimeInto, TimeInto, Address, TrieuChung } = this.state;
        /* var ListQuanHuyen = [];
         var ListPhuongXa = [];
         if (this.props.payload.currentUser.ProvincialCode !== '' && ProvincialCode === '') {
             ListQuanHuyen = this.props.quanhuyen
                 .filter(item => item.ProvincialCode === this.props.payload.currentUser.ProvincialCode)
                 .map((item, idx) => <option key={idx} value={item.DistrictCode} >{item.DistrictName}</option>);
         } else {
             ListQuanHuyen = this.props.quanhuyen
                 .filter(item => item.ProvincialCode === ProvincialCode)
                 .map((item, idx) => <option key={idx} value={item.DistrictCode} >{item.DistrictName}</option>);
         }
 
         if (this.props.payload.currentUser.DistrictCode !== '' && DistrictCode === '') {
             ListPhuongXa = this.props.phuongxa
                 .filter(item => item.DistrictCode === this.props.payload.currentUser.DistrictCode)
                 .map((item, idx) => <option key={idx} value={item.WardCode} >{item.WardName}</option>);
         } else {
             ListPhuongXa = this.props.phuongxa
                 .filter(item => item.DistrictCode === DistrictCode)
                 .map((item, idx) => <option key={idx} value={item.WardCode} >{item.WardName}</option>);
         }*/
        return (

            <nav className={` main-header navbar navbar-expand navbar-white navbar-light`}>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <span className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></span>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="" className="nav-link">Trang chủ</Link>
                    </li>
                   {/* <li className="nav-item d-none d-sm-inline-block">
                        <div className="nav-link titledathen" data-toggle="modal" data-target="#exampleModalLong">
                            <div className="titledathen_clo">
                                <i className="nav-icon fas fa-copy"></i>
                                Đặt hẹn
                            </div>

                        </div>
        </li>*/}
                </ul>
                <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Đặt hẹn</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={this.onSave} >
                                <div className="modal-body frmdathen">
                                    <div className="form-group">
                                        <label >Phòng khám:</label>
                                        <select value={ClinicCode} className="form-control conleft" onChange={this.onChangePK} name="ClinicCode" >
                                            <option value="">Chọn</option>
                                            {this.categoryClinic()}

                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                                        <label >Bác sĩ:</label>
                                        {ClinicCode === '' ?
                                            <select value="" className="form-control conleft" onChange={this.onChange} name="" >
                                                <option value="">Chọn</option>

                                            </select>
                                            :
                                            <select value={RowIDDoctor} className="form-control conleft" onChange={this.onChange} name="RowIDDoctor" >
                                                <option value="">Chọn</option>
                                                {this.categoryDoctor()}

                                            </select>
                                        }

                                    </div>*/}
                                    <div className="form-group">
                                        <label >Chuyên khoa:</label>
                                        <select value={ChuyenKhoa} className="form-control conleft" onChange={this.onChange} name="ChuyenKhoa" >
                                            <option value="">Chọn</option>
                                            {this.categoryChuyenKhoa()}

                                        </select>
                                    </div>
                                    {/*<div className="form-group">
                                        <label >Tỉnh thành:</label>
                                        <select value={ProvincialCode === '' ? `${this.props.payload.currentUser.ProvincialCode}` : ProvincialCode} className="form-control conleft" onChange={this.onChangeTT} name="ProvincialCode" >
                                            <option value="">Chọn</option>
                                            {this.categoryTinhThanh()}

                                        </select>
                                    </div>
                                    {this.props.payload.currentUser.ProvincialCode !== '' && ProvincialCode === '' ?
                                        <div className="form-group">
                                            <label >Quận / Huyện:</label>
                                            <select value={DistrictCode === '' ? `${this.props.payload.currentUser.DistrictCode}` : DistrictCode} className="form-control conleft" onChange={this.onChangeQH} name="DistrictCode" >
                                                <option value="">Chọn</option>
                                                {ListQuanHuyen}
                                            </select>
                                        </div>
                                        :
                                        <div className="form-group">
                                            <label >Quận / Huyện:</label>
                                            <select value={DistrictCode} className="form-control conleft" onChange={this.onChangeQH} name="DistrictCode" >
                                                <option value="">Chọn</option>
                                                {ListQuanHuyen}

                                            </select>
                                        </div>


                                    }

                                    {this.props.payload.currentUser.DistrictCode !== '' && DistrictCode === '' ?
                                        <div className="form-group">
                                            <label >Phường / Xã:</label>
                                            <select value={WardCode === '' ? `${this.props.payload.currentUser.WardCode}` : WardCode} className="form-control conleft" onChange={this.onChange} name="WardCode" >
                                                <option value="">Chọn</option>
                                                {ListPhuongXa}
                                            </select>


                                        </div>
                                        :
                                        <div className="form-group">
                                            <label >Phường / Xã:</label>
                                            <select value={WardCode} className="form-control conleft" onChange={this.onChange} name="WardCode" >
                                                <option value="">Chọn</option>
                                                {ListPhuongXa}

                                            </select>
                                        </div>


                                    }
                                */}



                                    <div className="form-group">
                                        <label >Địa chỉ:</label>
                                        <input className="form-control conleft" name='Address' value={Address === '' ? `${this.props.payload.currentUser.Address}` : Address} onChange={this.onChange} />

                                    </div>
                                    <div className="form-group">
                                        <label >Ngày</label>
                                        <DatePicker className="form-control" name="DatetimeInto" selected={DatetimeInto ? DatetimeInto : new Date()} onChange={this.handleChangeDatetimeInto}
                                            dateFormat="dd/MM/yyyy" />
                                    </div>
                                    <div className="form-group">
                                        <label >Thời gian</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={TimeInto}
                                            onChange={this.handleChangeTimeInto}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                        />

                                    </div>
                                    <div className="form-group">
                                        <label >Triệu chứng:</label>
                                        <textarea className="form-control conleft" name="TrieuChung" value={TrieuChung ? TrieuChung : ''} onChange={this.onChange} />

                                    </div>



                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Đóng</button>
                                    <button type="submit" className="btn btn-success">Đặt hẹn</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <ul className="navbar-nav ml-auto">
                    {/*
                    <li className="nav-item dropdown">
                        <Link to="" className="nav-link" data-toggle="dropdown">
                            <i className="far fa-comments"></i>
                            <span className="badge badge-danger navbar-badge">3</span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <Link to="" className="dropdown-item">

                                <div className="media">
                                    <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                  <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>

                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item">

                                <div className="media">
                                    <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            John Pierce
                  <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                                        </h3>
                                        <p className="text-sm">I got your message bro</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>

                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item">

                                <div className="media">
                                    <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Nora Silvester
                  <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                                        </h3>
                                        <p className="text-sm">The subject goes here</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>

                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item dropdown-footer">See All Messages</Link>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <Link to="" className="nav-link" data-toggle="dropdown" >
                            <i className="far fa-bell"></i>
                            <span className="badge badge-warning navbar-badge">15</span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"></i> 4 new messages
            <span className="float-right text-muted text-sm">3 mins</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item">
                                <i className="fas fa-users mr-2"></i> 8 friend requests
            <span className="float-right text-muted text-sm">12 hours</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item">
                                <i className="fas fa-file mr-2"></i> 3 new reports
            <span className="float-right text-muted text-sm">2 days</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="" className="dropdown-item dropdown-footer">See All Notifications</Link>
                        </div>
                    </li>
                    */}
                    <li className="nav-item">
                        <div className='UserHeader'>User: {this.props.payload.currentUser.FullName} </div>
                    </li>
                    <li className="nav-item">
                        <div className='btnLogout'>
                            <i class="fa fa-window-close" aria-hidden="true"></i>
                            <button className="logoutBtn" onClick={this.logout}>Thoát</button>
                        </div>
                    </li>



                </ul>
            </nav>

        );
    }
}
const mapStateToProps = state => {
    return {
        payload: state.payload,
        clinicscatedhs: state.clinicscatedhs,
        catedoctordathens: state.catedoctordathens,
        tinhthanh: state.tinhthanh,
        quanhuyen: state.quanhuyen,
        phuongxa: state.phuongxa,
        chuyenkhoa: state.chuyenkhoa

    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
    getProfileFetch: () => {

        dispatch(getProfileFetch());
    },
    DatHenClinic: () => {
        dispatch(DatHenCliniCRequest());
    },
    DatHenDoctor: () => {
        dispatch(actDatHenDocTorsRequest());
    },
    onAddDatHen: (dathens) => {
        dispatch(addDatHenRequest(dathens));
    },

    getTinhThanh: () => {
        dispatch(TinhThanhRequest());
    },
    getQuanHuyen: () => {
        dispatch(QuanHuyenRequest());
    },
    getPhuongXa: () => {
        dispatch(PhuongXaRequest());
    },
    getChuyenKhoa: () => {
        dispatch(ChuyenKhoaRequest());
    },


})
export default connect(mapStateToProps, mapDispatchToProps)(Header);
