
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ServiceRadiologyEntryItem extends Component {
 
    onDelete = (id) => {
        
        this.props.onDelete(id);
    }

    render() {
        var { serviceradio } = this.props;

        return (
            <tr>
                <td>{serviceradio.SuggestedID}</td>
                <td>{serviceradio.PatientReceiveID}</td>
                <td>{serviceradio.ClinicCode}</td>
                <td>{serviceradio.PatientCode}</td>
                <td>{serviceradio.EmployeeDoctorOrder_Code}</td>
                <td>{serviceradio.EmployeeDoctorOrder_Name}</td>
                <td>{serviceradio.Contents}</td>
                <td>{serviceradio.Conclusion}</td>
                <td>{serviceradio.Proposal}</td>
                <td>{serviceradio.PostingDate}</td>
                <td>{serviceradio.EmployeeDoctorDone_Code}</td>
                <td>{serviceradio.EmployeeDoctorDone_Name}</td>
                <td>{serviceradio.DiagnosisCustom}</td>
                <td>{serviceradio.Note}</td>
                <td>{serviceradio.ServiceName}</td>
                <td>{serviceradio.ServiceCode}</td>
                <td>{serviceradio.ServiceModuleCode}</td>
                <td>{serviceradio.ServiceMunuID}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(serviceradio.SuggestedID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/service-radiology-entry/${serviceradio.SuggestedID}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default ServiceRadiologyEntryItem;

