import * as Types from '../../constants/View/ActionTypes';
var initialState = [];
/*
var findIndex = (lichsukhambenhs, id) => {
    var result = -1;
    lichsukhambenhs.forEach((lichsukhambenh, index) => {
        if (lichsukhambenh.id === id) {
            result = index;
        }
    })
    return result;
}
*/
const lichsukhambenhs = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_VIEW_LSKB:
           state = action.lichsukhambenhs;
            return [...state];

        default: return [...state];
    }
}
export default lichsukhambenhs;