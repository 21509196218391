import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { addMedicRecordRequest, eidtMedicRecordRequest, updateMedicRecordRequest } from '../../actions/MedicalRecord/AcMedicalRecord';

class MedicRecordActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            PatientReceiveID: '',
            ClinicCode: '',
            PatientCode: '',
            MedicalRecordCode: '',
            Reason_KB: '',
            DiagnosisCustom: '',
            ICD10_ICD10KT_Ma: '',
            ICD10_ICD10KT_Ten: '',
            DepartmentName: '',
            EmployeeDoctor_Code: '',
            EmployeeDoctor_Name: '',
            PostingDate: '',
            AppointmentDate: '',
            AppointmentNote: '',
            ObjectCode: '',
            Treatments: '',
            TackleName: '',
            TinhTrangRaVien: '',
            Advices_Note: '',
            OffDay: '',
            OffDay_From: '',
            OffDay_To: '',
            SuggestedID: '',
            Pulse: '',
            Temperature: '',
            BloodPressure: '',
            BloodPressure1: '',
            Weight: '',
            Hight: '',
            Breath: '',
            BMI: '',
            VongNguc: '',
            SPO2: ''

        }
        this.handleChangePostingDate = this.handleChangePostingDate.bind(this);
        this.handleChangeAppointmentDate = this.handleChangeAppointmentDate.bind(this);
        this.handleChangeOffDay_From = this.handleChangeOffDay_From.bind(this);
        this.handleChangeOffDay_To = this.handleChangeOffDay_To.bind(this);

    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditMedicRecord(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingMedicRecord !== this.props.EditingMedicRecord) {

                this.props.EditingMedicRecord.map((menuItem) => (
                    this.setState({
                        id: menuItem.MedicalRecordCode,
                        Reason_KB: menuItem.Reason_KB,
                        PatientReceiveID: menuItem.PatientReceiveID,
                        ClinicCode: menuItem.ClinicCode,
                        PatientCode: menuItem.PatientCode,
                        DiagnosisCustom: menuItem.DiagnosisCustom,
                        ICD10_ICD10KT_Ma: menuItem.ICD10_ICD10KT_Ma,
                        ICD10_ICD10KT_Ten: menuItem.ICD10_ICD10KT_Ten,
                        DepartmentName: menuItem.DepartmentName,
                        EmployeeDoctor_Code: menuItem.EmployeeDoctor_Code,
                        EmployeeDoctor_Name: menuItem.EmployeeDoctor_Name,
                        PostingDate: new Date(Date.parse(moment(menuItem.datePostingDate).format('YYYY-MM-DD') + ' ' + menuItem.timePostingDate.substring(11, 19))),
                        AppointmentDate: new Date(Date.parse(moment(menuItem.AppDate).format('YYYY-MM-DD') )),
                        AppointmentNote: menuItem.AppointmentNote,
                        ObjectCode: menuItem.ObjectCode,
                        Treatments: menuItem.Treatments,
                        TackleName: menuItem.TackleName,
                        TinhTrangRaVien: menuItem.TinhTrangRaVien,
                        Advices_Note: menuItem.Advices_Note,
                        OffDay: menuItem.OffDay,
                        OffDay_From: new Date(Date.parse(menuItem.OffDay_From)),
                        OffDay_To: new Date(Date.parse(menuItem.OffDay_To)),
                        SuggestedID: menuItem.SuggestedID,
                        Pulse: menuItem.Pulse,
                        Temperature: menuItem.Temperature,
                        BloodPressure: menuItem.BloodPressure,
                        BloodPressure1: menuItem.BloodPressure1,
                        Weight: menuItem.Weight,
                        Hight: menuItem.Hight,
                        Breath: menuItem.Breath,
                        BMI: menuItem.BMI,
                        VongNguc: menuItem.VongNguc,
                        SPO2: menuItem.SPO2


                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, PatientReceiveID, ClinicCode, PatientCode, MedicalRecordCode, Reason_KB, DiagnosisCustom,
            ICD10_ICD10KT_Ma, ICD10_ICD10KT_Ten, DepartmentName, EmployeeDoctor_Code, EmployeeDoctor_Name,
            PostingDate, AppointmentDate, AppointmentNote, ObjectCode, Treatments, TackleName, TinhTrangRaVien,
            Advices_Note, OffDay, OffDay_From, OffDay_To, SuggestedID, Pulse, Temperature, BloodPressure,
            BloodPressure1, Weight, Hight, Breath, BMI, VongNguc, SPO2 } = this.state;
       // var { history } = this.props;

        const PostingDateID = moment(PostingDate).format('YYYYMMDDHHmmss');
        const AppointmentDateID = moment(AppointmentDate).format('YYYYMMDD');
        const OffDay_FromID = moment(OffDay_From).format('YYYY-MM-DD');
        const OffDay_ToID = moment(OffDay_To).format('YYYY-MM-DD');

        e.preventDefault();

        if (id) {
            var MedicRecordS = {
                MedicalRecordCode: id,
                Reason_KB: Reason_KB,
                DiagnosisCustom: DiagnosisCustom,
                ICD10_ICD10KT_Ma: ICD10_ICD10KT_Ma,
                ICD10_ICD10KT_Ten: ICD10_ICD10KT_Ten,
                DepartmentName: DepartmentName,
                EmployeeDoctor_Code: EmployeeDoctor_Code,
                EmployeeDoctor_Name: EmployeeDoctor_Name,
                PostingDate: PostingDateID,
                AppointmentDate: AppointmentDateID,
                AppointmentNote: AppointmentNote,
                ObjectCode: ObjectCode,
                Treatments: Treatments,
                TackleName: TackleName,
                TinhTrangRaVien: TinhTrangRaVien,
                Advices_Note: Advices_Note,
                OffDay: OffDay,
                OffDay_From: OffDay_FromID,
                OffDay_To: OffDay_ToID,
                SuggestedID: SuggestedID,
                Pulse: Pulse,
                Temperature: Temperature,
                BloodPressure: BloodPressure,
                BloodPressure1: BloodPressure1,
                Weight: Weight,
                Hight: Hight,
                Breath: Breath,
                BMI: BMI,
                VongNguc: VongNguc,
                SPO2: SPO2
            }
            this.props.onUpdateMedicRecord(MedicRecordS);
           // history.goBack(window.location.reload(true));

        } else {
            var MedicRecord = {
                PatientReceiveID: PatientReceiveID,
                ClinicCode: ClinicCode,
                PatientCode: PatientCode,
                MedicalRecordCode: MedicalRecordCode,
                Reason_KB: Reason_KB,
                DiagnosisCustom: DiagnosisCustom,
                ICD10_ICD10KT_Ma: ICD10_ICD10KT_Ma,
                ICD10_ICD10KT_Ten: ICD10_ICD10KT_Ten,
                DepartmentName: DepartmentName,
                EmployeeDoctor_Code: EmployeeDoctor_Code,
                EmployeeDoctor_Name: EmployeeDoctor_Name,
                PostingDate: PostingDate ? moment(PostingDate).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                AppointmentDate: AppointmentDate ? moment(AppointmentDate).format('YYYYMMDD') : moment(new Date()).format('YYYYMMDD'),
                AppointmentNote: AppointmentNote,
                ObjectCode: ObjectCode,
                Treatments: Treatments,
                TackleName: TackleName,
                TinhTrangRaVien: TinhTrangRaVien,
                Advices_Note: Advices_Note,
                OffDay: OffDay,
                OffDay_From: OffDay_From ? moment(OffDay_From).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
                OffDay_To: OffDay_To ? moment(OffDay_To).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
                SuggestedID: SuggestedID,
                Pulse: Pulse,
                Temperature: Temperature,
                BloodPressure: BloodPressure,
                BloodPressure1: BloodPressure1,
                Weight: Weight,
                Hight: Hight,
                Breath: Breath,
                BMI: BMI,
                VongNguc: VongNguc,
                SPO2: SPO2
            }
            this.props.onAddMedicRecord(MedicRecord);
           // history.goBack(window.location.reload(true));
        }
        window.location.href = '/medical-record';

    }
    handleChangePostingDate = PostingDate => {
        this.setState({
            PostingDate
        });
    };
    handleChangeAppointmentDate = AppointmentDate => {
        this.setState({
            AppointmentDate
        });
    };
    handleChangeOffDay_From = OffDay_From => {
        this.setState({
            OffDay_From
        });
    };
    handleChangeOffDay_To = OffDay_To => {
        this.setState({
            OffDay_To
        });
    };

    render() {
        var { PatientReceiveID, ClinicCode, PatientCode, MedicalRecordCode, Reason_KB, DiagnosisCustom,
            ICD10_ICD10KT_Ma, ICD10_ICD10KT_Ten, DepartmentName, EmployeeDoctor_Code, EmployeeDoctor_Name,
            PostingDate, AppointmentDate, AppointmentNote, ObjectCode, Treatments, TackleName, TinhTrangRaVien,
            Advices_Note, OffDay, OffDay_From, OffDay_To, SuggestedID, Pulse, Temperature, BloodPressure,
            BloodPressure1, Weight, Hight, Breath, BMI, VongNguc, SPO2 } = this.state;


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Lịch sử bệnh nhân</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã TN BN</label>
                                                <input type="text"
                                                    name="PatientReceiveID"
                                                    className="form-control"
                                                    value={PatientReceiveID}
                                                    onChange={this.onChange}
                                                />

                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã PK</label>
                                                <input type="text"
                                                    name="ClinicCode"
                                                    className="form-control"
                                                    value={ClinicCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BN</label>
                                                <input type="text"
                                                    name="PatientCode"
                                                    className="form-control"
                                                    value={PatientCode}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã LS</label>
                                                <input type="text"
                                                    name="MedicalRecordCode"
                                                    className="form-control"
                                                    value={MedicalRecordCode}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Lý do</label>
                                                <input type="text"
                                                    name="Reason_KB"
                                                    className="form-control"
                                                    value={Reason_KB}
                                                    onChange={this.onChange}
                                                />

                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Chuẩn đoán BĐ</label>
                                                <input type="text"
                                                    name="DiagnosisCustom"
                                                    className="form-control"
                                                    value={DiagnosisCustom}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã ICD10</label>
                                                <input type="text"
                                                    name="ICD10_ICD10KT_Ma"
                                                    className="form-control"
                                                    value={ICD10_ICD10KT_Ma}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên ICD10</label>
                                                <input type="text"
                                                    name="ICD10_ICD10KT_Ten"
                                                    className="form-control"
                                                    value={ICD10_ICD10KT_Ten}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên bộ phận</label>
                                                <input type="text"
                                                    name="DepartmentName"
                                                    className="form-control"
                                                    value={DepartmentName}
                                                    onChange={this.onChange}
                                                />

                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã Bs NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctor_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctor_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctor_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctor_Name}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã đối tượng</label>
                                                <input type="text"
                                                    name="ObjectCode"
                                                    className="form-control"
                                                    value={ObjectCode}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày thực hiện</label>
                                                <DatePicker name="PostingDate" value={PostingDate ? PostingDate : new Date()} onChange={this.handleChangePostingDate}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />

                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày tái khám</label>
                                                <DatePicker name="AppointmentDate" value={AppointmentDate ? AppointmentDate : new Date()} onChange={this.handleChangeAppointmentDate}
                                                    dateFormat="DD/MM/yyyy" timeFormat="" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Kết quả điều trị</label>
                                                <input type="text"
                                                    name="Treatments"
                                                    className="form-control"
                                                    value={Treatments}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã đề xuất</label>
                                                <input type="text"
                                                    name="SuggestedID"
                                                    className="form-control"
                                                    value={SuggestedID}
                                                    onChange={this.onChange}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Xử trí khám bệnh</label>
                                                <input type="text"
                                                    name="TackleName"
                                                    className="form-control"
                                                    value={TackleName}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Tình trạng ra viện</label>
                                                <input type="text"
                                                    name="TinhTrangRaVien"
                                                    className="form-control"
                                                    value={TinhTrangRaVien}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Lời dặn</label>
                                                <input type="text"
                                                    name="Advices_Note"
                                                    className="form-control"
                                                    value={Advices_Note}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Ghi chú tái khám</label>
                                                <input type="text"
                                                    name="AppointmentNote"
                                                    className="form-control"
                                                    value={AppointmentNote}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Số ngày</label>
                                                <input type="text"
                                                    name="OffDay"
                                                    className="form-control"
                                                    value={OffDay}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày bắt đầu</label>
                                                <DatePicker name="OffDay_From" value={OffDay_From ? OffDay_From : new Date()} onChange={this.handleChangeOffDay_From}
                                                    dateFormat="DD/MM/yyyy" timeFormat="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày kết thúc</label>
                                                <DatePicker name="OffDay_To" value={OffDay_To ? OffDay_To : new Date()} onChange={this.handleChangeOffDay_To}
                                                    dateFormat="DD/MM/yyyy" timeFormat="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Mạch</label>
                                                <input type="text"
                                                    name="Pulse"
                                                    className="form-control"
                                                    value={Pulse}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Nhiệt độ</label>
                                                <input type="text"
                                                    name="Temperature"
                                                    className="form-control"
                                                    value={Temperature}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Huyết áp</label>
                                                <input type="text"
                                                    name="BloodPressure"
                                                    className="form-control"
                                                    value={BloodPressure}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Huyết áp</label>
                                                <input type="text"
                                                    name="BloodPressure1"
                                                    className="form-control"
                                                    value={BloodPressure1}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Cân nặng</label>
                                                <input type="text"
                                                    name="Weight"
                                                    className="form-control"
                                                    value={Weight}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Chiều cao</label>
                                                <input type="text"
                                                    name="Hight"
                                                    className="form-control"
                                                    value={Hight}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Hơi thở</label>
                                                <input type="text"
                                                    name="Breath"
                                                    className="form-control"
                                                    value={Breath}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >BMI</label>
                                                <input type="text"
                                                    name="BMI"
                                                    className="form-control"
                                                    value={BMI}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Vòng ngực</label>
                                                <input type="text"
                                                    name="VongNguc"
                                                    className="form-control"
                                                    value={VongNguc}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >SPO2</label>
                                                <input type="text"
                                                    name="SPO2"
                                                    className="form-control"
                                                    value={SPO2}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        
                                    </div>


                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/medical-record" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        EditingMedicRecord: state.EditingMedicRecord
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddMedicRecord: (medicrecords) => {
            dispatch(addMedicRecordRequest(medicrecords));
        },
        onEditMedicRecord: (id) => {
            dispatch(eidtMedicRecordRequest(id));
        },
        onUpdateMedicRecord: (medicrecords) => {
            dispatch(updateMedicRecordRequest(medicrecords));
        },


    }

}
export default connect(mapStateToProps, mapDispatchToProps)(MedicRecordActionPage);