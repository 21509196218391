import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { clone } from "lodash";
import Select from 'react-select';
import { addCKhoaRequest, eidtCKhoaRequest, updateCKhoaRequest } from '../../actions/ChuyenKhoa/AcChuyenKhoa';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
function isIncludingString(string, option) {
    let result = false;
    if (
        !string ||
        option.label.toString().includes(string) ||
        option.value.toString().includes(string)
    ) {
        result = true;
    }
    return result;
}
class ChuyenKhoaActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            ChuyenKhoaName: '',
            MapHis:'',
            value: '',
            errorMessage: '',
            isClearable: true,
            isSearchable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,

            searchField: null,
            ClinicCodeArry: [],
            selectedOption: ''

        }


    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditChuyenKhoa(id);

        }
        this.props.fetchAllCategoryClinic();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditChuyenKhoa !== this.props.EditChuyenKhoa) {

                this.props.EditChuyenKhoa.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        ChuyenKhoaName: menuItem.ChuyenKhoaName,
                        MapHis:menuItem.MapHis,
                        ClinicCode: menuItem.ClinicCode,
                        ClinicCodeArry: { value: menuItem.ClinicCode, label: menuItem.ClinicName },
                    })
                ))
            }
        }
    }
    handleChange = (opt, { option }) => {
        let newOpts = opt;
        let string = this.state.searchField;

        if (option && option.value === "all") {
            let filteredOptions = clone(this.props.clinics);

            filteredOptions = filteredOptions.filter(
                filteredOption =>
                    isIncludingString(string, filteredOption) &&
                    !newOpts.includes(filteredOption)
            );

            string = null;
            newOpts = newOpts
                .concat(filteredOptions)
                .filter(newOpt => newOpt.value !== "all");
        }
        this.setState({
            searchField: string,
            ClinicCodeArry: newOpts
        });
    };
    onInputChange = (string, { action }) => {
        if (action === "input-change") {
            this.setState({
                searchField: string
            });
        }
    };
    filterOption = ({ label, value }, string) => {

        if (value === "all") {
            return true;
        } else if (string) {
            return label.toLocaleLowerCase().includes(string) || value.toString().includes(string);
        } else {
            return true;
        }
    };

    onSave = (e) => {
        var { id, ChuyenKhoaName, MapHis } = this.state;
        e.preventDefault();
        if (id) {
            var listchuyenkhoa = {
                RowID: id,
                ChuyenKhoaName: ChuyenKhoaName,
                MapHis:MapHis,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onUpdateChuyenKhoa(listchuyenkhoa);
            

        } else {

            var arraychuyenkhoa = {
                RowID: Math.floor(Math.random() * 1000),
                ChuyenKhoaName: ChuyenKhoaName,
                MapHis:MapHis,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onAddChuyenKhoa(arraychuyenkhoa);
            




        }

    }
  
    render() {
        var { ChuyenKhoaName, MapHis } = this.state;
        console.log(this.state.ClinicCodeArry)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Chuyên Khoa</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label >Tên chuyên khoa</label>
                                                <input type="text"
                                                    name="ChuyenKhoaName"
                                                    className="form-control"
                                                    value={ChuyenKhoaName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label >Mã chuyên khoa</label>
                                                <input type="text"
                                                    name="MapHis"
                                                    className="form-control"
                                                    value={MapHis}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <Select
                                                    filterOption={this.filterOption}
                                                    onInputChange={this.onInputChange}
                                                    onChange={this.handleChange}
                                                    options={this.props.clinics}
                                                    value={this.state.ClinicCodeArry}

                                                    isDisabled={this.state.isDisabled}
                                                    isLoading={this.state.isLoading}
                                                    isClearable={this.state.isClearable}
                                                    isRtl={this.state.isRtl}
                                                    isSearchable={this.state.isSearchable}

                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="btnAdd">
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/chuyen-khoa" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        EditChuyenKhoa: state.EditChuyenKhoa,
        clinics: state.clinics,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddChuyenKhoa: (chuyenkhoas) => {
            dispatch(addCKhoaRequest(chuyenkhoas));
        },
        onEditChuyenKhoa: (id) => {
            dispatch(eidtCKhoaRequest(id));
        },
        onUpdateChuyenKhoa: (chuyenkhoas) => {
            dispatch(updateCKhoaRequest(chuyenkhoas));
        },
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(ChuyenKhoaActionPage);