import * as Types from '../../constants/TraLoi/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';


export const actFetchTraLoiRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('tra-loi','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchTraLoi(res.data))
                }
            }
        })
    }
}

export const actFetchTraLoi = (tralois) => {
    return {
        type: Types.FETCH_TRALOI,
        tralois
    }
}

export const deleteTraLoiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-tra-loi/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteTraLoi(id));
        })
    }
}
export const deleteTraLoi = (id) => {
    return {
        type: Types.DELETE_TRALOI,
        id
    }
}

export const eidtTraLoiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`tra-loi/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editTraLoi(res.data));
            }

        })
    }
}


export const editTraLoi = (tralois) => {
    return {
        type: Types.EDIT_TRALOI,
        tralois
    }
}
export const updateTraLoiRequest = (tralois) => {
    return (dispatch) => {
        return UrlApi(`update-tra-loi/${tralois.id}`, 'PUT', tralois, authHeader()).then(res => {
            dispatch(updateTraLoi(res.data));

        })
    }
}
export const updateTraLoi = (tralois) => {
    return {
        type: Types.UPDATE_TRALOI,
        tralois
    }
}
export const updateisLockedTraLoiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-tra-loi/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedTraLoi(id));

        })
    }
}
export const updateisLockedTraLoi = (id) => {
    return {
        type: Types.UPDATE_STATUS_TRALOI,
        id
    }
}