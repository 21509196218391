import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { addDocImageRequest, eidtDocImageRequest, updateDocImageRequest } from '../../actions/ReceiveDocumentImage/AcDocImage';

const url = 'http://115.73.219.199:8090/uploads/';

class DocImageActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            PatientReceiveID: '',
            ClinicCode: '',
            PatientCode: '',
            Note: '',
            ImageUrl: '',
            STT: '',
            file: '',
        }
        this.onChangeImage = this.onChangeImage.bind(this);
    }
    onChangeImage(e) {
        
        this.setState({file:e.target.files});
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditDocImage(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingDocImage !== this.props.EditingDocImage) {
                this.props.EditingDocImage.map((menuItem) => (
                    this.setState({
                        id: menuItem.ClinicCode,
                        PatientReceiveID: menuItem.PatientReceiveID,
                        ClinicCode: menuItem.ClinicCode,
                        PatientCode: menuItem.PatientCode,
                        Note: menuItem.Note,
                        ImageUrl: menuItem.ImageUrl,
                        STT: menuItem.STT
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, PatientReceiveID, PatientCode, Note, STT } = this.state;
       // var { history } = this.props;
        e.preventDefault();

        if (id) {
            var docimages = {
                PatientReceiveID: PatientReceiveID,
                ClinicCode: id,
                PatientCode: PatientCode,
                Note: Note,
                STT: STT
            }
            this.props.onUpdateDocImage(docimages);
            if(this.state.file){
                const formData = new FormData();
               
                for (const key of Object.keys(this.state.file)) {
                    formData.append('ImageUrl', this.state.file[key])
                }
                formData.append('PatientReceiveID',PatientReceiveID);
    
                let token = JSON.parse(localStorage.getItem('token'));
                const config = {
                    headers: { 'content-type': 'multipart/form-data', 'x-access-token': `${token}` }
                   };
                   axios.post("http://192.168.88.244:8090/apitest/upload-document-image",formData, config )
                    .then((response) => {
                       
                    }).catch((error) => {
                });
            }

        } else {
           /*var docimage = {
                PatientReceiveID: PatientReceiveID,
                ClinicCode: ClinicCode,
                PatientCode: PatientCode,
                Note: Note,
                STT: STT
            }
            this.props.onAddDocImage(docimage);
			*/
            if(this.state.file){
					
                const formData = new FormData();
                 for (const key of Object.keys(this.state.file)) {
                    formData.append('ImageUrl', this.state.file[key])
                }
                formData.append('PatientReceiveID',PatientReceiveID);
                let token = JSON.parse(localStorage.getItem('token'));
				console.log('dfgdfgfdgdgd',formData)
				
                const config = {
                    headers: { 'content-type': 'multipart/form-data', 'x-access-token': `${token}` }
                   };
                   axios.post("http://192.168.88.244:8090/apitest/upload-document-image",formData, config )
                    .then((response) => {
                       console.log(response)
                    }).catch((error) => {
                });
            }

           // history.goBack(window.location.reload(true));
        }
        //window.location.href = '/document-image';
    }
    render() {
        var { PatientReceiveID, ClinicCode, PatientCode, Note, ImageUrl, STT } = this.state;
		
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Hình ảnh</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã TN</label>
                                                <input type="text"
                                                    name="PatientReceiveID"
                                                    className="form-control"
                                                    value={PatientReceiveID}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã PK</label>
                                                <input type="text"
                                                    name="ClinicCode"
                                                    className="form-control"
                                                    value={ClinicCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BN</label>
                                                <input type="text"
                                                    name="PatientCode"
                                                    className="form-control"
                                                    value={PatientCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >STT</label>
                                                <input type="text"
                                                    name="STT"
                                                    className="form-control"
                                                    value={STT}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Ghi chú</label>
                                                <textarea type="text"
                                                    name="Note"
                                                    className="form-control"
                                                    value={Note}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label >Hình ảnh</label>
                                        <div className="custom-file">
                                            <input multiple  type="file" className="custom-file-input" name="ImageUrl" id="exampleInputFile" onChange={this.onChangeImage} />
                                            <label className="custom-file-label" >Choose file</label>
                                        </div>
                                        {this.state.id ? <img src={`${url}${ImageUrl}`} className="ImgUrlUser" alt="User" />
                                            : null}


                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/document-image" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        EditingDocImage: state.EditingDocImage
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddDocImage: (docimages) => {
            dispatch(addDocImageRequest(docimages));
        },
        onEditDocImage: (id) => {
            dispatch(eidtDocImageRequest(id));
        },
        onUpdateDocImage: (docimages) => {
            dispatch(updateDocImageRequest(docimages));
        },


    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DocImageActionPage);