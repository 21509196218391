
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class AboutsItem extends Component {
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }

    render() {
        var { about } = this.props;
        var statusname = about.isLocked === true ? 'Hiện' : 'Ẩn';
        var statusclass = about.isLocked === true ? 'btn-success' : 'btn-warning';
        return (
            <tr>
                <td>Giới thiệu</td>
                <td >
                    <span className={`labelIs btn btn-block  btn-xs ${statusclass}`} onClick={() => this.onUpdateStatus(about.RowID)} >
                        {statusname}
                    </span>
                </td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(about.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/abouts/${about.RowID}/edit`}  className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default AboutsItem;

