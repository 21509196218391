
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
class PatientsItem extends Component {
    componentDidMount(){
        this.props.fetchAllCategoryClinic();
    }

    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
        }
    }

    render() {
        var { patient } = this.props;
       /* var cate  = this.props.clinics
        .filter(commentReply => commentReply.ClinicCode === patient.ClinicCode)
        .map((commentReply, idx) => <span key={idx}>{commentReply.ClinicName}</span>);
        */
        var ngaysinh =  moment(new Date(patient.PatientBirthDay)).format('DD/MM/YYYY');
        return (
            <tr>
                 <td>{patient.PatientName}</td>
                <td>{patient.ClinicCode}</td>
                <td>{patient.PatientCode}</td>
                <td>{patient.EThnicName}</td>
                <td>{patient.CareerName}</td>
                <td>{patient.NationalityName}</td>
                <td>{patient.ProvincialCode}</td>
                <td>{patient.DistrictCode}</td>
                <td>{patient.WardCode}</td>
                <td>{patient.PatientAddress}</td>
                <td>{patient.PatientGender}</td>
                <td>{ngaysinh}</td>
                <td>{patient.PatientAge}</td>
                <td>{patient.PatientMobile}</td>
                <td>{patient.PatientEmail}</td>
                <td>{patient.MedicalHistory}</td>
                <td>{patient.Allergy}</td>
               

             
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(patient.PatientCode)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/patients/${patient.PatientCode}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
const mapStateToProps = state => {
    return {
        clinics: state.clinics
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
       
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },
      
       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientsItem);

