import * as Types from '../../constants/Clinic/ActionTypes';
var initialState = [];

var findIndex = (clinics, id) => {
    var result = -1;
    clinics.forEach((clinic, index) => {
        if (clinic.id === id) {
            result = index;
        }
    })
    return result;
}
const clinics = (state = initialState, action) => {
    var index = -1;
    var { id, clinics } = action;
    switch (action.type) {
        case Types.FETCH_CLINIC:
            state = action.clinics;
            return [...state];
        case Types.DELETE_CLINIC:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_CLINIC:
            state.push(action.clinics);
            return [...state];

        case Types.UPDATE_CLINIC:
            index = findIndex(state, clinics.id);
            state[index] = clinics;
            return [...state];
        case Types.UPDATE_STATUS_CLINIC:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
        case Types.FETCH_CATEGORY_CLINIC:
            state = action.clinics;
            return [...state];

         case Types.FETCH_GET_CLINIC:
            state = action.clinics;
             return state;
        
       

        default: return state;
    }
}
export default clinics;