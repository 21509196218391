import * as Types from '../../constants/Clinic/ActionTypes';
var initialState = [];
const clinicscatedhs = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_DATHEN_CLINIC:
            state = action.clinicscatedhs;
            return [...state];

        default: return [...state];
    }
}
export default clinicscatedhs;