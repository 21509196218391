export const FETCH_DATHEN ='FETCH_DATHEN';
export const ADD_DATHEN ='ADD_DATHEN';
export const UPDATE_DATHEN ='UPDATE_DATHEN';
export const DELETE_DATHEN ='DELETE_DATHEN';
export const EDIT_DATHEN ='EDIT_DATHEN';
export const UPDATE_STATUS_DATHEN ='UPDATE_STATUS_DATHEN';
export const SEARCH_KEYWORD_DATHEN ='SEARCH_KEYWORD_DATHEN';
export const SEARCH_CATE_DATHEN ='SEARCH_CATE_DATHEN';
export const FETCH_SEARCH_CATE_DATHEN ='FETCH_SEARCH_CATE_DATHEN';




