import React, { Component } from 'react';
class NotFoundPage extends Component {
    render() {
        return (
            <div className="container">
                <div className="notfound">
                 <img src="http://localhost:3000/dist/img/404notfound.png" alt="User Avatar" className="imgnotfound" />
                 </div>
            </div>
        );
    }
}
export default NotFoundPage;