import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { clone } from "lodash";
import { AsyncPaginate } from 'react-select-async-paginate';
import { addMapUserClinicRequest, eidtMapUserClinicRequest, updateMapUserClinicRequest } from '../../actions/MapUserClinic/AcMapUserClinic';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
import { actCategoryUserRequest } from '../../actions/User/AcUser';

import * as Url from '../../constants/Config';

function isIncludingString(string, option) {
    let result = false;
    if (
        !string ||
        option.label.toString().includes(string) ||
        option.value.toString().includes(string)
    ) {
        result = true;
    }
    return result;
}
async function loadOptions(search, loadedOptions, { page }) {
    if (search === '') {
        const response = await fetch(`${Url.API_URL}/category-clinic/search=''&page=${page}`);
        const responseJSON = await response.json();
        return {
            options: responseJSON.results,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        };


    } else {
        const response = await fetch(`${Url.API_URL}/category-clinic/search=${search}&page=${page}`);
        const responseJSON = await response.json();
        return {
            options: responseJSON.results,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        };
    }

}

class MapUserClinicActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            UserName: '',
            Mobile: '',
            UserID: '',
            ClinicCode: '',
            // selectedOption:[],
            isClearable: true,
            isSearchable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,

            searchField: null,
            ClinicCodeArry: [],
            UserArry: [],

            selectedOption: ''
        }
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditMap(id);
        }
        this.props.fetchAllCategoryClinic();
        this.props.fetchAllCategoryUser();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingMapUserClinic !== this.props.EditingMapUserClinic) {

                this.props.EditingMapUserClinic.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,

                        ClinicCode: menuItem.ClinicCode,
                        ClinicCodeArry: { value: menuItem.ClinicCode, label: menuItem.ClinicName },
                        UserArry: { value: menuItem.UserID, label: menuItem.FullName, UserName: menuItem.UserName, Mobile: menuItem.Mobile, UserID: menuItem.UserID }

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id } = this.state;
        e.preventDefault();

        if (id) {
            var Maps = {
                RowID: id,
                UserName: this.state.UserArry.UserName,
                Mobile: this.state.UserArry.Mobile,
                UserID: this.state.UserArry.UserID,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onUpdateMap(Maps);

        } else {
            var MapsUC = {
                RowID: Math.floor(Math.random() * 10000000000),
                UserName: this.state.UserArry.UserName,
                Mobile: this.state.UserArry.Mobile,
                UserID: this.state.UserArry.UserID,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onAddMap(MapsUC);
            // history.goBack(window.location.reload(true));
        }
       

    }
    categoryUser() {
        if (this.props.users) {
            return (
                this.props.users.map((cate, index) => {
                    return (<option key={index} value={cate.UserID} >{cate.FullName}</option>);
                })
            )
        }
    }
    handleChange = (opt, { option }) => {
        let newOpts = opt;
        let string = this.state.searchField;

        if (option && option.value === "all") {
            let filteredOptions = clone(this.props.clinics);

            filteredOptions = filteredOptions.filter(
                filteredOption =>
                    isIncludingString(string, filteredOption) &&
                    !newOpts.includes(filteredOption)
            );

            string = null;
            newOpts = newOpts
                .concat(filteredOptions)
                .filter(newOpt => newOpt.value !== "all");
        }
        this.setState({
            searchField: string,
            ClinicCodeArry: newOpts
        });
    };
    onInputChange = (string, { action }) => {
        if (action === "input-change") {
            this.setState({
                searchField: string
            });
        }
    };
    filterOption = ({ label, value }, string) => {

        if (value === "all") {
            return true;
        } else if (string) {
            return label.toLocaleLowerCase().includes(string) || value.toString().includes(string);
        } else {
            return true;
        }
    };
    handleChangeUser = (opt, { option }) => {
        let newOpts = opt;
        let string = this.state.searchField;
        if (option && option.value === "all") {
            let filteredOptions = clone(this.props.users);

            filteredOptions = filteredOptions.filter(
                filteredOption =>
                    isIncludingString(string, filteredOption) &&
                    !newOpts.includes(filteredOption)
            );

            string = null;
            newOpts = newOpts
                .concat(filteredOptions)
                .filter(newOpt => newOpt.value !== "all");
        }
        this.setState({
            searchField: string,
            UserArry: newOpts
        });
    };
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">User - Phòng Khám</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >



                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Tên User</label>
                                                {/* <Select
                                                    filterOption={this.filterOption}
                                                    onInputChange={this.onInputChange}
                                                    onChange={this.handleChangeUser}
                                                    options={this.props.users}
                                                    value={this.state.UserArry}

                                                    isDisabled={this.state.isDisabled}
                                                    isLoading={this.state.isLoading}
                                                    isClearable={this.state.isClearable}
                                                    isRtl={this.state.isRtl}
                                                    isSearchable={this.state.isSearchable}

                                              />*/}
                                                {loadOptions === undefined ? null :

                                                    <AsyncPaginate
                                                        value={this.state.UserArry || ""}
                                                        filterOption={this.filterOption}
                                                        loadOptions={loadOptions}
                                                        onInputChange={this.onInputChange}
                                                        onChange={this.handleChangeUser}
                                                        additional={{
                                                            page: 1,
                                                        }}
                                                    />

                                                }



                                            </div>

                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <Select
                                                    filterOption={this.filterOption}
                                                    onInputChange={this.onInputChange}
                                                    onChange={this.handleChange}
                                                    options={this.props.clinics}
                                                    value={this.state.ClinicCodeArry}

                                                    isDisabled={this.state.isDisabled}
                                                    isLoading={this.state.isLoading}
                                                    isClearable={this.state.isClearable}
                                                    isRtl={this.state.isRtl}
                                                    isSearchable={this.state.isSearchable}

                                                />
                                            </div>

                                        </div>



                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/map-user-clinic" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        users: state.users,
        EditingMapUserClinic: state.EditingMapUserClinic
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddMap: (usersclinics) => {
            dispatch(addMapUserClinicRequest(usersclinics));
        },
        onEditMap: (id) => {
            dispatch(eidtMapUserClinicRequest(id));
        },
        onUpdateMap: (usersclinics) => {
            dispatch(updateMapUserClinicRequest(usersclinics));
        },
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },
        fetchAllCategoryUser: () => {
            dispatch(actCategoryUserRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(MapUserClinicActionPage);
