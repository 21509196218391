import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { addNotifiRequest, eidtNotifiRequest, updateNotifiRequest, actFetchClinicNotifiequest } from '../../actions/Notification/AcNotification';

class NotifiActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            NameNotifi: '',
            NoteNotifi: '',
            value: '',
            ClinicCode: ''

        }

    }
    onChangeEditor = (e) => {
        this.setState({
            NoteNotifi: e
        })
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditNotifi(id);
        }
        this.props.onClinicNotifi();
    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.itemEditingNotifi !== this.props.itemEditingNotifi) {

                this.props.itemEditingNotifi.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        NameNotifi: menuItem.NameNotifi,
                        NoteNotifi: menuItem.NoteNotifi,
                        ClinicCode: menuItem.ClinicCode
                    })
                ))
            }
        }
    }
    onSave = (e) => {
        var { id, NameNotifi, NoteNotifi, ClinicCode } = this.state;
        e.preventDefault();
        if (id) {
            var arrNotifi = {
                RowID: id,
                NameNotifi: NameNotifi,
                NoteNotifi: NoteNotifi,
                ClinicCode: ClinicCode
            }
            this.props.onUpdateNotifi(arrNotifi);
        } else {
            if (NameNotifi === '' || NoteNotifi === '' || ClinicCode === '') {
                alert('Vui lòng nhập đầy đủ thông tin !')
            } else {

                var arrNotifis = {
                    RowID: Math.floor(Math.random() * 10000000000),
                    NameNotifi: NameNotifi,
                    NoteNotifi: NoteNotifi,
                    ClinicCode: ClinicCode
                }
                this.props.onAddNotifi(arrNotifis);
            }

        }


    }
    categoryClinic() {
        if (this.props.clinicnotifi) {
            return (
                this.props.clinicnotifi.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }
    onChangePK = (e) => {
        var target = e.target;
        var name = target.name;

        var value = target.type === 'checkbox' ? target.checked : target.value;
        localStorage.setItem('ClinicCode', value);
        this.setState({
            [name]: value
        })
    }



    render() {
        var { NameNotifi, NoteNotifi, ClinicCode } = this.state;
        const defaultFonts = [
            "Arial",
            "Comic Sans MS",
            "Courier New",
            "Impact",
            "Georgia",
            "Tahoma",
            "Trebuchet MS",
            "Verdana"
        ];
        const sortedFontOptions = [
            "Logical",
            "Salesforce Sans",
            "Garamond",
            "Sans-Serif",
            "Serif",
            "Times New Roman",
            "Helvetica",
            ...defaultFonts
        ].sort();

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Thông báo</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label >Tên thông báo</label>
                                                <input type="text"
                                                    name="NameNotifi"
                                                    className="form-control"
                                                    value={NameNotifi}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label >Phòng khám:</label>
                                                <select value={ClinicCode} className="form-control conleft" onChange={this.onChangePK} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryClinic()}

                                                </select>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="form-group" id="dynamic_field">
                                        <label >Nội dung</label>
                                        <SunEditor
                                            plugin=""
                                            setContents={NoteNotifi}
                                            onChange={this.onChangeEditor}
                                            setOptions={{
                                                buttonList: [
                                                    ["undo", "redo"],
                                                    ["font", "fontSize"],
                                                    // ['paragraphStyle', 'blockquote'],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript"
                                                    ],
                                                    ["fontColor", "hiliteColor"],
                                                    ["align", "list", "lineHeight"],
                                                    ["outdent", "indent"],

                                                    ["table", "horizontalRule", "link", "image", "video"],
                                                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                    // ["fullScreen", "showBlocks", "codeView"],
                                                    ["preview", "print", "save"],
                                                    ["removeFormat"]

                                                    // ['save', 'template'],
                                                    // '/', Line break
                                                ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                defaultTag: "div",
                                                minHeight: "300px",
                                                showPathLabel: false,
                                                font: sortedFontOptions
                                            }}
                                        />

                                        <textarea
                                            name='NoteNotifi'
                                            disabled
                                            value={JSON.stringify(NoteNotifi, null, 2)}
                                            style={{ display: 'none' }}
                                        />

                                    </div>
                                    <div className="btnAdd">
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/thong-bao" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        itemEditingNotifi: state.itemEditingNotifi,
        clinicnotifi: state.clinicnotifi

    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddNotifi: (notifications) => {
            dispatch(addNotifiRequest(notifications));
        },
        onEditNotifi: (id) => {
            dispatch(eidtNotifiRequest(id));
        },
        onUpdateNotifi: (notifications) => {
            dispatch(updateNotifiRequest(notifications));
        },
        onClinicNotifi: () => {
            dispatch(actFetchClinicNotifiequest());
        }


    }

}
export default connect(mapStateToProps, mapDispatchToProps)(NotifiActionPage);