import * as Types from '../../constants/Doctors/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';
export const actDatHenDocTorsRequest = () => {
    const ClinicCode = localStorage.getItem('ClinicCode');
    return (dispatch) => {
        return UrlApi(`category-dat-hen-doctors`, 'POST', { "ClinicCode": ClinicCode }, authHeader()).then(res => {
            if (res === undefined) {
                window.location.href = './';
               // alert("Không thể kết nối được! Vui lòng kiểm tra lại mạng");
            } else {
                const token = localStorage.getItem('token');
                if (token === null) {
                    window.location.href = '/';
                } else {
                    dispatch(DatHenDoctor(res.data));
                }
            }
        })
    }
}

export const DatHenDoctor = (catedoctordathens) => {
    return {
        type: Types.DATHEN_DOCTORS,
        catedoctordathens
    }
}

export const actFetchDoctorRequest = () => {
    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = '/';
    } else {
        return (dispatch) => {
            return UrlApi('danh-sach-doctor', 'GET', null, authHeader()).then(res => {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(actFetchDoctor(res.data));
                }
            })
        }
    }

}
export const actFetchDoctor = (doctors) => {
    return {
        type: Types.FETCH_DOCTORS,
        doctors
    }
}

export const deleteDoctorRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-doctor/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteDoctor(id));
        })
    }
}
export const deleteDoctor = (id) => {
    return {
        type: Types.DELETE_DOCTORS,
        id
    }
}

export const addDoctorRequest = (doctors) => {
    return (dispatch) => {
        return UrlApi('add-doctor', 'POST', doctors, authHeader()).then(res => {
            dispatch(addDoctor(res.data));
        })
    }
}

export const addDoctor = (doctors) => {
    return {
        type: Types.ADD_DOCTORS,
        doctors
    }
}


export const eidtDoctorRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`doctor/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editDoctor(res.data));
            }

        })
    }
}
export const editDoctor = (doctors) => {
    return {
        type: Types.EDIT_DOCTORS,
        doctors
    }
}


export const updateDoctorRequest = (doctors) => {
    return (dispatch) => {
        return UrlApi(`update-doctor/${doctors.id}`, 'PUT', doctors, authHeader()).then(res => {
            dispatch(updateDoctor(res.data));

        })
    }
}


export const updateDoctor = (doctors) => {
    return {
        type: Types.UPDATE_DOCTORS,
        doctors
    }
}

export const updateisLockedDoctorRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-doctor/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedDoctor(id));

        })
    }
}
export const updateisLockedDoctor = (id) => {
    return {
        type: Types.UPDATE_STATUS_DOCTORS,
        id
    }
}






/*
export const actSearchBacSi = keyword => {
    return {
      type: Types.SEARCH_BACSI,
      keyword
    };
  };
  */
