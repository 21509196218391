import React, { Component } from 'react';
class ServiceLaboratoryEntryList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <div className="Tableauto">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th >Mã TN</th>
                                            <th >Mã PK</th>
                                            <th >Mã BN</th>
                                            <th >Mã BS NV</th>
                                            <th >Tên BS NV</th>
                                            <th >Chuẩn đoán BD</th>
                                            <th >STT</th>
                                            <th >Ghi chú</th>
                                            <th >Ngày lấy mẫu</th>
                                            <th >Ngày trả KQ</th>
                                            <th >Kết quả</th>
                                            <th >Mã BS NV KT</th>
                                            <th >Tên BS NV KT</th>
                                            <th >Khoa phòng chỉ định</th>
                                            <th >Tên DV</th>
                                            <th> Mã DV</th>
                                            <th >Loại DV</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default ServiceLaboratoryEntryList;

