import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router-dom';
//import { showKetQuaCLSAdminRequest, showBNCLSAdminRequest, showPKCLSAdminRequest, showTDCLSAdminRequest, showXNCLSAdminRequest, showXNKQCLSAdminRequest } from '../../actions/DSBNAdmin/AcDSBNAdmin';

class KetQuaCLSPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
   /* componentDidMount() {
        var { match } = this.props;
        if (match) {
            var idBN = match.params.idBN;
            var id = match.params.id;
            this.props.onShowBN(idBN, id);
            this.props.onShowPK(idBN, id);
            this.props.onShowCLS(idBN, id);
            this.props.onShowTD(idBN, id);
            this.props.onShowXN(idBN, id);
            this.props.onShowXNResult(idBN, id);

        }
    }*/

    render() {
        //var { ketquacls, phongkhamcls, benhnhancls, tieudecls, ketquaxetnghiem, ketquaxetnghiemresult } = this.props;
       
        return (
            <div className="col-md-12">
                <div className='BgPhongkham' ref={el => (this.componentRef = el)}>
                   <div>sdfsdfdsf</div>
                </div>
                <ReactToPrint
                    trigger={() => <Link to="#" type="button" className=" btnPrint btn btn-block bg-gradient-success btn-xs">In CLS</Link>}
                    content={() => this.componentRef}
                />

            </div>
        );
    }
   


}
const mapStateToProps = state => {
    return {
       // ketquacls: state.ketquacls,
     //   phongkhamcls: state.phongkhamcls,
       // benhnhancls: state.benhnhancls,
       // tieudecls: state.tieudecls,
        //ketquaxetnghiem: state.ketquaxetnghiem,
        //ketquaxetnghiemresult: state.ketquaxetnghiemresult

    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
       /* onShowCLS: (idBN, id) => {
            dispatch(showKetQuaCLSAdminRequest(idBN, id));
        },
        onShowPK: (idBN, id) => {
            dispatch(showPKCLSAdminRequest(idBN, id));
        },
        onShowBN: (idBN, id) => {
            dispatch(showBNCLSAdminRequest(idBN, id));
        },
        onShowTD: (idBN, id) => {
            dispatch(showTDCLSAdminRequest(idBN, id));
        },
        onShowXN: (idBN, id) => {
            dispatch(showXNCLSAdminRequest(idBN, id));
        },
        onShowXNResult: (idBN, id) => {
            dispatch(showXNKQCLSAdminRequest(idBN, id));
        },*/

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KetQuaCLSPage);

/*
{this.showKetQuaThucHien(ketquacls, phongkhamcls, tieudecls, benhnhancls, ketquaxetnghiem,ketquaxetnghiemresult)}

 showPhongKhamList(phongkhamcls) {
        var result = null;

        if (phongkhamcls.length > 0) {
            result = phongkhamcls.map((phongkham, index) => {
                return (
                    <div className="TieuDePhongKham" key={index}>
                        <div className="ImgPhongKham">
                            <img src="http://localhost:3000/dist/img/logo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
                        </div>
                        <div className="ThongTinPhongKham">
                            <div className="TenPk">{phongkham.ClinicName}</div>
                            <div className="AddressPk">D/c: {phongkham.ClinicAddress}</div>
                            <div className="PhonePk">Điện thoại: {phongkham.Mobile}</div>
                        </div>
                    </div>
                );
            })
        }
        return result;
    }
    showBenhNhanList(benhnhancls) {
        var result = null;
        if (benhnhancls.length > 0) {
            result = benhnhancls.map((benhnhan, index) => {
                return (
                    <div key={index}>
                        <div className="ThongTinBenhNhanRow">
                            <div className='rowBN'>
                                <div className='colmd6'>
                                    <span>Họ Tên: </span>
                                    <span className='nameBN'>{benhnhan.PatientName}</span>
                                </div>
                                <div className='colmd4'>
                                    <span>Năm sinh:</span>
                                    <span>{benhnhan.PatientBirthDay}</span>
                                </div>
                                <div className='colmd3'>
                                    <span>Giới tính:</span>
                                    <span>{benhnhan.PatientGender === 1 ? 'Nam' : 'Nữ'}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ThongTinBenhNhanRow'>
                            <span>Địa chỉ: </span>
                            <span>{benhnhan.PatientAddress}</span>
                        </div>
                    </div>
                );
            })
        }
        return result;
    }
    showKetQuaTieuDe(tieudecls) {
        var result = null;

        if (tieudecls.length > 0) {
            result = tieudecls.map((tieude, index) => {
                return (
                    <div className='TieuDeCLS' key={index}>KẾT QUẢ {tieude.ServiceGroupName}</div>
                );
            })
        }
        return result;
    }

    showKetQuaXetNghiem(ketquaxetnghiemresult) {
     
        var result = null;
        if (ketquaxetnghiemresult.length > 0) {
            result = ketquaxetnghiemresult.map((ketqualist, index) => {
                var listkq = ketqualist.Result.split('|');
                return (
                    <tr key={index} >
                          <td>{listkq[0]}</td>
                          <td align='center'>{listkq[2]}</td>  
                          <td align='center'>{listkq[1]}</td> 
                          <td align='center'>{listkq[3]}</td>  
                    </tr>
                )
            })
        }

       return result;

    }
    showKetQuaThucHien(ketquacls, phongkhamcls, tieudecls, benhnhancls, ketquaxetnghiem,ketquaxetnghiemresult) {
        var result = null;
        
        if (ketquacls.length > 0) {
            result = ketquacls.map((ketqua, index) => {
                if (ketqua.ServiceModuleCode === 'XN') {
                    return (
                        <div key={index}>
                            {this.showPhongKhamList(phongkhamcls)}
                            <div className='TieuDeKetQua'>
                                {this.showKetQuaTieuDe(tieudecls)}

                            </div>
                            <div className='ThongTinBenhNhan'>
                                {this.showBenhNhanList(benhnhancls)}
                                <div className='ThongTinBenhNhanRow'>
                                    <span>Chuẩn đoán: </span>
                                    <span>{ketqua.DiagnosisCustom}</span>
                                </div>
                                <div className='ThongTinBenhNhanRow'>{ketqua.ServiceName}</div>

                            </div>
                            <div className="KetQuaXetNghiem">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>TÊN XÉT NGHIỆM</th>
                                            <th>KẾT QUẢ</th>
                                            <th>ĐƠN VỊ</th>
                                            <th>KHOẢN THAM CHIẾU</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      
                                            {
                                                this.showKetQuaXetNghiem(ketquaxetnghiemresult) 
                                            }
                                      
                                   
                                    </tbody>


                                </table>
                            </div>
                           

                            <div className='KyTen'>
                                <div className='KyTenBG'>
                                    <div className='NgayThangNam'>Ngày {moment(new Date(ketqua.PostingDateDay)).format('DD')} tháng {moment(new Date(ketqua.PostingDateDay)).format('MM')} năm {moment(new Date(ketqua.PostingDateDay)).format('YYYY')}</div>
                                    <div className='TenBSTH'>PHÒNG XÉT NGHIỆM</div>
                                    <div className='TenBacSi'>{ketqua.EmployeeDoctorDone_Name}</div>
                                </div>

                            </div>



                        </div>
                    );
                } else {
                    return (
                        <div key={index}>
                            <div className="BgLeft">
                                {this.showPhongKhamList(phongkhamcls)}
                                <div className='TieuDeKetQua'>
                                    {this.showKetQuaTieuDe(tieudecls)}
                                </div>
                                <div className='ThongTinBenhNhan'>
                                    {this.showBenhNhanList(benhnhancls)}
                                    <div className='ThongTinBenhNhanRow'>
                                        <span>Chuẩn đoán: </span>
                                        <span>{ketqua.DiagnosisCustom}</span>
                                    </div>
                                    <div className='ThongTinBenhNhanRow'>{ketqua.ServiceName}</div>
                                </div>
                                <div className='NoteKetQua' >{ketqua.Contents}</div>

                            </div>
                            <div className="BgRight">
                                <div className="Imgketqua">
                                    <img src="http://localhost:3000/dist/img/2108000001_Image01.jpg" alt="AdminLTE Logo" />
                                    <img src="http://localhost:3000/dist/img/2108000001_Image01.jpg" alt="AdminLTE Logo" />
                                </div>
                                <div className='KetLuan'>
                                    <div className='KetLuanTile'>KẾT LUẬN</div>
                                    <div className='KetLuanNote'>{ketqua.Conclusion}</div>
                                </div>
                                <div className='KetLuan'>
                                    <div className='KetLuanTile'>ĐỀ NGHỊ</div>
                                    <div className='KetLuanNote'>{ketqua.Proposal}</div>
                                </div>
                                <div className='KyTen'>
                                    <div className='KyTenBG'>
                                        <div className='NgayThangNam'>Ngày {moment(new Date(ketqua.PostingDateDay)).format('DD')} tháng {moment(new Date(ketqua.PostingDateDay)).format('MM')} năm {moment(new Date(ketqua.PostingDateDay)).format('YYYY')}</div>
                                        <div className='TenBSTH'>Bác sĩ siêu âm</div>
                                        <div className='TenBacSi'>{ketqua.EmployeeDoctorDone_Name}</div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    );
                }
            })

        }
        return result;
    }
*/