import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const dsbnadmins = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_DSBN_ADMIN:
            state = action.dsbnadmins;
            return [...state];

        default: return [...state];
    }
}
export default dsbnadmins;