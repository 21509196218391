
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ServiceLaboratoryEntryItem extends Component {
 
    onDelete = (id) => {
        
        this.props.onDelete(id);
    }

    render() {
        var { servicelaboratory } = this.props;

        return (
            <tr>
                <td>{servicelaboratory.SuggestedReceiptID}</td>
                <td>{servicelaboratory.PatientReceiveID}</td>
                <td>{servicelaboratory.ClinicCode}</td>
                <td>{servicelaboratory.PatientCode}</td>
                <td>{servicelaboratory.EmployeeDoctorOrder_Code}</td>
                <td>{servicelaboratory.EmployeeDoctorOrder_Name}</td>
                <td>{servicelaboratory.DiagnosisCustom}</td>
                <td>{servicelaboratory.STT_Mau}</td>
                <td>{servicelaboratory.Note}</td>
                <td>{servicelaboratory.PostingDate_GetOrder}</td>
                <td>{servicelaboratory.PostingDate_Result}</td>
                <td>{servicelaboratory.Result}</td>
                <td>{servicelaboratory.EmployeeDoctorDone_Code}</td>
                <td>{servicelaboratory.EmployeeDoctorDone_Name}</td>
                <td>{servicelaboratory.DepartmentNameOrder}</td>
                <td>{servicelaboratory.ServiceName}</td>
                <td>{servicelaboratory.ServiceCode}</td>
                <td>{servicelaboratory.ServiceCategoryName}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(servicelaboratory.SuggestedReceiptID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/service-laboratory-entry/${servicelaboratory.SuggestedReceiptID}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default ServiceLaboratoryEntryItem;

