import React, { Component } from 'react';
import ClinicList from './../../components/Clinic/ClinicList'
import ClinicItem from './../../components/Clinic/ClinicItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import { actFetchClinicRequest,deleteClinicRequest,updateisLockedClinicRequest } from '../../actions/Clinic/AcClinic';


class ClinicPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idClinic:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
           
        }

    }
    componentDidMount(){
        this.props.fetchAllClinic();
        this.props.getProfileFetch();
        
    }
    componentDidUpdate(prevProps, prevState) {
       
        if (prevState.idClinic && this.state.idClinic !== '') {
            this.props.fetchAllClinic();
            this.props.getProfileFetch();
            this.setState({ idClinic: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idClinic: id })
        this.props.onUpdateStatusClinic(id);
    }
  

    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idClinic: id })
            this.props.onDeleteClinic(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };

    render() {
       
        var { clinics } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];

        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        rowsPerPage = clinics.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3 className="card-title">Phòng khám</h3>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/clinic/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
               <div className="pageMainShow">
                <ClinicList>
                    {this.showClinic(rowsPerPage) }
                </ClinicList>
                <p className="totalPhongKham">
                    Tổng cộng: {clinics.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={clinics.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />
                </div>


            </div>
        );
    }
    showClinic(clinics) {
        
        var result = null;
        if (clinics.length > 0) {
            result = clinics.map((clinic, index) => {
                return (
                    <ClinicItem
                        key={index}
                        clinic={clinic}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        payload: state.payload
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllClinic: () => {
            dispatch(actFetchClinicRequest());
            
        },
        onDeleteClinic: (id) => {
            dispatch(deleteClinicRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
      
        onUpdateStatusClinic: (id) => {
            dispatch(updateisLockedClinicRequest(id));
        },
      
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicPage);