export const FETCH_SUGGESTED_SERVICE ='FETCH_SUGGESTED_SERVICE';
export const ADD_SUGGESTED_SERVICE ='ADD_SUGGESTED_SERVICE';
export const UPDATE_SUGGESTED_SERVICE ='UPDATE_SUGGESTED_SERVICE';
export const DELETE_SUGGESTED_SERVICE ='DELETE_SUGGESTED_SERVICE';
export const EDIT_SUGGESTED_SERVICE ='EDIT_SUGGESTED_SERVICE';
export const SEARCH_SUGGESTED_SERVICE ='SEARCH_SUGGESTED_SERVICE';
export const FETCH_SUGGESTED_SERVICE_CATE ='FETCH_SUGGESTED_SERVICE_CATE';



