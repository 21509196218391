import React, { Component } from 'react';
import ChuyenKhoaList from './../../components/ChuyenKhoa/ChuyenKhoaList'
import ChuyenKhoaItem from './../../components/ChuyenKhoa/ChuyenKhoaItem';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
import { Link } from 'react-router-dom';
import { getProfileFetch } from '../../actions/AcLogin';
import Pagination from '../Pagination/Pagination';
import { actFetchCKhoaRequest,deleteCKhoaRequest,updateisLockedCKhoaRequest } from '../../actions/ChuyenKhoa/AcChuyenKhoa';


class ChuyenKhoaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thongbao: false,
            thongbaoxoa: true,
            idChuyenKhoa:'',
            totalPages: "",
            pageLimit: "",
            currentPage: "",
            startIndex: "",
            endIndex: ""
           
        }

    }
    componentDidMount(){
        this.props.fetchAllChuyenKhoa();
        this.props.getProfileFetch();
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.idChuyenKhoa && this.state.idChuyenKhoa !== '') {
            this.props.fetchAllChuyenKhoa();
            this.props.getProfileFetch();
            this.setState({ idChuyenKhoa: '' })
        }
    }
    onUpdateStatus = (id) => {
        this.setState({ idChuyenKhoa: id })
        this.props.onUpdateStatusChuyenKhoa(id);
    }
  

    onDelete = (id) => {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ thongbao: true })
        } else {
            this.setState({ idChuyenKhoa: id })
            this.props.onDeleteChuyenKhoa(id);
        }
    }
    clickThongBao() {
        if (this.state.thongbao === true) {
            this.setState({ thongbao: false })
            window.location.reload(true);
        }

    }
    onChangePage = data => {
        this.setState({
            pageLimit: data.pageLimit,
            totalPages: data.totalPages,
            currentPage: data.page,
            startIndex: data.startIndex,
            endIndex: data.endIndex
        });
    };

    render() {
       
        var { chuyenkhoas } = this.props;
        var {
            totalPages,
            currentPage,
            pageLimit,
            startIndex,
            endIndex
        } = this.state;
        var rowsPerPage = [];

        if (this.props.payload.currentUser === 'TAIKHOAN_XOA') {
            return (
                <ReactJsAlert
                    status={this.state.thongbaoxoa}
                    type="warning"   // success, warning, error, info
                    title="Tài khoản đã bị xóa"
                    Close={() => {this.setState( {thongbaoxoa : false});  localStorage.removeItem("token"); window.location.reload(true);} }
                    button='Đăng nhập'
                />

            );
        } 
        rowsPerPage = chuyenkhoas.slice(startIndex, endIndex + 1);
        return (
            <div className="col-md-12">
                <div className="titlePage">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3 className="card-title">Chuyên Khoa</h3>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/chuyen-khoa/add" className="btn btn-info"> <i className="far fa-edit"></i> Thêm</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
               <div className="pageMainShow">
                <ChuyenKhoaList>
                    {this.showChuyenKhoa(rowsPerPage) }
                </ChuyenKhoaList>
                <p className="totalPhongKham">
                    Tổng cộng: {chuyenkhoas.length} | Trang {currentPage}/{totalPages}
                </p>
                <Pagination
                    totalRecords={chuyenkhoas.length}
                    pageLimit={pageLimit || 20}
                    initialPage={1}
                    pagesToShow={20}
                    onChangePage={this.onChangePage}
                />

                <ReactJsAlert
                    status={this.state.thongbao}   // true or false
                    type="warning"   // success, warning, error, info
                    title="Tài khoản không tồn tại"   // title you want to display
                    Close={() => this.clickThongBao()}   // callback method for hide
                    button='Đăng nhập'
                />
                </div>


            </div>
        );
    }
    showChuyenKhoa(chuyenkhoas) {
        
        var result = null;
        if (chuyenkhoas.length > 0) {
            result = chuyenkhoas.map((chuyenkhoa, index) => {
                return (
                    <ChuyenKhoaItem
                        key={index}
                        chuyenkhoa={chuyenkhoa}
                        index={index}
                        onDelete={this.onDelete}
                        onUpdateStatus={this.onUpdateStatus}
                    />
                )
            })
        }
        return result;
    }

}
const mapStateToProps = state => {
    return {
        chuyenkhoas: state.chuyenkhoas,
        payload: state.payload
    }

}
const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllChuyenKhoa: () => {
            dispatch(actFetchCKhoaRequest());
            
        },
        onDeleteChuyenKhoa: (id) => {
            dispatch(deleteCKhoaRequest(id));
        },
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
      
        onUpdateStatusChuyenKhoa: (id) => {
            dispatch(updateisLockedCKhoaRequest(id));
        },
      
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChuyenKhoaPage);