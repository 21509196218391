
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class NewsItem extends Component {
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { // no-restricted-globals
            this.props.onDelete(id);
           // window.location.href = '/news';
        }
    }
    onUpdateStatus = (id) => {
        this.props.onUpdateStatus(id);
    }

    render() {
        var { newitem } = this.props;
        var statusname = newitem.isLocked === true ? 'Hiện' : 'Ẩn';
        var statusclass = newitem.isLocked === true ? 'btn-success' : 'btn-warning';
        return (
            <tr>
                <td>{newitem.NewsName}</td>
                <td >
                    <span className={`labelIs btn btn-block  btn-xs ${statusclass}`} onClick={() => this.onUpdateStatus(newitem.RowID)} >
                        {statusname}
                    </span>
                </td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(newitem.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/news/${newitem.RowID}/edit`}  className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
export default NewsItem;

