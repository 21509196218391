export const FETCH_DSBN_ADMIN ='FETCH_DSBN_ADMIN';
export const FETCH_ADMIN ='FETCH_ADMIN';
export const SEARCH_KEYWORD ='SEARCH_KEYWORD';
export const SEARCH_CATEGORY ='SEARCH_CATEGORY';
export const VIEW_BN_ADMIN ='VIEW_BN_ADMIN';
export const FETCH_THUOC_ADMIN ='FETCH_THUOC_ADMIN';
export const FETCH_DVKT_ADMIN ='FETCH_DVKT_ADMIN';
export const FETCH_MEDIC_ADMIN ='FETCH_MEDIC_ADMIN';

export const KETQUA_CLS_ADMIN ='KETQUA_CLS_ADMIN';
export const PHONGKHAM_CLS_ADMIN ='PHONGKHAM_CLS_ADMIN';
export const BENHNHAN_CLS_ADMIN ='BENHNHAN_CLS_ADMIN';
export const TIEUDE_CLS_ADMIN ='TIEUDE_CLS_ADMIN';
export const XETNGHIEM_CLS_ADMIN ='XETNGHIEM_CLS_ADMIN';
export const XETNGHIEM_CLS_KQ_ADMIN ='XETNGHIEM_CLS_KQ_ADMIN';

export const FETCH_THONGTIN_USER ='FETCH_THONGTIN_USER';



