import * as Types from '../../constants/User/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchUserRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('user', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchUser(res.data));
                }
            })
        }
    }
 
}
export const actFetchUser = (users) => {
    return {
        type: Types.FETCH_USER,
        users
    }
}

export const deleteUserRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-user/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteUser(id));
        })
    }
}
export const deleteUser = (id) => {
    return {
        type: Types.DELETE_USER,
        id
    }
}

export const addUserRequest = (users) => {
    return (dispatch) => {
        return UrlApi('add-user', 'POST', users, authHeader()).then(res => {
            dispatch(addUser(res.data));
            window.location.href = '/user';
        })
    }
}

export const addUser = (users) => {
    return {
        type: Types.ADD_USER,
        users
    }
}

export const eidtUserRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`user/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editUser(res.data));
            }

        })
    }
}


export const editUser = (users) => {
    return {
        type: Types.EDIT_USER,
        users
    }
}


export const updateUserRequest = (users) => {
  
    return (dispatch) => {
        return UrlApi(`update-user/${users.id}`, 'PUT', users, authHeader()).then(res => {
            dispatch(updateUser(res.data));
            window.location.href = '/user';
        })
    }
}


export const updateUser = (users) => {
    return {
        type: Types.UPDATE_USER,
        users
    }
}

export const updateisLockedUserRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-user/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedUser(id));

        })
    }
}
export const updateisLockedUser = (id) => {
    return {
        type: Types.UPDATE_STATUS_USER,
        id
    }
}
export const updateisdoctorUserRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isdoctor-user/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisdoctorUser(id));

        })
    }
}
export const updateisdoctorUser = (id) => {
    return {
        type: Types.UPDATE_STATUS_DOCTOR,
        id
    }
}


export const actCategoryUserRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('user-category', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actCategoryUser(res.data));
                }
            })
        }
    }
 
}
export const actCategoryUser = (users) => {
    return {
        type: Types.CATEGORY_USER,
        users
    }
}

/*
export const actSearchBacSi = keyword => {
    return {
      type: Types.SEARCH_BACSI,
      keyword
    };
  };
  */
