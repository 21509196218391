import * as Types from '../../constants/TraCuuKetQua/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

/*export const showKetQuaXetNghiemRequest = (NgayKQ,IDMau,categoryID) => {
    return (dispatch) => {
        return UrlApi(`ket-qua-xet-nghiem/NgayKQ=${NgayKQ}&IDMau=${IDMau}&categoryID=${categoryID}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showKetQuaXetNghiem(res.data));
            }

        })
    }
}*/
export const showKetQuaXetNghiemRequest = (NgayKQ,IDMau,categoryID) => {
    return (dispatch) => {
        let tokentop = JSON.parse(localStorage.getItem('token'));
        return UrlApi(`ket-qua-xet-nghiem-web`, 'POST', { "refreshToken": tokentop,"NgayKQ":NgayKQ,"IDMau":IDMau,"categoryID":categoryID }, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(showKetQuaXetNghiem(res.data));
            }

        })
    }
}
export const showKetQuaXetNghiem = (tracuuketquaxetnghiem) => {
    return {
        type: Types.FETCH_TRACUUKETQUAXN,
        tracuuketquaxetnghiem
    }
}

export const actFetchClinicTraCuuequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('clinic-tra-cuu-ket-qua-web','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchClinicTraCuu(res.data))
                }
            }
            
        })
    }
}


export const actFetchClinicTraCuu = (clinictracuus) => {
    return {
        type: Types.FETCH_CLINIC_TRACUU,
        clinictracuus
    }
}




