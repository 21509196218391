import * as Types from '../../constants/MedicalRecord/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchMedicRecordRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('medical-record', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchMedicRecord(res.data));
                }
            })
        }
    }
 
}
export const actFetchMedicRecord = (medicrecords) => {
    return {
        type: Types.FETCH_MEDIC_RECORD,
        medicrecords
    }
}

export const deleteMedicRecordRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-medical-record/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteMedicRecord(id));
        })
    }
}
export const deleteMedicRecord = (id) => {
    return {
        type: Types.DELETE_MEDIC_RECORD,
        id
    }
}

export const addMedicRecordRequest = (medicrecords) => {
    return (dispatch) => {
        return UrlApi('add-medical-record', 'POST', medicrecords, authHeader()).then(res => {
            dispatch(addMedicRecord(res.data));
        })
    }
}

export const addMedicRecord = (medicrecords) => {
    return {
        type: Types.ADD_MEDIC_RECORD,
        medicrecords
    }
}


export const eidtMedicRecordRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`medical-record/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editMedicRecord(res.data));
            }

        })
    }
}
export const editMedicRecord = (medicrecords) => {
    return {
        type: Types.EDIT_MEDIC_RECORD,
        medicrecords
    }
}


export const updateMedicRecordRequest = (medicrecords) => {
    return (dispatch) => {
        return UrlApi(`update-medical-record/${medicrecords.id}`, 'PUT', medicrecords, authHeader()).then(res => {
            dispatch(updateMedicRecord(res.data));

        })
    }
}
export const updateMedicRecord = (medicrecords) => {
    return {
        type: Types.UPDATE_MEDIC_RECORD,
        medicrecords
    }
}


export const actFetchMedicRecordCateRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('medical-record-category', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchMedicRecordCate(res.data));
                }
            })
        }
    }
 
}
export const actFetchMedicRecordCate = (medicrecords) => {
    return {
        type: Types.FETCH_MEDIC_RECORD_CATE,
        medicrecords
    }
}


