
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
class MedicRecordDetailItem extends Component {
 
    onDelete = (id) => {
        
        this.props.onDelete(id);
    }

    render() {
        var { medicrecorddetail } = this.props;
        var PostingDateDay = moment(new Date(medicrecorddetail.PostingDateDay)).format('DD/MM/YYYY');
       // var PostingDateTime = moment(new Date(medicrecorddetail.PostingDateTime)).format('DD/MM/YYYY');

        return (
            <tr>
                <td>{medicrecorddetail.RowID}</td>
                <td>{medicrecorddetail.PatientReceiveID}</td>
                <td>{medicrecorddetail.ClinicCode}</td>
                <td>{medicrecorddetail.PatientCode}</td>
                <td>{medicrecorddetail.MedicalRecordCode}</td>
                <td>{medicrecorddetail.ItemName}</td>
                <td>{medicrecorddetail.ItemCode}</td>
                <td>{medicrecorddetail.Active}</td>
                <td>{medicrecorddetail.ItemContent}</td>
                <td>{medicrecorddetail.UnitOfMeasureName}</td>
                <td>{medicrecorddetail.UsageName}</td>
                <td>{medicrecorddetail.DateOfIssues}</td>
                <td>{medicrecorddetail.S_Tr_Ch_T}</td>
                <td>{medicrecorddetail.Quantity}</td>
                <td>{medicrecorddetail.Instruction}</td>
                <td>{medicrecorddetail.UnitPrice}</td>
                <td>{medicrecorddetail.Amount}</td>
                <td>{medicrecorddetail.ObjectCode}</td>
                <td>{medicrecorddetail.isToaVe}</td>
                <td>{PostingDateDay}</td>
                <td>{medicrecorddetail.STT}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(medicrecorddetail.RowID)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/medical-record-detail/${medicrecorddetail.RowID}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}

export default MedicRecordDetailItem;

