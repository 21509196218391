import * as Types from '../../constants/View/ActionTypes';
var initialState = [];
const medicalrecords = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_MEDICRE:
           state = action.medicalrecords;
            return [...state];

        default: return [...state];
    }
}
export default medicalrecords;