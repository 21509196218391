import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { addServiceRadiologyEntryRequest, eidtServiceRadiologyEntryRequest, updateServiceRadiologyEntryRequest } from '../../actions/ServiceRadiologyEntry/AcServiceRadiologyEntry';
import{actFetchSuggServiceCateRequest} from '../../actions/SuggestedServiceReceipt/AcSuggestedService';

class ServiceRadiologyEntryActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            SuggestedID: '',
            PatientReceiveID: '',
            ClinicCode: '',
            PatientCode: '',
            EmployeeDoctorOrder_Code: '',
            EmployeeDoctorOrder_Name: '',
            Contents: '',
            Conclusion: '',
            Proposal: '',
            PostingDate: '',
            EmployeeDoctorDone_Code: '',
            EmployeeDoctorDone_Name: '',
            DiagnosisCustom: '',
            Note: '',
            ServiceName: '',
            ServiceCode: '',
            ServiceModuleCode: '',
            ServiceMunuID: ''
        }
        this.handleChangePostingDate = this.handleChangePostingDate.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditServiceRadiologyEntry(id);
        }
        this.props.CateSuggService();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingServiceRadiologyEntry !== this.props.EditingServiceRadiologyEntry) {

                this.props.EditingServiceRadiologyEntry.map((menuItem) => (
                    this.setState({
                        id: menuItem.SuggestedID,
                        EmployeeDoctorOrder_Code: menuItem.EmployeeDoctorOrder_Code,
                        EmployeeDoctorOrder_Name: menuItem.EmployeeDoctorOrder_Name,
                        Contents: menuItem.Contents,
                        Conclusion: menuItem.Conclusion,
                        Proposal: menuItem.Proposal,
                        PostingDate: new Date(Date.parse(moment(menuItem.datePostingDate).format('YYYY-MM-DD') + ' ' + menuItem.timePostingDate.substring(11, 19))),
                        EmployeeDoctorDone_Code: menuItem.EmployeeDoctorDone_Code,
                        EmployeeDoctorDone_Name: menuItem.EmployeeDoctorDone_Name,
                        DiagnosisCustom: menuItem.DiagnosisCustom,
                        Note: menuItem.Note,
                        ServiceName: menuItem.ServiceName,
                        ServiceCode: menuItem.ServiceCode,
                        ServiceModuleCode: menuItem.ServiceModuleCode,
                        ServiceMunuID: menuItem.ServiceMunuID


                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, ClinicCode, EmployeeDoctorOrder_Code, EmployeeDoctorOrder_Name, Contents,
            Conclusion, Proposal, PostingDate, EmployeeDoctorDone_Code, EmployeeDoctorDone_Name, DiagnosisCustom, Note, ServiceName, ServiceCode,
            ServiceModuleCode, ServiceMunuID } = this.state;
        //var { history } = this.props;

        const PostingDateID = moment(PostingDate).format('YYYYMMDDHHmmss');

        var SuggestedID1 = this.props.suggestedservices
            .filter(commentReply => commentReply.ClinicCode === ClinicCode)
            .map((commentReply, idx) => commentReply.SuggestedID);

        var PatientReceiveID1 = this.props.suggestedservices
            .filter(commentReply => commentReply.ClinicCode === ClinicCode)
            .map((commentReply, idx) => commentReply.PatientReceiveID);

        var PatientCode1 = this.props.suggestedservices
            .filter(commentReply => commentReply.ClinicCode === ClinicCode)
            .map((commentReply, idx) => commentReply.PatientCode);



        e.preventDefault();

        if (id) {
            var ServiceEntrys = {
                SuggestedID: id,
                EmployeeDoctorOrder_Code: EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name: EmployeeDoctorOrder_Name,
                Contents: Contents,
                Conclusion: Conclusion,
                Proposal: Proposal,
                PostingDate: PostingDateID,
                EmployeeDoctorDone_Code: EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name: EmployeeDoctorDone_Name,
                DiagnosisCustom: DiagnosisCustom,
                Note: Note,
                ServiceName: ServiceName,
                ServiceCode: ServiceCode,
                ServiceModuleCode: ServiceModuleCode,
                ServiceMunuID: ServiceMunuID
            }
            this.props.onUpdateServiceRadiologyEntry(ServiceEntrys);
          //  history.goBack(window.location.reload(true));

        } else {
            var ServiceEntry = {

                SuggestedID: SuggestedID1,
                PatientReceiveID: PatientReceiveID1,
                ClinicCode: ClinicCode,
                PatientCode: PatientCode1,
                EmployeeDoctorOrder_Code: EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name: EmployeeDoctorOrder_Name,
                Contents: Contents,
                Conclusion: Conclusion,
                Proposal: Proposal,
                PostingDate: PostingDate ? moment(PostingDate).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                EmployeeDoctorDone_Code: EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name: EmployeeDoctorDone_Name,
                DiagnosisCustom: DiagnosisCustom,
                Note: Note,
                ServiceName: ServiceName,
                ServiceCode: ServiceCode,
                ServiceModuleCode: ServiceModuleCode,
                ServiceMunuID: ServiceMunuID
            }
            this.props.onAddServiceRadiologyEntry(ServiceEntry);
           // history.goBack(window.location.reload(true));
        }
        window.location.href = '/service-radiology-entry';

    }
    handleChangePostingDate = PostingDate => {
        this.setState({
            PostingDate
        });
    };
    categoryMedicRecord() {
        if (this.props.suggestedservices) {
            return (
                this.props.suggestedservices.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicCode}</option>);
                })
            )
        }
    }

    render() {
        var { ClinicCode, EmployeeDoctorOrder_Code, EmployeeDoctorOrder_Name, Contents,
            Conclusion, Proposal, PostingDate, EmployeeDoctorDone_Code, EmployeeDoctorDone_Name, DiagnosisCustom, Note, ServiceName, ServiceCode,
            ServiceModuleCode, ServiceMunuID } = this.state;


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Chi tiết Lịch sử bệnh nhân</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >DVKT</label>
                                                <select value={ClinicCode} className="form-control" onChange={this.onChange} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryMedicRecord()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày</label>
                                                <DatePicker name="PostingDate" value={PostingDate ? PostingDate : new Date()} onChange={this.handleChangePostingDate}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Đề nghị</label>
                                        <input type="text"
                                            name="Proposal"
                                            className="form-control"
                                            value={Proposal}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Kết luận</label>
                                        <input type="text"
                                            name="Conclusion"
                                            className="form-control"
                                            value={Conclusion}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Mô tả</label>
                                        <textarea type="text"
                                            name="Contents"
                                            className="form-control"
                                            value={Contents}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BS NV KT</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên BS NV KT</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên DV</label>
                                                <input type="text"
                                                    name="ServiceName"
                                                    className="form-control"
                                                    value={ServiceName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã DV</label>
                                                <input type="text"
                                                    name="ServiceCode"
                                                    className="form-control"
                                                    value={ServiceCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Mã Module DV</label>
                                                <input type="text"
                                                    name="ServiceModuleCode"
                                                    className="form-control"
                                                    value={ServiceModuleCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >ServiceMunuID</label>
                                                <input type="text"
                                                    name="ServiceMunuID"
                                                    className="form-control"
                                                    value={ServiceMunuID}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Chuẩn đoán ban đầu</label>
                                        <textarea type="text"
                                            name="DiagnosisCustom"
                                            className="form-control"
                                            value={DiagnosisCustom}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Ghi chú</label>
                                        <textarea type="text"
                                            name="Note"
                                            className="form-control"
                                            value={Note}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                  
                                    <div className="btnAdd">
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/service-radiology-entry" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        suggestedservices:state.suggestedservices,
        EditingServiceRadiologyEntry: state.EditingServiceRadiologyEntry
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddServiceRadiologyEntry: (serviceradios) => {
            dispatch(addServiceRadiologyEntryRequest(serviceradios));
        },
        onEditServiceRadiologyEntry: (id) => {
            dispatch(eidtServiceRadiologyEntryRequest(id));
        },
        onUpdateServiceRadiologyEntry: (serviceradios) => {
            dispatch(updateServiceRadiologyEntryRequest(serviceradios));
        },
        CateSuggService: () => {
            dispatch(actFetchSuggServiceCateRequest());
        },



    }

}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRadiologyEntryActionPage);