import React, { Component } from "react";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { actFetchClinicTraCuuequest } from '../../actions/TraCuuKetQua/AcTraCuuKetQua';
class SearchControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IDMau: "",
            NgayKQ: '',
            categoryID: 0,
            showSearchInfo: false
        };
        this.handleChangeNgayKQ = this.handleChangeNgayKQ.bind(this);
    }
    componentDidMount() {
        this.props.getCatePhongKham();
    }

    handleChangeNgayKQ = NgayKQ => {
        this.setState({
            NgayKQ
        });
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.IDMau && nextProps.NgayKQ && nextProps.categoryID) {
            this.setState({
                IDMau: nextProps.IDMau,
                NgayKQ: nextProps.NgayKQ,
                categoryID: nextProps.categoryID,
                showSearchInfo: true
            });
        }

    }
    onHandleChange = event => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        this.setState({
            [name]: value
        });
    };

    onSearch = e => {
        e.preventDefault();
        if (this.state.IDMau === '') {
            alert('ID Mẫu không được để trống!')
        } else {
            var ngay = this.state.NgayKQ ? this.state.NgayKQ : new Date();
            //var ngay = this.state.NgayKQ? moment(this.state.NgayKQ).format('dd/MM/yyyy') :  moment(new Date()).format('dd/MM/yyyy') 
            this.props.onSearch(ngay, this.state.IDMau, this.state.categoryID);
        }

    };

    onClearSearch = () => {
        this.props.onSearch('', '');
        this.setState({
            IDMau: "",
            NgayKQ: '',
            categoryID: 0,
            showSearchInfo: false
        });
        window.location.href = '/tra-cuu-ket-qua-xet-nghiem';
    };
    categoryPhongKham() {
        if (this.props.clinictracuus) {
            return (
                this.props.clinictracuus.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }

    render() {
        var { NgayKQ, IDMau, categoryID } = this.state;
        return (
            <div className="col-xs-12 box_search">
                <form onSubmit={this.onSearch}>
                    <div className="search_wrp mb-15">
                        {this.props.clinictracuus.length > 1 ?
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phòng khám</label>
                                        <select value={categoryID} className="form-control" onChange={this.onHandleChange} name="categoryID" >
                                            <option value="0">Chọn</option>
                                            {this.categoryPhongKham()}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Ngày xét nghiệm</label>
                                        <DatePicker className="form-control" name="NgayKQ" selected={NgayKQ ? new Date(NgayKQ) : new Date()} onChange={this.handleChangeNgayKQ}
                                            dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>ID Mẫu</label>
                                        <input
                                            type="text"
                                            name="IDMau"
                                            className="form-control"
                                            placeholder="Nhập ID mẫu"
                                            value={IDMau}
                                            onChange={this.onHandleChange}
                                        />
                                    </div>


                                </div>
                                <div className="col-md-3">
                                    <div className="input-group tracuu-xn">
                                        <label></label>
                                        <span className="input-group-btn">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={this.onSearch}
                                            >
                                                <i className="fa fa-search" />
                                                Tìm
                                            </button>
                                            <button
                                                className="btn btn-primary clearBtn"
                                                type="button"
                                                onClick={this.onClearSearch}
                                            >
                                                <i className="fa fa-times"></i>
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Ngày xét nghiệm</label>
                                        <DatePicker className="form-control" name="NgayKQ" selected={NgayKQ ? new Date(NgayKQ) : new Date()} onChange={this.handleChangeNgayKQ}
                                            dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>ID Mẫu</label>
                                        <input
                                            type="text"
                                            name="IDMau"
                                            className="form-control"
                                            placeholder="Nhập ID mẫu"
                                            value={IDMau}
                                            onChange={this.onHandleChange}
                                        />
                                    </div>


                                </div>
                                <div className="col-md-4">
                                    <div className="input-group tracuu-xn">
                                        <label></label>
                                        <span className="input-group-btn">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={this.onSearch}
                                            >
                                                <i className="fa fa-search" />
                                                Tìm
                                            </button>
                                            <button
                                                className="btn btn-primary clearBtn"
                                                type="button"
                                                onClick={this.onClearSearch}
                                            >
                                                <i className="fa fa-times"></i>
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clinictracuus: state.clinictracuus
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        getCatePhongKham: () => {
            dispatch(actFetchClinicTraCuuequest());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchControl);
