import * as Types from '../../constants/Notification/ActionTypes';
var initialState = [];
const clinicnotifi = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_CLINIC_NOTIFI:
            state = action.clinicnotifi;
            return [...state];

        default: return [...state];
    }
}
export default clinicnotifi;