import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { clone } from "lodash";
import { addDoctorRequest, eidtDoctorRequest, updateDoctorRequest } from '../../actions/Doctors/AcDoctors';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';
function isIncludingString(string, option) {
    let result = false;
    if (
        !string ||
        option.label.toString().includes(string) ||
        option.value.toString().includes(string)
    ) {
        result = true;
    }
    return result;
}

class DoctorActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            DoctorCode: '',
            DoctorCode_HIS: '',
            DoctorName: '',
            Address: '',
            Mobile: '',
            MaCCHN: '',
            MaChuyenKhoa: '',
            ClinicCode: '',

            isClearable: true,
            isSearchable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,

            searchField: null,
            ClinicCodeArry: [],
            selectedOption: ''

        }
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditDoctor(id);
        }
        this.props.fetchAllCategoryClinic();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingDoctors !== this.props.EditingDoctors) {

                this.props.EditingDoctors.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        DoctorCode: menuItem.DoctorCode,
                        DoctorCode_HIS: menuItem.DoctorCode_HIS,
                        DoctorName: menuItem.DoctorName,
                        Address: menuItem.Address,
                        Mobile: menuItem.Mobile,
                        MaCCHN: menuItem.MaCCHN,
                        MaChuyenKhoa: menuItem.MaChuyenKhoa,
                        ClinicCode: menuItem.ClinicCode,
                        ClinicCodeArry: { value: menuItem.ClinicCode, label: menuItem.ClinicName },
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, DoctorCode, DoctorCode_HIS, DoctorName, Address, Mobile, MaCCHN, MaChuyenKhoa } = this.state;
        //var { history } = this.props;

        e.preventDefault();

        if (id) {
            var doctors = {
                RowID: id,
                DoctorCode: DoctorCode,
                DoctorCode_HIS: DoctorCode_HIS,
                DoctorName: DoctorName,
                Address: Address,
                Mobile: Mobile,
                MaCCHN: MaCCHN,
                MaChuyenKhoa: MaChuyenKhoa,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onUpdateDoctor(doctors);
            //history.goBack(window.location.reload(false));
            window.location.href = '/doctor';

        } else {
            var doctor = {
                RowID: Math.floor(Math.random() * 10000000000),
                DoctorCode: DoctorCode,
                DoctorCode_HIS: DoctorCode_HIS,
                DoctorName: DoctorName,
                Address: Address,
                Mobile: Mobile,
                MaCCHN: MaCCHN,
                MaChuyenKhoa: MaChuyenKhoa,
                ClinicCode: this.state.ClinicCodeArry.value
            }
            this.props.onAddDoctor(doctor);
           // history.goBack(window.location.reload(false));
           window.location.href = '/doctor';
        }

    }
    handleChange = (opt, { option }) => {
        let newOpts = opt;
        let string = this.state.searchField;

        if (option && option.value === "all") {
            let filteredOptions = clone(this.props.clinics);

            filteredOptions = filteredOptions.filter(
                filteredOption =>
                    isIncludingString(string, filteredOption) &&
                    !newOpts.includes(filteredOption)
            );

            string = null;
            newOpts = newOpts
                .concat(filteredOptions)
                .filter(newOpt => newOpt.value !== "all");
        }
        this.setState({
            searchField: string,
            ClinicCodeArry: newOpts
        });
    };
    onInputChange = (string, { action }) => {
        if (action === "input-change") {
            this.setState({
                searchField: string
            });
        }
    };
    filterOption = ({ label, value }, string) => {

        if (value === "all") {
            return true;
        } else if (string) {
            return label.toLocaleLowerCase().includes(string) || value.toString().includes(string);
        } else {
            return true;
        }
    };

    categoryClinic() {
        if (this.props.clinics) {
            return (
                this.props.clinics.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }
    render() {
        var { DoctorCode, DoctorCode_HIS, DoctorName, Address, Mobile, MaCCHN, MaChuyenKhoa } = this.state;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">User</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã code</label>
                                                <input type="text"
                                                    name="DoctorCode"
                                                    className="form-control"
                                                    value={DoctorCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã HIS</label>
                                                <input type="text"
                                                    name="DoctorCode_HIS"
                                                    className="form-control"
                                                    value={DoctorCode_HIS}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Điện thoại</label>
                                                <input type="number"
                                                    name="Mobile"
                                                    className="form-control"
                                                    value={Mobile}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Tên Bác sĩ</label>
                                                <input type="text"
                                                    name="DoctorName"
                                                    className="form-control"
                                                    value={DoctorName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã CCHN</label>
                                                <input type="text"
                                                    name="MaCCHN"
                                                    className="form-control"
                                                    value={MaCCHN}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã CK</label>
                                                <input type="text"
                                                    name="MaChuyenKhoa"
                                                    className="form-control"
                                                    value={MaChuyenKhoa}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >PK</label>
                                                <Select
                                                    filterOption={this.filterOption}
                                                    onInputChange={this.onInputChange}
                                                    onChange={this.handleChange}
                                                    options={this.props.clinics}
                                                    value={this.state.ClinicCodeArry}

                                                    isDisabled={this.state.isDisabled}
                                                    isLoading={this.state.isLoading}
                                                    isClearable={this.state.isClearable}
                                                    isRtl={this.state.isRtl}
                                                    isSearchable={this.state.isSearchable}

                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Địa chỉ</label>
                                                <input type="text"
                                                    name="Address"
                                                    className="form-control"
                                                    value={Address}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/clinic" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        EditingDoctors: state.EditingDoctors
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddDoctor: (doctors) => {
            dispatch(addDoctorRequest(doctors));
        },
        onEditDoctor: (id) => {
            dispatch(eidtDoctorRequest(id));
        },
        onUpdateDoctor: (doctors) => {
            dispatch(updateDoctorRequest(doctors));
        },
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DoctorActionPage);