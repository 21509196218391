import * as Types from '../../constants/View/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchViewDVKTRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('dich-vu-ky-thuat','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchViewDVKT(res.data))
                }
            }
            
        })
    }
}
export const actFetchViewDVKT = (dichvukythuats) => {
    return {
        type: Types.FETCH_DVKT,
        dichvukythuats
    }
}

