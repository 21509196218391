import * as Types from '../../constants/DSBNPhongKham/ActionTypes';
var initialState = [];
const dsbnphongkhams = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_DSBN_PHONGKHAM:
            state = action.dsbnphongkhams;
            return [...state];

        default: return [...state];
    }
}
export default dsbnphongkhams;