import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addClinicRequest, eidtClinicRequest, updateClinicRequest } from '../../actions/Clinic/AcClinic';

function FormError(props) {
    if (props.isHidden) {return null;}
    return (
      <div className="form-warning">
          {props.errorMessage}
      </div>
    )
  }
  
  const validateInput = (checkingText) => {
      
      const regexp = /^\d{10,11}$/; 
      // regular expression - checking if phone number contains only 10 - 11 numbers
      
      if (regexp.exec(checkingText) !== null) {
              return {
                  isInputValid: true,
                  errorMessage: ''
              };
          } else {
              return {
                  isInputValid: false,
                  errorMessage: 'Số điện thoại phải có 10 - 11 chữ số.'
              };
          }
  }


  
class ClinicActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            ClinicCode: '',
            ClinicCode_BYT: '',
            ClinicName: '',
            ClinicAddress: '',
            ProvincialCode: '',
            DistrictCode: '',
            WardCode: '',
            DoctorName: '',
            Mobile:'',
            value: '',
            isInputValid: true,
            errorMessage: ''
           
        }
    
     
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditClinic(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingClinic !== this.props.EditingClinic) {

                this.props.EditingClinic.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        ClinicCode: menuItem.ClinicCode,
                        ClinicCode_BYT: menuItem.ClinicCode_BYT,
                        ClinicName: menuItem.ClinicName,
                        ClinicAddress: menuItem.ClinicAddress,
                        ProvincialCode: menuItem.ProvincialCode,
                        DistrictCode: menuItem.DistrictCode,
                        WardCode: menuItem.WardCode,
                        DoctorName: menuItem.DoctorName,
                        Mobile: menuItem.Mobile
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, ClinicCode, ClinicCode_BYT, ClinicName, ClinicAddress, ProvincialCode, DistrictCode, WardCode,
            DoctorName, Mobile } = this.state;
       // var { history } = this.props;

        e.preventDefault();
      
        if (id) {
            var clinics = {
                RowID: id,
                ClinicCode: ClinicCode,
                ClinicCode_BYT: ClinicCode_BYT,
                ClinicName: ClinicName,
                ClinicAddress: ClinicAddress,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                DoctorName: DoctorName,
                Mobile: Mobile
            }
            this.props.onUpdateClinic(clinics);
            

        } else {
        
            var clinic = {
                RowID: Math.floor(Math.random() * 10000000000),
                ClinicCode: ClinicCode,
                ClinicCode_BYT: ClinicCode_BYT,
                ClinicName: ClinicName,
                ClinicAddress: ClinicAddress,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                DoctorName: DoctorName,
                Mobile: Mobile
            }
           
            this.props.onAddClinic(clinic);
            
        }

    }
    ///Hàm kiểm tra

    handleInput = event => {
        const { value } = event.target;
        this.setState({value});
      }  
      
      handleInputValidation = event => {
        const { isInputValid, errorMessage } = validateInput(this.state.Mobile);
        this.setState({
          isInputValid: isInputValid,
          errorMessage: errorMessage
        })
        
      }


    render() {
        var { ClinicCode, ClinicCode_BYT, ClinicName, ClinicAddress, ProvincialCode, DistrictCode, WardCode,
            DoctorName, Mobile } = this.state;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Phòng khám</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã Phòng khám</label>
                                                <input type="text"
                                                    name="ClinicCode"
                                                    className="form-control"
                                                    value={ClinicCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã Phong khám BYT</label>
                                                <input type="text"
                                                    name="ClinicCode_BYT"
                                                    className="form-control"
                                                    value={ClinicCode_BYT}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên Phòng khám</label>
                                                <input type="text"
                                                    name="ClinicName"
                                                    className="form-control"
                                                    value={ClinicName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên Bác sĩ</label>
                                                <input type="text"
                                                    name="DoctorName"
                                                    className="form-control"
                                                    value={DoctorName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã tỉnh</label>
                                                <input type="text"
                                                    name="ProvincialCode"
                                                    className="form-control"
                                                    value={ProvincialCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã quận</label>
                                                <input type="text"
                                                    name="DistrictCode"
                                                    className="form-control"
                                                    value={DistrictCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã phường</label>
                                                <input type="text"
                                                    name="WardCode"
                                                    className="form-control"
                                                    value={WardCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Điện thoại</label>
                                                <input type="number"
                                                    name="Mobile"
                                                    className="form-control"
                                                    value={Mobile}
                                                    onChange={this.onChange}
                                                    onBlur={this.handleInputValidation}
                                                    required
                                                />
                                                 
       <FormError 
              isHidden={this.state.isInputValid} 
              errorMessage={this.state.errorMessage} />


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Địa chỉ</label>
                                                <input type="text"
                                                    name="ClinicAddress"
                                                    className="form-control"
                                                    value={ClinicAddress}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/clinic" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        EditingClinic: state.EditingClinic
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddClinic: (clinics) => {
            dispatch(addClinicRequest(clinics));
        },
        onEditClinic: (id) => {
            dispatch(eidtClinicRequest(id));
        },
        onUpdateClinic: (clinics) => {
            dispatch(updateClinicRequest(clinics));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(ClinicActionPage);