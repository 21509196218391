import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css';
import { getProfileFetch } from '../../actions/AcLogin';
import { eidtHoiDapRequest, updateHoiDapRequest } from '../../actions/HoiDap/AcHoiDap';

class AboutsActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            TieuDe: '',
            NoteHoi: '',
            ChuyenKhoaID: '',
            ClinicCode: '',
            ChuyenKhoaName: '',
            ClinicName: '',
            TraLoi:''

        }
    }

    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditHoiDap(id);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.itemEditingHoiDap !== this.props.itemEditingHoiDap) {

                this.props.itemEditingHoiDap.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        TieuDe: menuItem.TieuDe,
                        NoteHoi: menuItem.NoteHoi,
                        ChuyenKhoaID: menuItem.ChuyenKhoaID,
                        ClinicCode: menuItem.ClinicCode,
                        ChuyenKhoaName: menuItem.ChuyenKhoaName,
                        ClinicName: menuItem.ClinicName

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id,TraLoi } = this.state;
        e.preventDefault();
        if (id) {
            var arrayHoiDap = {
                RowID: Math.floor(Math.random() * 10000000000),
                NoteDap: TraLoi,
                HoiID: id,
                UserID: this.props.payload.currentUser.UserID,
            }
            this.props.onUpdateHoiDap(arrayHoiDap);
        }

    }
    render() {
        var { TieuDe, NoteHoi,  ChuyenKhoaName, ClinicName, TraLoi } = this.state;
        console.log(this.props.payload)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Giới thiệu</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label >Tiêu đề</label>
                                                <input type="text" disabled="disabled"
                                                    name="TieuDe"
                                                    className="form-control"
                                                    value={TieuDe}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label >Chuyên khoa</label>
                                                <input type="text" disabled="disabled"
                                                    name="ChuyenKhoaName"
                                                    className="form-control"
                                                    value={ChuyenKhoaName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <input type="text" disabled="disabled"
                                                    name="ClinicName"
                                                    className="form-control"
                                                    value={ClinicName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Nội dung</label>
                                        <textarea className="form-control" disabled="disabled"
                                            name='NoteHoi'
                                            value={NoteHoi}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Trả lời</label>
                                        <textarea className="form-control"
                                            name='TraLoi'
                                            value={TraLoi}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/hoi-dap" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        itemEditingHoiDap: state.itemEditingHoiDap,
        payload: state.payload,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
        onEditHoiDap: (id) => {
            dispatch(eidtHoiDapRequest(id));
        },
        onUpdateHoiDap: (hoidaps) => {
            dispatch(updateHoiDapRequest(hoidaps));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(AboutsActionPage);