
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class DocImageItem extends Component {
    onDelete = (id) => {
        if (window.confirm('Bạn có muốn xóa không ?')) { 
            this.props.onDelete(id);
        }
    }

    render() {
        var { docimage } = this.props;
        return (
            <tr>
                <th>{docimage.PatientReceiveID}</th>
                <td>{docimage.ClinicCode}</td>
                <td>{docimage.PatientCode}</td>
                <td>{docimage.Note}</td>
               
                <td>{docimage.STT}</td>
                <td>
                    <div className="btnCapNhatList">
                        <button type="button" onClick={() => this.onDelete(docimage.ClinicCode)} className="btn btn-block bg-gradient-primary btn-xs"><i className="fas fa-trash-alt"></i> Xóa</button>
                        <Link to={`/document-image/${docimage.ClinicCode}/edit`} type="button" className=" btnEdit btn btn-block bg-gradient-success btn-xs"><i className="far fa-edit"></i> Sửa</Link>
                    </div>
                </td>
            </tr>

        );
    }
}
/*
 <td>
                  <img src={`${url}${docimage.ImageUrl}`} className="ImgUrlList" alt="User" />
                </td>
*/

export default DocImageItem;

