import axios from 'axios';
import * as Config from '../constants/Config';
export default function UrlApi(endpoint, method, body, token){
    return axios({
        method: method,
        url: `${Config.API_URL}/${endpoint}`,
        data: body,
        headers:token
      }).catch(err=>{
          window.location.href = './';
      });
}
/*
export default function phongkhamApi(endpoint, method='GET', body){
    return axios({
        method: method,
        url: `${Config.API_URL}/${endpoint}`,
        data: body
      }).catch(err=>{
          console.log(err);
      });
}*/