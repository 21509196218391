import * as Types from '../../constants/PatientReceive/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchPatientReceiveRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('patient-receive', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchPatientReceive(res.data));
                }
            })
        }
    }
 
}
export const actFetchPatientReceive = (patientreceives) => {
    return {
        type: Types.FETCH_PATIENT_RECEIVE,
        patientreceives
    }
}

export const deletePatientReceiveRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-patient-receive/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deletePatientReceive(id));
        })
    }
}
export const deletePatientReceive = (id) => {
    return {
        type: Types.DELETE_PATIENT_RECEIVE,
        id
    }
}

export const addPatientReceiveRequest = (patientreceives) => {
    return (dispatch) => {
        return UrlApi('add-patient-receive', 'POST', patientreceives, authHeader()).then(res => {
            dispatch(addPatientReceive(res.data));
        })
    }
}

export const addPatientReceive = (patientreceives) => {
    return {
        type: Types.ADD_PATIENT_RECEIVE,
        patientreceives
    }
}


export const eidtPatientReceiveRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`patient-receive/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editPatientReceive(res.data));
            }

        })
    }
}
export const editPatientReceive = (patientreceives) => {
    return {
        type: Types.EDIT_PATIENT_RECEIVE,
        patientreceives
    }
}
export const updatePatientReceiveRequest = (patientreceives) => {
    return (dispatch) => {
        return UrlApi(`update-patient-receive/${patientreceives.id}`, 'PUT', patientreceives, authHeader()).then(res => {
            dispatch(updatePatientReceive(res.data));

        })
    }
}
export const updatePatientReceive = (patientreceives) => {
    return {
        type: Types.UPDATE_PATIENT_RECEIVE,
        patientreceives
    }
}


export const actFetchPatientReceiveCateRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('patient-receive-cate', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchPatientReceiveCate(res.data));
                }
            })
        }
    }
 
}
export const actFetchPatientReceiveCate = (patientreceives) => {
    return {
        type: Types.FETCH_PATIENT_RECEIVE_CATE,
        patientreceives
    }
}

