import React, { Component } from "react";
import { connect } from 'react-redux';
import { actFetchCatePhongKhamRequest } from '../../actions/DSBNPhongKham/AcDSBNPhongKham';

class SearchControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      categoryID: '',
      showSearchInfo: false
    };
  }
  componentDidMount() {
    this.props.getCatePhongKham();
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.keyword) {
      this.setState({
        keyword: nextProps.keyword,
        showSearchInfo: true
      });
    }
  }*/
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.keyword && nextProps.categoryID) {
      this.setState({
        keyword: nextProps.keyword,
        categoryID: nextProps.categoryID,
        showSearchInfo: true
      });
    }

  }


  onHandleChange = event => {
    var target = event.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      [name]: value
    });
  };

  onSearch = e => {
    e.preventDefault();
    this.props.onSearch(this.state.categoryID, this.state.keyword);
  };

  onClearSearch = () => {
    this.props.onSearch(0,'');
    this.setState({
      keyword: "",
      categoryID: 0,
      showSearchInfo: false
    });
  };
  categoryPhongKham() {
    if (this.props.catephongkhams) {
      return (
        this.props.catephongkhams.map((cate, index) => {
          return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
        })
      )
    }
  }


  render() {
    return (
      <div className="col-xs-12 box_search">
        <form onSubmit={this.onSearch}>
          <div className="search_wrp mb-15">
            {this.props.catephongkhams.length > 1 ?
              <div className="row">
                <div className="col-md-4">
                  <select value={this.state.categoryID} className="form-control" onChange={this.onHandleChange} name="categoryID" >
                    <option value="0">Chọn</option>
                    {this.categoryPhongKham()}
                  </select>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="keyword"
                    className="form-control"
                    placeholder="Nhập tên, Điện thoại..."
                    value={this.state.keyword}
                    onChange={this.onHandleChange}
                  />
                </div>
                <div className="col-md-4">
                  <div className="input-group">

                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.onSearch}
                      >
                        <i className="fa fa-search" />
                        Tìm
                      </button>
                      <button
                        className="btn btn-primary clearBtn"
                        type="button"
                        onClick={this.onClearSearch}
                      >
                        <i className="fa fa-times"></i>
                         Clear
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-8">
                  <input
                    type="text"
                    name="keyword"
                    className="form-control"
                    placeholder="Nhập tên, Điện thoại..."
                    value={this.state.keyword}
                    onChange={this.onHandleChange}
                  />
                </div>
                <div className="col-md-4">
                  <div className="input-group">

                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.onSearch}
                      >
                        <i className="fa fa-search" />
                        Tìm
                      </button>
                      <button
                        className="btn btn-primary clearBtn"
                        type="button"
                        onClick={this.onClearSearch}
                      >
                        <i className="fa fa-times"></i>
                         Clear
                      </button>
                    </span>
                  </div>
                  
                </div>
              </div>
            }




          </div>

        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    catephongkhams: state.catephongkhams
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    getCatePhongKham: () => {
      dispatch(actFetchCatePhongKhamRequest());
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchControl);
