import * as Types from '../../constants/Location/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const TinhThanhRequest = () => {

    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = '/';
    } else {
        return (dispatch) => {
            return UrlApi('category-tinh-thanh', 'POST', null, authHeader()).then(res => {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(TinhThanh(res.data));
                }
            })
        }
    }
}

export const TinhThanh = (tinhthanh) => {
    return {
        type: Types.FETCH_TINHTHANH,
        tinhthanh
    }
}


export const QuanHuyenRequest = (id) => {
    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = '/';
    } else {
        return (dispatch) => {
            return UrlApi('category-quan-huyen', 'POST',null, authHeader()).then(res => {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(QuanHuyen(res.data));
                }
            })
        }
    }

}

export const QuanHuyen = (quanhuyen) => {
    return {
        type: Types.FETCH_QUANHUYEN,
        quanhuyen
    }
}

export const PhuongXaRequest = () => {
    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = '/';
    } else {
        return (dispatch) => {
            return UrlApi('category-phuong-xa', 'POST', null, authHeader()).then(res => {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(PhuongXa(res.data));
                }
            })
        }
    }
}

export const PhuongXa = (phuongxa) => {
    return {
        type: Types.FETCH_PHUONGXA,
        phuongxa
    }
}


export const ChuyenKhoaRequest = (ClinicCode) => {
    const token = localStorage.getItem('token');
    
    if (token === null) {
        window.location.href = '/';
    } else {
        return (dispatch) => {
            return UrlApi('category-chuyen-khoa', 'POST', null, authHeader()).then(res => {
                if (res.data === 'TOKEN_KHONG_HOP_LE') {
                    localStorage.removeItem("token")
                    window.location.reload(true);
                } else {
                    dispatch(ChuyenKhoa(res.data));
                }
            })
        }
    }
}

export const ChuyenKhoa = (chuyenkhoa) => {
    return {
        type: Types.FETCH_CHUYENKHOA,
        chuyenkhoa
    }
}