import * as Types from '../../constants/View/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchViewCTThuocRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('chi-tiet-thuoc','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchViewCTThuoc(res.data))
                }
            }
            
        })
    }
}
export const actFetchViewCTThuoc = (chitietthuocs) => {
    return {
        type: Types.FETCH_VIEW_CTThuoc,
        chitietthuocs
    }
}

