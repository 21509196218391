import * as Types from '../../constants/MedicalRecord/ActionTypes';
var initialState = [];

var findIndex = (medicrecords, id) => {
    var result = -1;
    medicrecords.forEach((medicrecord, index) => {
        if (medicrecord.id === id) {
            result = index;
        }
    })
    return result;
}
const medicrecords = (state = initialState, action) => {
    var index = -1;
    var { id, medicrecords } = action;
    switch (action.type) {
        case Types.FETCH_MEDIC_RECORD:
            state = action.medicrecords;
            return [...state];
        case Types.DELETE_MEDIC_RECORD:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_MEDIC_RECORD:
            state.push(action.medicrecords);
            return [...state];

        case Types.UPDATE_MEDIC_RECORD:
            index = findIndex(state, medicrecords.id);
            state[index] = medicrecords;
            return [...state];
        case Types.FETCH_MEDIC_RECORD_CATE:
            state = action.medicrecords;
            return [...state];
        default: return [...state];
    }
}
export default medicrecords;