import * as Types from '../../constants/Notification/ActionTypes';
var initialState = [];

var findIndex = (notifications, id) => {
    var result = -1;
    notifications.forEach((notification, index) => {
        if (notification.id === id) {
            result = index;
        }
    })
    return result;
}
const notifications = (state = initialState, action) => {
    var index = -1;
    var { id, notifications } = action;
    switch (action.type) {
        case Types.FETCH_NOTIFI:
            state = action.notifications;
            return [...state];
        case Types.DELETE_NOTIFI:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_NOTIFI:
            state.push(action.notifications);
            return [...state];

        case Types.UPDATE_NOTIFI:
            index = findIndex(state, notifications.id);
            state[index] = notifications;
            return [...state];
        case Types.UPDATE_STATUS_NOTIFI:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
       
        
       

        default: return state;
    }
}
export default notifications;