import * as Types from '../../constants/Notification/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchNotifiRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('notification','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchNotifi(res.data))
                }
            }
            
        })
    }
}

export const actFetchNotifi = (notifications) => {
    return {
        type: Types.FETCH_NOTIFI,
        notifications
    }
}

export const deleteNotifiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-notification/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteNotifi(id));
        })
    }
}
export const deleteNotifi = (id) => {
    return {
        type: Types.DELETE_NOTIFI,
        id
    }
}

export const addNotifiRequest = (notifications) => {
    return (dispatch) => {
        return UrlApi('add-notification', 'POST', notifications, authHeader()).then(res => {
            dispatch(addNotifi(res.data));
            window.location.href = '/thong-bao';
        })
    }
}

export const addNotifi = (notifications) => {
    return {
        type: Types.ADD_NOTIFI,
        notifications
    }
}


export const eidtNotifiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`notification/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editNotifi(res.data));
            }

        })
    }
}


export const editNotifi = (notifications) => {
    return {
        type: Types.EDIT_NOTIFI,
        notifications
    }
}


export const updateNotifiRequest = (notifications) => {
    return (dispatch) => {
        return UrlApi(`update-notification/${notifications.id}`, 'PUT', notifications, authHeader()).then(res => {
            dispatch(updateNotifi(res.data));
            window.location.href = '/thong-bao';

        })
    }
}


export const updateNotifi = (notifications) => {
    return {
        type: Types.UPDATE_NOTIFI,
        notifications
    }
}

export const updateisLockedNotifiRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-notification/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedNotifi(id));

        })
    }
}
export const updateisLockedNotifi = (id) => {
    return {
        type: Types.UPDATE_STATUS_NOTIFI,
        id
    }
}


export const actFetchClinicNotifiequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('clinic-notification','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchClinicNotifi(res.data))
                }
            }
            
        })
    }
}


export const actFetchClinicNotifi = (clinicnotifi) => {
    return {
        type: Types.FETCH_CLINIC_NOTIFI,
        clinicnotifi
    }
}


