import * as Types from '../../constants/Location/ActionTypes';
var initialState = [];
const tinhthanh = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_TINHTHANH:
            state = action.tinhthanh;
            return [...state];
        default: return state;
    }
}
export default tinhthanh;