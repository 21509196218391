export const FETCH_DOCTORS ='FETCH_DOCTORS';
export const ADD_DOCTORS ='ADD_DOCTORS';
export const UPDATE_DOCTORS ='UPDATE_DOCTORS';
export const DELETE_DOCTORS ='DELETE_DOCTORS';
export const EDIT_DOCTORS ='EDIT_DOCTORS';
export const UPDATE_STATUS_DOCTORS ='UPDATE_STATUS_DOCTORS';
export const SEARCH_DOCTORS ='SEARCH_DOCTORS';
export const DATHEN_DOCTORS ='DATHEN_DOCTORS';


