import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router  } from 'react-router-dom';
import Header from './components/Header/Header';
import Left from './components/Left/Left';
import Footer from './components/Footer/Footer';
import routes  from './routes';
import LoginPage from './pages/Login/LoginPage';


import { connect } from 'react-redux';
import { getProfileFetch } from './actions/AcLogin';


class App extends Component {
  constructor(porps){
    super(porps);
    this.state={
      loggedIn:false
    }
  }
  componentDidMount(){
    const token = localStorage.getItem("token");
    if(token){
      this.setState({
        loggedIn:true
      });
      this.props.getProfileFetch();
    }
   
    
  }

  showContentMenus = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.main}
          />
        );
      })
    }
    return <Switch>{result} </Switch>
  }


  render() {
    if(this.state.loggedIn === false ){
      return <LoginPage />
    }
   // var classSet = this.props.payload.currentUser.UserType === 1? '': 'content-wrapper';
    return (
      <Router>
      <div className="wrapper">
        <Header />
        <Left/>
        <div className="content-wrapper">
            {this.showContentMenus(routes)}
        </div>
        <Footer/>
      </div>

    </Router>
    )

  }
}

const mapStateToProps = state => {
  return {
    payload: state.payload
  }
}
const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => {
    dispatch(getProfileFetch());
  },

})
export default connect(mapStateToProps, mapDispatchToProps)(App);
