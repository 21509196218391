import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = "";
var searchcateadmin = (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_CATEGORY:
      return action.categoryID;
    default:
      return state;
  }
};
export default searchcateadmin;