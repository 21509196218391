import * as Types from '../../constants/ServiceRadiologyEntry/ActionTypes';
var initialState = [];

var findIndex = (serviceradios, id) => {
    var result = -1;
    serviceradios.forEach((serviceradio, index) => {
        if (serviceradio.id === id) {
            result = index;
        }
    })
    return result;
}
const serviceradios = (state = initialState, action) => {
    var index = -1;
    var { id, serviceradios } = action;
    switch (action.type) {
        case Types.FETCH_SERVICE_RADIOLOGY_ENTRY:
            state = action.serviceradios;
            return [...state];
        case Types.DELETE_SERVICE_RADIOLOGY_ENTRY:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_SERVICE_RADIOLOGY_ENTRY:
            state.push(action.serviceradios);
            return [...state];

        case Types.UPDATE_SERVICE_RADIOLOGY_ENTRY:
            index = findIndex(state, serviceradios.id);
            state[index] = serviceradios;
            return [...state];
        
            
        default: return [...state];
    }
}
export default serviceradios;