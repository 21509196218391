import * as Types from '../../constants/ServiceLaboratoryEntry/ActionTypes';
var initialState = [];

var findIndex = (servicelaboratorys, id) => {
    var result = -1;
    servicelaboratorys.forEach((servicelaboratory, index) => {
        if (servicelaboratory.id === id) {
            result = index;
        }
    })
    return result;
}
const servicelaboratorys = (state = initialState, action) => {
    var index = -1;
    var { id, servicelaboratorys } = action;
    switch (action.type) {
        case Types.FETCH_SERVICE_LABORATORY_ENTRY:
            state = action.servicelaboratorys;
            return [...state];
        case Types.DELETE_SERVICE_LABORATORY_ENTRY:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_SERVICE_LABORATORY_ENTRY:
            state.push(action.servicelaboratorys);
            return [...state];

        case Types.UPDATE_SERVICE_LABORATORY_ENTRY:
            index = findIndex(state, servicelaboratorys.id);
            state[index] = servicelaboratorys;
            return [...state];
        
            
        default: return [...state];
    }
}
export default servicelaboratorys;