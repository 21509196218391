import React, { Component } from 'react';
class MedicRecordList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <div className="Tableauto">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="120">Mã TN</th>
                                            <th width="120">Mã PK</th>
                                            <th width="120">Mã BN</th>
                                            <th width="120">Mã HS</th>
                                            <th width="300">Lý do</th>
                                            <th width="200">Chuẩn đoán</th>
                                            <th width="120">Mã ICD10</th>
                                            <th width="120">Tên ICD10</th>
                                            <th width="120">Tên Bộ phận</th>
                                            <th width="120">Mã BS NV</th>
                                            <th width="120">Tên BS NV</th>
                                            <th width="120">Ngày Thực hiện</th>
                                            <th width="120">Ngày tái khám</th>
                                            <th width="120">Ghi chú</th>
                                            <th width="120">Mã đối tượng</th>
                                            <th width="120">KQ điều trị</th>
                                            <th width="120">Xữ trí KB</th>
                                            <th width="120">TT ra viện</th>
                                            <th width="120">Lời dặn/Ghi chú</th>
                                            <th width="120">Số ngày nghỉ</th>
                                            <th width="120">Ngày bd nghỉ</th>
                                            <th width="120">Ngày kết thúc</th>
                                            <th width="120">Mã DX</th>
                                            <th width="120">Mạch</th>
                                            <th width="120">Nhiệt độ</th>
                                            <th width="120">Huyết áp</th>
                                            <th width="120">Huyết áp 1</th>
                                            <th width="120">Cân nặng</th>
                                            <th width="120">Chiều cao</th>
                                            <th width="120">Hơi thở</th>
                                            <th width="120">BMI</th>
                                            <th width="120">Vòng ngực</th>
                                            <th width="120">SPO2</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default MedicRecordList;

