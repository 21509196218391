import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchControl from "./SearchControl";
import "react-datepicker/dist/react-datepicker.css";
import { showKetQuaXetNghiemRequest } from '../../actions/TraCuuKetQua/AcTraCuuKetQua';

import { APIURL } from '../../constants/urlIp';

class KetQuaXNActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IDMau: "",
            NgayKQ: '',
            clickmau: 0,
            showSearchInfo: false

        }
        this.handleChangeNgayKQ = this.handleChangeNgayKQ.bind(this);
    }
    handleChangeNgayKQ = NgayKQ => {
        this.setState({
            NgayKQ
        });
    };
    onHandleChange = event => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        this.setState({
            [name]: value
        });
    };
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        var { NgayKQ, IDMau, categoryID } = this.props;
        /* var { match } = this.props;
         if (match) {
             var id = match.params.id;
             this.props.onEditNews(id);
         }*/
        this.props.ShowThongTinUserbbb(NgayKQ, IDMau, categoryID);
    }
    onSearch = (NgayKQ, IDMau, categoryID) => {
        this.setState({ clickmau: 1 });
        this.props.ShowThongTinUserbbb(NgayKQ, IDMau, categoryID);

    };

    /* onSearch = () => {
        // console.log(NgayKQ,IDMau)
        //var ngay = this.state.NgayKQ? moment(this.state.NgayKQ).format('DD/MM/YYYY') :  moment(new Date()).format('DD/MM/YYYY') 
       var ngay = this.state.NgayKQ? this.state.NgayKQ : new Date();
       if(this.state.IDMau == ''){
         alert('ID Mẫu không được để trống!')
       }{
         fetch(`${Url.API_URL}/ket-qua-xet-nghiem/NgayKQ=${ngay}&IDMau=${this.state.IDMau}`,authHeader() )
           .then(response => response.json())
           .then(responseData => {
            
 
           })
           .catch(error => {
             console.log('Error fetching and parsing data', error);
           });
       }
         
       }*/





    render() {
        var { tracuuketquaxetnghiem } = this.props;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Tra cứu kết quả xét nghiệm</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <SearchControl
                                    onSearch={this.onSearch}
                                    NgayKQ={this.props.NgayKQ}
                                    IDMau={this.props.IDMau}
                                    categoryID={this.props.categoryID}
                                />
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Họ Tên</th>
                                            <th>Phòng khám</th>
                                            <th>Ngày kết quả</th>
                                            <th>ID Mẫu</th>
                                            <th>File</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showketqua(tracuuketquaxetnghiem)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
    showketqua(tracuuketquaxetnghiem) {
        var result = null;
        if (this.state.clickmau === 0) {
            return (<tr><td colSpan="5"><div className='error_KQXN'>Nhập ngày kết quả và ID mẫu vào</div></td></tr>)
        } else {
            if (tracuuketquaxetnghiem.length > 0) {
                result = tracuuketquaxetnghiem.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.PatientName}</td>
                            <td>{item.ClinicName}</td>
                            <td>{item.PostingDate}</td>
                            <td>{item.IDMau}</td>
                            <td align='center'>
                                {
                                    item.UrlFile === null ? <span><i className="fa fa-eye-slash" ></i></span> :
                                        <span className="onclickPdf" onClick={() => window.open(`${APIURL}/${item.UrlFile}`, "_blank")}> <i className="fa fa-eye" ></i></span>
                                }

                            </td>

                        </tr>
                    )

                })
            } else {
                return (<tr><td colSpan="5"><div className='error_KQXN'>Chưa có kết quả</div></td></tr>)
            }
        }
        return result;
    }


}


const mapStateToProps = state => {
    return {
        tracuuketquaxetnghiem: state.tracuuketquaxetnghiem
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        ShowThongTinUserbbb: (NgayKQ, IDMau, categoryID) => {
            dispatch(showKetQuaXetNghiemRequest(NgayKQ, IDMau, categoryID));
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(KetQuaXNActionPage);