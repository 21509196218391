import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css';
import { getProfileFetch } from '../../actions/AcLogin';
import { eidtTraLoiRequest, updateTraLoiRequest } from '../../actions/TraLoi/AcTraLoi';

class TraLoiActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            TieuDe: '',
            NoteHoi: '',
            NoteDap: ''

        }
    }

    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditTraLoi(id);
            this.props.getProfileFetch();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingTraLoi !== this.props.EditingTraLoi) {

                this.props.EditingTraLoi.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        TieuDe: menuItem.TieuDe,
                        NoteHoi: menuItem.NoteHoi,
                        NoteDap: menuItem.NoteDap
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, NoteDap } = this.state;
        e.preventDefault();
        if (id) {
            var arrayTraLoi = {
                RowID: id,
                NoteDap: NoteDap,
                UserID: this.props.payload.currentUser.UserID
            }
            this.props.onUpdateTraLoi(arrayTraLoi);
        }
        window.location.href = '/tra-loi';

    }
    render() {
        var { TieuDe, NoteHoi, NoteDap } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Giới thiệu</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="form-group">
                                        <label >Tiêu đề</label>
                                        <input type="text" disabled="disabled"
                                            name="TieuDe"
                                            className="form-control"
                                            value={TieuDe}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Nội dung</label>
                                        <textarea className="form-control" disabled="disabled"
                                            name='NoteHoi'
                                            value={NoteHoi}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Trả lời</label>
                                        <textarea className="form-control"
                                            name='NoteDap'
                                            value={NoteDap}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/hoi-dap" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        EditingTraLoi: state.EditingTraLoi,
        payload: state.payload,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        getProfileFetch: () => {
            dispatch(getProfileFetch());
        },
        onEditTraLoi: (id) => {
            dispatch(eidtTraLoiRequest(id));
        },
        onUpdateTraLoi: (tralois) => {
            dispatch(updateTraLoiRequest(tralois));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(TraLoiActionPage);