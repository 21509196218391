import * as Types from '../../constants/DatHen/ActionTypes';
var initialState = [];

var findIndex = (dathens, id) => {
    var result = -1;
    dathens.forEach((dathen, index) => {
        if (dathen.id === id) {
            result = index;
        }
    })
    return result;
}
const dathens = (state = initialState, action) => {
    var index = -1;
    var { id, dathens } = action;
    switch (action.type) {
        case Types.FETCH_DATHEN:
            state = action.dathens;
            return [...state];
        case Types.DELETE_DATHEN:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_DATHEN:
            state.push(action.dathens);
            return [...state];
        case Types.UPDATE_DATHEN:
            index = findIndex(state, dathens.id);
            state[index] = dathens;
            return [...state];
        case Types.UPDATE_STATUS_DATHEN:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
        
        default: return [...state];
    }
}
export default dathens;