import * as Types from '../../constants/View/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';
/*
export const actFetchViewLSKBRequest = (mobile) => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi(`show-lich-su-kham-benh/${mobile}`, 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchViewLSKB(res.data));
                }
            })
        }
    }
}
*/
export const actFetchViewLSKBRequest = () => {
    return (dispatch)=>{
        let token = JSON.parse(localStorage.getItem('token'));
        return UrlApi('show-lich-su-kham-benh','POST', { "refreshToken": token}, authHeader() ).then(res =>{
            if(res === undefined){
                localStorage.removeItem("token")
                window.location.reload(true);
            }else{
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchViewLSKB(res.data))
                }
            }
            
        })
    }
}
export const actFetchViewLSKB = (lichsukhambenhs) => {
    return {
        type: Types.FETCH_VIEW_LSKB,
        lichsukhambenhs
    }
}

