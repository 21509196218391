import * as Types from '../../constants/Slogan/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchSloganRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('slogan', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchSlogan(res.data));
                }
            })
        }
    }
 
}
export const actFetchSlogan = (slogans) => {
    return {
        type: Types.FETCH_SLOGAN,
        slogans
    }
}

export const deleteSloganRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-slogan/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteSlogan(id));
        })
    }
}
export const deleteSlogan = (id) => {
    return {
        type: Types.DELETE_SLOGAN,
        id
    }
}



export const addSloganRequest = (slogans) => {
    return (dispatch) => {
        return UrlApi('add-slogan', 'POST', slogans, authHeader()).then(res => {
            dispatch(addSlogan(res.data));
            window.location.href = '/abouts';
        })
    }
}

export const addSlogan = (slogans) => {
    return {
        type: Types.ADD_SLOGAN,
        slogans
    }
}


export const eidtSloganRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`slogan/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editSlogan(res.data));
            }

        })
    }
}


export const editSlogan = (slogans) => {
    return {
        type: Types.EDIT_SLOGAN,
        slogans
    }
}
export const updateSloganRequest = (slogans) => {
    return (dispatch) => {
        return UrlApi(`update-slogan/${slogans.id}`, 'PUT', slogans, authHeader()).then(res => {
            dispatch(updateSlogan(res.data));
            window.location.href = '/slogan';

        })
    }
}
export const updateSlogan = (slogans) => {
    return {
        type: Types.UPDATE_SLOGAN,
        slogans
    }
}

export const updateisLockedSloganRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-slogan/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedSlogan(id));

        })
    }
}
export const updateisLockedSlogan = (id) => {
    return {
        type: Types.UPDATE_STATUS_SLOGAN,
        id
    }
}






