import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { addServiceLaboratoryEntryRequest, eidtServiceLaboratoryEntryRequest, updateServiceLaboratoryEntryRequest } from '../../actions/ServiceLaboratoryEntry/AcServiceLaboratoryEntry';

class ServiceLaboratoryEntryActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            SuggestedReceiptID:'',
            PatientReceiveID:'',
            ClinicCode:'',
            PatientCode:'',
            EmployeeDoctorOrder_Code:'',
            EmployeeDoctorOrder_Name:'',
            DiagnosisCustom:'',
            STT_Mau:'',
            Note:'',
            PostingDate_GetOrder:'',
            PostingDate_Result:'',
            Result:'',
            EmployeeDoctorDone_Code:'',
            EmployeeDoctorDone_Name:'',
            DepartmentNameOrder:'',
            ServiceName:'',
            ServiceCode:'',
            ServiceCategoryName:''
        }
        this.handleChangePostingDate_GetOrder = this.handleChangePostingDate_GetOrder.bind(this);
        this.handleChangePostingDate_Result = this.handleChangePostingDate_Result.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditServiceLaboratoryEntry(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingServiceLaboratoryEntry !== this.props.EditingServiceLaboratoryEntry) {

                this.props.EditingServiceLaboratoryEntry.map((menuItem) => (
                    this.setState({
                        id: menuItem.SuggestedReceiptID,

                        EmployeeDoctorOrder_Code:menuItem.EmployeeDoctorOrder_Code,
                        EmployeeDoctorOrder_Name:menuItem.EmployeeDoctorOrder_Name,
                        DiagnosisCustom:menuItem.DiagnosisCustom,
                        STT_Mau:menuItem.STT_Mau,
                        Note:menuItem.Note,
                        PostingDate_GetOrder:new Date(Date.parse(moment(menuItem.datePostingDate_Get).format('YYYY-MM-DD') + ' ' + menuItem.timePostingDate_Get.substring(11, 19))),
                        PostingDate_Result:new Date(Date.parse(moment(menuItem.datePostingDate_Result).format('YYYY-MM-DD') + ' ' + menuItem.timePostingDate_Result.substring(11, 19))),
                        Result:menuItem.Result,
                        EmployeeDoctorDone_Code:menuItem.EmployeeDoctorDone_Code,
                        EmployeeDoctorDone_Name:menuItem.EmployeeDoctorDone_Name,
                        DepartmentNameOrder:menuItem.DepartmentNameOrder,
                        ServiceName:menuItem.ServiceName,
                        ServiceCode:menuItem.ServiceCode,
                        ServiceCategoryName:menuItem.ServiceCategoryName

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, SuggestedReceiptID,PatientReceiveID,ClinicCode,PatientCode,EmployeeDoctorOrder_Code,EmployeeDoctorOrder_Name,DiagnosisCustom,
            STT_Mau,Note,PostingDate_GetOrder,PostingDate_Result,Result,EmployeeDoctorDone_Code,EmployeeDoctorDone_Name,DepartmentNameOrder,
            ServiceName,ServiceCode,ServiceCategoryName } = this.state;
        //var { history } = this.props;

        const PostingDate_GetOrderID = moment(PostingDate_GetOrder).format('YYYYMMDDHHmmss');
        const PostingDate_ResultID = moment(PostingDate_Result).format('YYYYMMDDHHmmss');

        e.preventDefault();

        if (id) {
            var ServiceEntrys = {
                SuggestedReceiptID:id,
                EmployeeDoctorOrder_Code:EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name:EmployeeDoctorOrder_Name,
                DiagnosisCustom:DiagnosisCustom,
                STT_Mau:STT_Mau,
                Note:Note,
                PostingDate_GetOrder:PostingDate_GetOrderID,
                PostingDate_Result:PostingDate_ResultID,
                Result:Result,
                EmployeeDoctorDone_Code:EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name:EmployeeDoctorDone_Name,
                DepartmentNameOrder:DepartmentNameOrder,
                ServiceName:ServiceName,
                ServiceCode:ServiceCode,
                ServiceCategoryName:ServiceCategoryName

            }
            this.props.onUpdateServiceLaboratoryEntry(ServiceEntrys);
           // history.goBack(window.location.reload(true));

        } else {
            var ServiceEntry = {

                SuggestedReceiptID:SuggestedReceiptID,
                PatientReceiveID:PatientReceiveID,
                ClinicCode:ClinicCode,
                PatientCode:PatientCode,
                EmployeeDoctorOrder_Code:EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name:EmployeeDoctorOrder_Name,
                DiagnosisCustom:DiagnosisCustom,
                STT_Mau:STT_Mau,
                Note:Note,
                PostingDate_GetOrder:PostingDate_GetOrder ? moment(PostingDate_GetOrder).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                PostingDate_Result:PostingDate_Result ? moment(PostingDate_Result).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                Result:Result,
                EmployeeDoctorDone_Code:EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name:EmployeeDoctorDone_Name,
                DepartmentNameOrder:DepartmentNameOrder,
                ServiceName:ServiceName,
                ServiceCode:ServiceCode,
                ServiceCategoryName:ServiceCategoryName
            }
            this.props.onAddServiceLaboratoryEntry(ServiceEntry);
            //history.goBack(window.location.reload(true));
        }
        window.location.href = '/service-laboratory-entry';

    }
    handleChangePostingDate_GetOrder = PostingDate_GetOrder => {
        this.setState({
            PostingDate_GetOrder
        });
    };
    handleChangePostingDate_Result = PostingDate_Result => {
        this.setState({
            PostingDate_Result
        });
    };

    render() {
        var { SuggestedReceiptID,PatientReceiveID,ClinicCode,PatientCode,EmployeeDoctorOrder_Code,EmployeeDoctorOrder_Name,DiagnosisCustom,
            STT_Mau,Note,PostingDate_GetOrder,PostingDate_Result,Result,EmployeeDoctorDone_Code,EmployeeDoctorDone_Name,DepartmentNameOrder,
            ServiceName,ServiceCode,ServiceCategoryName } = this.state;


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Trả kết quả xét nghiệm</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >ID đề xuất</label>
                                                <input type="text"
                                                    name="SuggestedReceiptID"
                                                    className="form-control"
                                                    value={SuggestedReceiptID}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >ID BN</label>
                                                <input type="text"
                                                    name="PatientReceiveID"
                                                    className="form-control"
                                                    value={PatientReceiveID}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã PK</label>
                                                <input type="text"
                                                    name="ClinicCode"
                                                    className="form-control"
                                                    value={ClinicCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BN</label>
                                                <input type="text"
                                                    name="PatientCode"
                                                    className="form-control"
                                                    value={PatientCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Chuẩn đoán ban đầu</label>
                                        <input type="text"
                                            name="DiagnosisCustom"
                                            className="form-control"
                                            value={DiagnosisCustom}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Kết quả</label>
                                        <input type="text"
                                            name="Result"
                                            className="form-control"
                                            value={Result}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Ghi chú</label>
                                        <textarea type="text"
                                            name="Note"
                                            className="form-control"
                                            value={Note}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã BS BN KT</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên BS BN KT</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >STT</label>
                                                <input type="text"
                                                    name="STT_Mau"
                                                    className="form-control"
                                                    value={STT_Mau}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày lấy mẫu</label>
                                                <DatePicker name="PostingDate_GetOrder" value={PostingDate_GetOrder ? PostingDate_GetOrder : new Date()} onChange={this.handleChangePostingDate_GetOrder}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày trả KQ</label>
                                                <DatePicker name="PostingDate_Result" value={PostingDate_Result ? PostingDate_Result : new Date()} onChange={this.handleChangePostingDate_Result}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Khoa phòng</label>
                                                <input type="text"
                                                    name="DepartmentNameOrder"
                                                    className="form-control"
                                                    value={DepartmentNameOrder}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Tên DV</label>
                                                <input type="text"
                                                    name="ServiceName"
                                                    className="form-control"
                                                    value={ServiceName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã DV</label>
                                                <input type="text"
                                                    name="ServiceCode"
                                                    className="form-control"
                                                    value={ServiceCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Loại dịch vụ</label>
                                                <input type="text"
                                                    name="ServiceCategoryName"
                                                    className="form-control"
                                                    value={ServiceCategoryName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                       
                                 
                                    </div>
                                    
                                   
                                    <div className="btnAdd">
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/service-laboratory-entry" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        EditingServiceLaboratoryEntry: state.EditingServiceLaboratoryEntry
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddServiceLaboratoryEntry: (servicelaboratorys) => {
            dispatch(addServiceLaboratoryEntryRequest(servicelaboratorys));
        },
        onEditServiceLaboratoryEntry: (id) => {
            dispatch(eidtServiceLaboratoryEntryRequest(id));
        },
        onUpdateServiceLaboratoryEntry: (servicelaboratorys) => {
            dispatch(updateServiceLaboratoryEntryRequest(servicelaboratorys));
        },
     



    }

}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceLaboratoryEntryActionPage);