import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { addAboutsRequest, eidtAboutsRequest, updateAboutsRequest } from '../../actions/Abouts/AcAbouts';

class AboutsActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            AboutNote: '',
            value: '',
            isInputValid: true,
            errorMessage: ''

        }
    }
    onChangeEditor = (e) => {
        this.setState({
            AboutNote: e
        })
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditAbout(id);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.itemEditingAbouts !== this.props.itemEditingAbouts) {

                this.props.itemEditingAbouts.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        AboutNote: menuItem.AboutNote
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, AboutNote } = this.state;
        // var { history } = this.props;
        e.preventDefault();
        if (id) {
            var arrabout = {
                RowID: id,
                AboutNote: AboutNote,
            }
            this.props.onUpdateAbout(arrabout);
        } else {

            var arrabouts = {
                RowID: Math.floor(Math.random() * 10000000000),
                AboutNote: AboutNote,
            }
            this.props.onAddAbout(arrabouts);
           
        }
       

    }
    render() {
        var { AboutNote } = this.state;
        const defaultFonts = [
            "Arial",
            "Comic Sans MS",
            "Courier New",
            "Impact",
            "Georgia",
            "Tahoma",
            "Trebuchet MS",
            "Verdana"
        ];
        const sortedFontOptions = [
            "Logical",
            "Salesforce Sans",
            "Garamond",
            "Sans-Serif",
            "Serif",
            "Times New Roman",
            "Helvetica",
            ...defaultFonts
        ].sort();

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Giới thiệu</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="form-group" id="dynamic_field">
                                        <label >Nội dung</label>

                                        <SunEditor
                                            plugin=""
                                            setContents={AboutNote}
                                            onChange={this.onChangeEditor}
                                            setOptions={{
                                                buttonList: [
                                                    ["undo", "redo"],
                                                    ["font", "fontSize"],
                                                    // ['paragraphStyle', 'blockquote'],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript"
                                                    ],
                                                    ["fontColor", "hiliteColor"],
                                                    ["align", "list", "lineHeight"],
                                                    ["outdent", "indent"],

                                                    ["table", "horizontalRule", "link", "image", "video"],
                                                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                    // ["fullScreen", "showBlocks", "codeView"],
                                                    ["preview", "print","save"],
                                                    ["removeFormat"]

                                                    // ['save', 'template'],
                                                    // '/', Line break
                                                ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                defaultTag: "div",
                                                minHeight: "300px",
                                                showPathLabel: false,
                                                font: sortedFontOptions
                                            }}
                                        />

                                        <textarea
                                            name='AboutNote'
                                            disabled
                                            value={JSON.stringify(AboutNote, null, 2)}
                                            style={{ display: 'none' }}
                                        />

                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/abouts" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        itemEditingAbouts: state.itemEditingAbouts
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddAbout: (abouts) => {
            dispatch(addAboutsRequest(abouts));
        },
        onEditAbout: (id) => {
            dispatch(eidtAboutsRequest(id));
        },
        onUpdateAbout: (abouts) => {
            dispatch(updateAboutsRequest(abouts));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(AboutsActionPage);