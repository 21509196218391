import React, { Component } from 'react';
class DSBNPhongKhamList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
							<div className ="tableMain">
                             <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Họ tên</th>
                                            <th >Giới tính</th>
                                            <th>Năm sinh</th>
                                            <th>SĐT</th>
                                            <th >Địa chỉ</th>
                                            <th >Phòng khám</th>
                                            <th width="100" >Xem</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
							</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default DSBNPhongKhamList;

