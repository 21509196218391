
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getProfileFetch } from './../../actions/AcLogin';
class Footer extends Component {
    componentDidMount(){
        this.props.getProfileFetch();
    }
    render() {
       // var classSet = this.props.payload.currentUser.UserType === 1? 'main-footer-u': 'main-footer';
        return (
            <div>
                <footer className="main-footer">
                    <strong> eMed Hồ Sơ Khám Bệnh Điện Tử</strong> - Thiết kế bởi Winsoft Việt Nam <span className='hotlinecty'></span>
                     <div className="float-right d-none d-sm-inline-block" onClick={()=> window.open('https://winsoft.com.vn/', "_blank")}> Winsoft.com.vn </div>
                </footer>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        payload: state.payload
      }
}

const mapDispatchToProps = dispatch => ({
    getProfileFetch: () => {
        dispatch(getProfileFetch());
      },
})
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

