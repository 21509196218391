import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { addPatientsRequest, eidtPatientsRequest, updatePatientsRequest } from '../../actions/Patients/AcPatients';
import { actFetchCateClinicRequest } from '../../actions/Clinic/AcClinic';



class PatientsActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            ClinicCode: '',
            PatientCode: '',
            EThnicName: '',
            CareerName: '',
            NationalityName: '',
            ProvincialCode: '',
            DistrictCode: '',
            WardCode: '',
            PatientAddress: '',
            PatientName: '',
            PatientGender: '',
            PatientBirthDay: '',
            PatientAge: '',
            PatientMobile: '',
            PatientEmail: '',
            MedicalHistory: '',
            Allergy: ''
        }
        this.handleChangeBirthDay = this.handleChangeBirthDay.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditPatients(id);
        }
        this.props.fetchAllCategoryClinic();
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingPatients !== this.props.EditingPatients) {

                this.props.EditingPatients.map((menuItem) => (
                    this.setState({
                        id: menuItem.PatientCode,
                        ClinicCode: menuItem.ClinicCode,
                        PatientCode: menuItem.PatientCode,
                        EThnicName: menuItem.EThnicName,
                        CareerName: menuItem.CareerName,
                        NationalityName: menuItem.NationalityName,
                        ProvincialCode: menuItem.ProvincialCode,
                        DistrictCode: menuItem.DistrictCode,
                        WardCode: menuItem.WardCode,
                        PatientAddress: menuItem.PatientAddress,
                        PatientName: menuItem.PatientName,
                        PatientGender: menuItem.PatientGender,
                        PatientBirthDay: new Date( Date.parse(menuItem.PatientBirthDay)),
                        PatientAge: menuItem.PatientAge,
                        PatientMobile: menuItem.PatientMobile,
                        PatientEmail: menuItem.PatientEmail,
                        MedicalHistory: menuItem.MedicalHistory,
                        Allergy: menuItem.Allergy
                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, ClinicCode, EThnicName, CareerName, NationalityName, ProvincialCode, DistrictCode, WardCode, PatientAddress, PatientName, PatientGender, PatientBirthDay, PatientAge, PatientMobile, PatientEmail, MedicalHistory, Allergy } = this.state;
      //  var { history } = this.props;
        const cdate = moment(PatientBirthDay).format('MM/DD/YYYY');
        e.preventDefault();

        if (id) {
            var patients = {
                ClinicCode: ClinicCode,
                PatientCode: id,
                EThnicName: EThnicName,
                CareerName: CareerName,
                NationalityName: NationalityName,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                PatientAddress: PatientAddress,
                PatientName: PatientName,
                PatientGender: PatientGender,
                PatientBirthDay: cdate,
                PatientAge: PatientAge,
                PatientMobile: PatientMobile,
                PatientEmail: PatientEmail,
                MedicalHistory: MedicalHistory,
                Allergy: Allergy
            }
            this.props.onUpdatePatients(patients);
           // history.goBack(window.location.reload(false));
           // window.location.reload(true);
           window.location.href = '/patients';

        } else {
            var patient = {
                ClinicCode: ClinicCode,
                PatientCode: 'BN'+ Math.floor(Math.random() * 10000000000),
                EThnicName: EThnicName,
                CareerName: CareerName,
                NationalityName: NationalityName,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                PatientAddress: PatientAddress,
                PatientName: PatientName,
                PatientGender: PatientGender,
                PatientBirthDay: PatientBirthDay? moment(PatientBirthDay).format('MM/DD/YYYY'): moment(new Date()).format('MM/DD/YYYY') ,
                PatientAge: PatientAge,
                PatientMobile: PatientMobile,
                PatientEmail: PatientEmail,
                MedicalHistory: MedicalHistory,
                Allergy: Allergy
            }
            this.props.onAddPatients(patient);
            window.location.href = '/patients';
           // history.goBack(window.location.reload(false));
        }
        

    }
    categoryClinic() {
        if (this.props.clinics) {
            return (
                this.props.clinics.map((cate, index) => {
                    return (<option key={index} value={cate.ClinicCode} >{cate.ClinicName}</option>);
                })
            )
        }
    }
    categoryGioiTinh() {
        var GioiTinh =[
            {
                PatientGender:1,
                lable:'Nam'
            },
            {
                PatientGender:2,
                lable:'Nữ'
            },
            {
                PatientGender:3,
                lable:'Khác'
            }
        ];
       
        return (
            GioiTinh.map((cate, index) => {
                return (<option key={index} value={cate.PatientGender} >{cate.lable}</option>);
            })
        )
        
    }
    handleChangeBirthDay = PatientBirthDay => {
        this.setState({
            PatientBirthDay
        });
      };

    render() {
        var { ClinicCode, EThnicName, CareerName, NationalityName, ProvincialCode, DistrictCode, WardCode, PatientAddress, PatientName, PatientGender, PatientBirthDay, PatientAge, PatientMobile, PatientEmail, MedicalHistory, Allergy } = this.state;
        
       /* var current=new Date().getFullYear();
        var birthday="04/30/1985";
        birthday=new Date(birthday).getFullYear();
        console.log(current-birthday);
            */

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Bệnh nhân</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Họ tên</label>
                                                <input type="text"
                                                    name="PatientName"
                                                    className="form-control"
                                                    value={PatientName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Nghề nghiệp</label>
                                                <input type="text"
                                                    name="CareerName"
                                                    className="form-control"
                                                    value={CareerName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Quốc tịch</label>
                                                <input type="text"
                                                    name="NationalityName"
                                                    className="form-control"
                                                    value={NationalityName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Email</label>
                                                <input type="text"
                                                    name="PatientEmail"
                                                    className="form-control"
                                                    value={PatientEmail}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Giới tính</label>
                                                <select value={PatientGender} className="form-control" onChange={this.onChange} name="PatientGender" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryGioiTinh()}
                                                </select>
                                               
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày sinh</label>
                                                <DatePicker className="form-control" name="PatientBirthDay"   selected={PatientBirthDay? PatientBirthDay : new Date() }    onChange={this.handleChangeBirthDay}
                                                     dateFormat="dd/MM/yyyy"  />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tuổi</label>
                                                <input type="text"
                                                    name="PatientAge"
                                                    className="form-control"
                                                    value={PatientAge}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Điện thoại</label>
                                                <input type="text"
                                                    name="PatientMobile"
                                                    className="form-control"
                                                    value={PatientMobile}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tỉnh thành</label>
                                                <input type="text"
                                                    name="ProvincialCode"
                                                    className="form-control"
                                                    value={ProvincialCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Quận/Huyện</label>
                                                <input type="text"
                                                    name="DistrictCode"
                                                    className="form-control"
                                                    value={DistrictCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Phường/Xã</label>
                                                <input type="text"
                                                    name="WardCode"
                                                    className="form-control"
                                                    value={WardCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Địa chỉ</label>
                                                <input type="text"
                                                    name="PatientAddress"
                                                    className="form-control"
                                                    value={PatientAddress}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >EThnicName</label>
                                                <input type="text"
                                                    name="EThnicName"
                                                    className="form-control"
                                                    value={EThnicName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Phòng khám</label>
                                                <select value={ClinicCode} className="form-control" onChange={this.onChange} name="ClinicCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryClinic()}
                                                </select>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Tiền sử</label>
                                                <textarea type="text"
                                                    name="MedicalHistory"
                                                    className="form-control"
                                                    value={MedicalHistory}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Triệu chứng</label>
                                                <textarea type="text"
                                                    name="Allergy"
                                                    className="form-control"
                                                    value={Allergy}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/patients" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        EditingPatients: state.EditingPatients
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddPatients: (patients) => {
            dispatch(addPatientsRequest(patients));
        },
        onEditPatients: (id) => {
            dispatch(eidtPatientsRequest(id));
        },
        onUpdatePatients: (patients) => {
            dispatch(updatePatientsRequest(patients));
        },
        fetchAllCategoryClinic: () => {
            dispatch(actFetchCateClinicRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(PatientsActionPage);