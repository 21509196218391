import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import DatePicker from "react-datepicker";
import moment from 'moment';
//import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


import { addSuggServiceRequest, eidtSuggServiceRequest, updateSuggServiceRequest } from '../../actions/SuggestedServiceReceipt/AcSuggestedService';
import { actFetchPatientReceiveCateRequest } from '../../actions/PatientReceive/AcPatientReceive';
class SuggServiceActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            SuggestedID: '',
            PatientReceiveID: '',
            PatientCode: '',
            ClinicCode: '',
            ServiceCode: '',
            ServiceName: '',
            ServicePrice: '',
            Quantity: '',
            DisparityPrice: '',
            Status_: '',
            WorkDate: '',
            Paid: '',
            EmployeeDoctorOrder_Code: '',
            EmployeeDoctorOrder_Name: '',
            WorkDateOrder: '',
            EmployeeDoctorDone_Code: '',
            EmployeeDoctorDone_Name: '',
            WorkDateDone: '',
            Note: '',
            UnitOfMeasureName: '',
            ServiceCategoryName: '',
            ServiceGroupName: '',
            ServiceModuleCode: '',
            mydata: '',
            mytime: ''
        }
        this.handleChangeWorkDateOrder = this.handleChangeWorkDateOrder.bind(this);
        this.handleChangeWorkDateDone = this.handleChangeWorkDateDone.bind(this);
        this.handleChangeWorkDate = this.handleChangeWorkDate.bind(this);
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditSuggService(id);
        }
        this.props.fetchCatePatientRe();

    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingSuggService !== this.props.EditingSuggService) {

                this.props.EditingSuggService.map((menuItem) => (


                    this.setState({
                        id: menuItem.SuggestedID,
                        PatientReceiveID: menuItem.PatientReceiveID,
                        PatientCode: menuItem.PatientCode,
                        ClinicCode: menuItem.ClinicCode,
                        ServiceCode: menuItem.ServiceCode,
                        ServiceName: menuItem.ServiceName,
                        ServicePrice: menuItem.ServicePrice,
                        Quantity: menuItem.Quantity,
                        DisparityPrice: menuItem.DisparityPrice,
                        Status_: menuItem.Status_,
                        Paid: menuItem.Paid,
                        EmployeeDoctorOrder_Code: menuItem.EmployeeDoctorOrder_Code,
                        EmployeeDoctorOrder_Name: menuItem.EmployeeDoctorOrder_Name,
                        WorkDateOrder: new Date(Date.parse(moment(menuItem.mydateOrder).format('YYYY-MM-DD') + ' ' + menuItem.TimeOrder.substring(11, 19))),
                        EmployeeDoctorDone_Code: menuItem.EmployeeDoctorDone_Code,
                        EmployeeDoctorDone_Name: menuItem.EmployeeDoctorDone_Name,
                        WorkDateDone: new Date(Date.parse(moment(menuItem.mydateDone).format('YYYY-MM-DD') + ' ' + menuItem.TimeDone.substring(11, 19))),
                        Note: menuItem.Note,
                        UnitOfMeasureName: menuItem.UnitOfMeasureName,
                        ServiceCategoryName: menuItem.ServiceCategoryName,
                        ServiceGroupName: menuItem.ServiceGroupName,
                        ServiceModuleCode: menuItem.ServiceModuleCode,
                        mydata: menuItem.mydate,
                        mytime: menuItem.TimeDB

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, PatientReceiveID, PatientCode, ClinicCode, ServiceCode, ServiceName, ServicePrice, Quantity, DisparityPrice, Status_, Paid, EmployeeDoctorOrder_Code, EmployeeDoctorOrder_Name, WorkDateOrder, EmployeeDoctorDone_Code, EmployeeDoctorDone_Name, WorkDateDone, Note, UnitOfMeasureName, ServiceCategoryName, ServiceGroupName, ServiceModuleCode } = this.state;
        //var { history } = this.props;

        // var ngayht = moment(this.state.mydate).format('YYYY-MM-DD')
        // var noichuoi = new Date(Date.parse( ngayht + ' ' + this.state.mytime.substring(11,19)));


        const WorkDateOrderID = moment(WorkDateOrder).format('YYYYMMDDHHmmss');
        const WorkDateDoneID = moment(WorkDateDone).format('YYYYMMDDHHmmss');

        var PatientReceiveID1 = this.props.patientreceives
            .filter(commentReply => commentReply.PatientCode === PatientCode)
            .map((commentReply, idx) => commentReply.PatientReceiveID);

        var ClinicCode1 = this.props.patientreceives
            .filter(commentReply => commentReply.PatientCode === PatientCode)
            .map((commentReply, idx) => commentReply.ClinicCode);



        e.preventDefault();

        if (id) {
            var suggservices = {
                SuggestedID: id,
                PatientReceiveID: PatientReceiveID1 ? PatientReceiveID1 : PatientReceiveID,
                PatientCode: PatientCode,
                ClinicCode: ClinicCode1 ? ClinicCode1 : ClinicCode,
                ServiceCode: ServiceCode,
                ServiceName: ServiceName,
                ServicePrice: ServicePrice,
                Quantity: Quantity,
                DisparityPrice: DisparityPrice,
                Status_: Status_,
                Paid: Paid,
                EmployeeDoctorOrder_Code: EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name: EmployeeDoctorOrder_Name,
                WorkDateOrder: WorkDateOrderID,
                EmployeeDoctorDone_Code: EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name: EmployeeDoctorDone_Name,
                WorkDateDone: WorkDateDoneID,
                Note: Note,
                UnitOfMeasureName: UnitOfMeasureName,
                ServiceCategoryName: ServiceCategoryName,
                ServiceGroupName: ServiceGroupName,
                ServiceModuleCode: ServiceModuleCode


            }
            this.props.onUpdateSuggService(suggservices);
           // history.goBack(window.location.reload(true));

        } else {
            var suggservice = {
                SuggestedID: Math.floor(Math.random() * 10000000000),
                PatientReceiveID: PatientReceiveID1,
                PatientCode: PatientCode,
                ClinicCode: ClinicCode1,
                ServiceCode: ServiceCode,
                ServiceName: ServiceName,
                ServicePrice: ServicePrice,
                Quantity: Quantity,
                DisparityPrice: DisparityPrice,
                Status_: Status_,
                Paid: Paid,
                EmployeeDoctorOrder_Code: EmployeeDoctorOrder_Code,
                EmployeeDoctorOrder_Name: EmployeeDoctorOrder_Name,
                WorkDateOrder: WorkDateOrder ? moment(WorkDateOrder).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                EmployeeDoctorDone_Code: EmployeeDoctorDone_Code,
                EmployeeDoctorDone_Name: EmployeeDoctorDone_Name,
                WorkDateDone: WorkDateDone ? moment(WorkDateDone).format('YYYYMMDDHHmmss') : moment(new Date()).format('YYYYMMDDHHmmss'),
                Note: Note,
                UnitOfMeasureName: UnitOfMeasureName,
                ServiceCategoryName: ServiceCategoryName,
                ServiceGroupName: ServiceGroupName,
                ServiceModuleCode: ServiceModuleCode

            }
            this.props.onAddSuggService(suggservice);
           // history.goBack(window.location.reload(true));
        }
        window.location.href = '/suggested-service';

    }
    handleChangeWorkDateOrder = WorkDateOrder => {
        this.setState({
            WorkDateOrder
        });
    };
    handleChangeWorkDateDone = WorkDateDone => {
        this.setState({
            WorkDateDone
        });
    };
    handleChangeWorkDate = WorkDate => {
        this.setState({
            WorkDate
        });
    };

    categoryPatientRe() {
        if (this.props.patientreceives) {
            return (
                this.props.patientreceives.map((cate, index) => {
                    return (<option key={index} value={cate.PatientCode} >{cate.CareerName}</option>);
                })
            )
        }
    }

    render() {
        var { PatientCode, ServiceCode, ServiceName, ServicePrice, Quantity,
            DisparityPrice, Status_, Paid, EmployeeDoctorOrder_Code, EmployeeDoctorOrder_Name,
            WorkDateOrder, EmployeeDoctorDone_Code, EmployeeDoctorDone_Name, WorkDateDone, Note,
            UnitOfMeasureName, ServiceCategoryName, ServiceGroupName, ServiceModuleCode } = this.state;

        //  var ngayht = moment(this.state.mydate).format('YYYY-MM-DD')
        //  var noichuoi = new Date(Date.parse( ngayht + ' ' + this.state.mytime.substring(11,19)));

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Dịch vụ kỹ thuật (CLS)</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Loại BN</label>
                                                <select value={PatientCode} className="form-control" onChange={this.onChange} name="PatientCode" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryPatientRe()}
                                                </select>

                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã DV</label>
                                                <input type="text"
                                                    name="ServiceCode"
                                                    className="form-control"
                                                    value={ServiceCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên DV</label>
                                                <input type="text"
                                                    name="ServiceName"
                                                    className="form-control"
                                                    value={ServiceName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label >Giá DV</label>
                                                        <input type="text"
                                                            name="ServicePrice"
                                                            className="form-control"
                                                            value={ServicePrice}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label >Giá Chênh lệch</label>
                                                        <input type="text"
                                                            name="DisparityPrice"
                                                            className="form-control"
                                                            value={DisparityPrice}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Số lượng</label>
                                                <input type="text"
                                                    name="Quantity"
                                                    className="form-control"
                                                    value={Quantity}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Trạng thái</label>
                                                <input type="text"
                                                    name="Status_"
                                                    className="form-control"
                                                    value={Status_}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Thanh toán</label>
                                                <input type="text"
                                                    name="Paid"
                                                    className="form-control"
                                                    value={Paid}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Ghi chú </label>
                                                <input type="text"
                                                    name="Note"
                                                    className="form-control"
                                                    value={Note}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">


                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Tên BS NV</label>
                                                <input type="text"
                                                    name="EmployeeDoctorOrder_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorOrder_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày chỉ định</label>
                                                <DatePicker name="WorkDateOrder" value={WorkDateOrder ? WorkDateOrder : new Date()} onChange={this.handleChangeWorkDateOrder}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Mã BS NV Kt</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Code"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Code}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Tên BS NV KT</label>
                                                <input type="text"
                                                    name="EmployeeDoctorDone_Name"
                                                    className="form-control"
                                                    value={EmployeeDoctorDone_Name}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Ngày kết thúc</label>
                                                <DatePicker name="WorkDateDone" value={WorkDateDone ? WorkDateDone : new Date()} onChange={this.handleChangeWorkDateDone}
                                                    dateFormat="DD/MM/yyyy" timeFormat="HH:mm:ss" />
                                            </div>
                                        </div>





                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên Đơn vị</label>
                                                <input type="text"
                                                    name="UnitOfMeasureName"
                                                    className="form-control"
                                                    value={UnitOfMeasureName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên Dịch vụ</label>
                                                <input type="text"
                                                    name="ServiceCategoryName"
                                                    className="form-control"
                                                    value={ServiceCategoryName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Tên DV Group</label>
                                                <input type="text"
                                                    name="ServiceGroupName"
                                                    className="form-control"
                                                    value={ServiceGroupName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Mã DV </label>
                                                <input type="text"
                                                    name="ServiceModuleCode"
                                                    className="form-control"
                                                    value={ServiceModuleCode}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>






                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/suggested-service" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {
        patientreceives: state.patientreceives,
        EditingSuggService: state.EditingSuggService
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddSuggService: (suggestedservices) => {
            dispatch(addSuggServiceRequest(suggestedservices));
        },
        onEditSuggService: (id) => {
            dispatch(eidtSuggServiceRequest(id));
        },
        onUpdateSuggService: (suggestedservices) => {
            dispatch(updateSuggServiceRequest(suggestedservices));
        },
        fetchCatePatientRe: () => {
            dispatch(actFetchPatientReceiveCateRequest());
        },

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(SuggServiceActionPage);