import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const ketquaxetnghiemresult = (state = initialState, action) => {
    switch (action.type) {
        case Types.XETNGHIEM_CLS_KQ_ADMIN:
           state = action.ketquaxetnghiemresult;
           return [...state];
           //return state;

        default: return [...state];
        //default: return state;;
    }
}
export default ketquaxetnghiemresult;