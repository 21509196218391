import * as Types from '../../constants/Doctors/ActionTypes';
var initialState = [];

var findIndex = (doctors, id) => {
    var result = -1;
    doctors.forEach((doctor, index) => {
        if (doctor.id === id) {
            result = index;
        }
    })
    return result;
}
const doctors = (state = initialState, action) => {
    var index = -1;
    var { id, doctors } = action;
    switch (action.type) {
        case Types.FETCH_DOCTORS:
            state = action.doctors;
           
            return [...state];
        case Types.DELETE_DOCTORS:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_DOCTORS:
            state.push(action.doctors);
            return [...state];

        case Types.UPDATE_DOCTORS:
            index = findIndex(state, doctors.id);
            state[index] = doctors;
            return [...state];
        case Types.UPDATE_STATUS_DOCTORS:
            index = findIndex(state, id);
            state[index] = id;
            return [...state];
       
        default: return [...state];
    }
}
export default doctors;