import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileFetch } from '../../actions/AcLogin';
import { GetCliniCRequest } from '../../actions/Clinic/AcClinic';


/*
const menus =[
    {
        name:'Trang chu',
        to:'/',
        exact:true
    },
    {
        name:'Quan ly',
        to:'/products',
        exact:false,
        resources:[
            {
                name:'Quan ly san pham 1',
                to:'/products',
                exact:false,
            },
            {
                name:'Quan ly san pham 2',
                to:'/products',
                exact:false,
            }
        ]
    }
];
const MenuLink =({label, to, activeOnlyWhenExact}) =>{
    return (
        <Route
            path={to}
            exact={activeOnlyWhenExact}
            children={(match)=>{
                var active = match? 'active':'';
                return(
                    <li className={`nav-item ${active}` }>
                        <Link to={to} className="nav-link">
                            {label}
                        </Link>
                    </li>
                );
            }

            }
        />
    );
}

*/

class Sidebar extends Component {
  /* showMenus=(menus)=>{
       return (
           <div>
               {menus.map(function(menuItem, i) {
                   if (menuItem.resources !== undefined) {
                       return (
                           <li key={i} className="nav-item has-treeview">
                           <Link to="" className="nav-link">
                             <i className="nav-icon fas fa-copy"></i>
                             <p>
                                {menuItem.name}
                               <i className="fas fa-angle-left right"></i>
                             </p>
                           </Link>
                           <ul className="nav nav-treeview">
                               {menuItem.resources.map(function(subMenu, i) {
                                   return (
                                       <MenuLink
                                       key={i}
                                       label={subMenu.name}
                                       to={subMenu.to}
                                       activeOnlyWhenExact={subMenu.exact}
                                   />
                                   );
                               })}
                           </ul></li>
                       )
                   } else {
                       return (
                           <MenuLink
                               key={i}
                               label={menuItem.name}
                               to={menuItem.to}
                               activeOnlyWhenExact={menuItem.exact}
                           />
                       )
                   }
               })};
   </div>)
                {this.showMenus(menus)}
   }*/
  componentDidMount() {
    this.props.getProfileFetch();
    this.props.getClinic();

  }

  render() {
    if (this.props.payload.currentUser.UserType === 3) {
      return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="" className="brand-link">
            <img src={`${window.location.origin}/dist/img/logo.png`} alt="eMedical WinSoft" className="brand-image img-circle elevation-3" />
            <span className="brand-text font-weight-light">Hồ Sơ Sức Khỏe</span>
          </Link>
          <div className="sidebar">
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item">
                  <Link to="/danh-sach-benh-nhan" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Danh sách bệnh nhân
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/danh-sach-dat-hen" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Danh sách đặt hẹn
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/clinic" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Phòng khám
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/doctor" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Bác sĩ
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/chuyen-khoa" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Chuyên khoa
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/map-user-clinic" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      User - Phòng Khám
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/hoi-dap" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Đặt câu hỏi
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/tra-loi" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Trả lời
                    </p>
                  </Link>
                </li>


                {/*
                <li className="nav-item">
                  <Link to="/document-image" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Hình ảnh
                    </p>
                  </Link>
                </li>
                  <li className="nav-item">
                  <Link to="/suggested-service" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Dịch vụ kỹ thuật
                    </p>
                  </Link>
                </li>
                 <li className="nav-item">
                  <Link to="/patients" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Bệnh nhân
                    </p>
                  </Link>
                </li>
                 <li className="nav-item">
                  <Link to="/patient-receive" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Tiếp nhận Bệnh nhân
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/document-image" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Hình ảnh
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/medical-record" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Lịch sử khám bệnh
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/medical-record-detail" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      
                      Chi tiết thuốc
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/service-radiology-entry" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Kết quả CLS
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/service-laboratory-entry" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Trả KQ xét nghiệm
                    </p>
                  </Link>
      </li>*/}
                <li className="nav-item">
                  <Link to="/news" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Tin tức
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/abouts" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Giới thiệu
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/slogan" className="nav-link menuleft">
                    <i className="nav-icon fa fa-tint"></i>
                    <p>
                      Slogan
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/user" className="nav-link menuleft">
                    <i className="nav-icon fa fa-user"></i>
                    <p>
                      User
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/tra-cuu-ket-qua-xet-nghiem" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Tra cứu KQ xét nghiệm
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/thong-bao" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Thông báo
                    </p>
                  </Link>
                </li>


                {/*
                <li className="nav-item has-treeview">
                  <span className="nav-link navSpan">
                    <i className="nav-icon fas fa-copy"></i>
                    <p>
                      fsdfsd
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </span>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="dfgdf" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Top Navigation</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dfg" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Top Navigation + Sidebar</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dfg" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Boxed</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="fdgfdg" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Fixed Sidebar</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dfg" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Fixed Navbar</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="fdgdf" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Fixed Footer</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="dfgdfg" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Collapsed Sidebar</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                 */}

              </ul>
            </nav>
          </div>
        </aside>

      );
    } else if (this.props.payload.currentUser.UserType === 2) {
      return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="" className="brand-link">
            <img src={`${window.location.origin}/dist/img/logo.png`} alt="eMedical WinSoft" className="brand-image img-circle elevation-3" />
            <span className="brand-text font-weight-light">Hồ Sơ Sức Khỏe</span>
          </Link>
          <div className="sidebar">
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                <li className="nav-item">
                  <Link to="/danh-sach-benh-nhan-phong-kham" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Danh sách bệnh nhân
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/danh-sach-dat-hen" className="nav-link menuleft">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Danh sách đặt hẹn
                    </p>
                  </Link>
                </li>
          
                {/*<li><div className="Leftbottom"></div></li>
                <li className="nav-item">
                  <p className="LeftTT">
                    Công Ty WinSoft Việt Nam
                  </p>
                </li>
                <li className="nav-item">
                  <p className="LeftTT">
                    Hotline: 086.86.79.115 (Mr.Tiến)
                  </p>
                </li>
                <li className="nav-item">
                  <p className="LeftTT">
                    Hotline: 0989.365.065 (Mr.Hùng)
                  </p>
                </li>
                <li className="nav-item">
                  <p className="LeftTT">
                    Hotline: 0973.230.919 (Mr.Đồng)
                  </p>
                </li>*/}

              </ul>
            </nav>
          </div>
        </aside>

      );
    } else {
      return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="" className="brand-link">
            <img src={`${window.location.origin}/dist/img/logo.png`} alt="eMedical WinSoft" className="brand-image img-circle elevation-3" />
            <span className="brand-text font-weight-light">Hồ Sơ Sức Khỏe</span>
          </Link>
          <div className="sidebar">
            <nav className="mt-2">
             

              {this.props.clinics.map((item, index) => {
                return (
                  <ul key={index} className="nav nav-pills nav-sidebar flex-column phongkhamleft" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item">
                      <p className="LeftTT">
                        {item.ClinicName}
                      </p>
                    </li>
                    <li className="nav-item">
                      <p className="LeftTT">
                        Hotline: {item.Mobile}
                      </p>
                    </li>
                  </ul>
                )
              })}

            </nav>
          </div>
        </aside>

      );
    }

  }
}
const mapStateToProps = state => {
  return {
    payload: state.payload,
    clinics: state.clinics
  }
}
const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => {
    dispatch(getProfileFetch());
  },
  getClinic: () => {
    dispatch(GetCliniCRequest());
  },

})
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

