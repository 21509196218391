import * as Types from '../../constants/DSBNPhongKham/ActionTypes';
var initialState = "";
var searchcatephongkham = (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_CATEGORY:
      return action.categoryID;
    default:
      return state;
  }
};
export default searchcatephongkham;