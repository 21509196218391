import React, { Component } from 'react';
class ChuyenKhoaList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
								<div className ="tableMain">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Tên</th>
                                            <th>Phòng khám</th>
                                            <th width="100" >Hiển thị</th>
                                            <th width="200" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
							</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default ChuyenKhoaList;

