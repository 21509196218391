import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
//import axios from 'axios';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { addUserRequest, eidtUserRequest, updateUserRequest } from '../../actions/User/AcUser';

import { API_URL_UP } from '../../utils/urlImage';

class UserActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            UserName: '',
            Pass_Word: '',
            FullName: '',
            Sex: '',
            Address: '',
            ProvincialCode: '',
            DistrictCode: '',
            WardCode: '',
            Mobile: '',
            ImageUrl: '',
            UserID: '',
            BirthDay: '',
            file: null,
            UserType: '',
            ngaysinh: ''

        }
        this.handleChangeBirthDay = this.handleChangeBirthDay.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.handleChangeGioiTinh = this.handleChangeGioiTinh.bind(this);

    }
    onChangeImage(e) {
        this.setState({ file: e.target.files[0] });
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {

        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditUser(id);
        }
        this.setState({
            Sex: this.props.defaultValue,
        })

    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.ItemEditingUser !== this.props.ItemEditingUser) {

                this.props.ItemEditingUser.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        UserName: menuItem.UserName,
                        FullName: menuItem.FullName,
                        Sex: menuItem.Sex,
                        Address: menuItem.Address,
                        ProvincialCode: menuItem.ProvincialCode,
                        DistrictCode: menuItem.DistrictCode,
                        WardCode: menuItem.WardCode,
                        Mobile: menuItem.Mobile,
                        ImageUrl: menuItem.ImageUrl,
                        UserID: menuItem.UserID,
                        // BirthDay: new Date(Date.parse(menuItem.BirthDay)),
                        BirthDay: menuItem.BirthDay,
                        UserType: menuItem.UserType,
                        ngaysinh: menuItem.ngaysinh

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, UserName, Pass_Word, FullName, Sex, ngaysinh, Address, ProvincialCode, DistrictCode, WardCode, Mobile, UserID, UserType } = this.state;
        // var { history } = this.props;
        const cdate = moment(ngaysinh).format('DD/MM/YYYY');
        const formData = new FormData();
        e.preventDefault();
        if (id) {

            formData.append('RowID', id);
            formData.append('UserName', UserName);
            formData.append('Pass_Word', Pass_Word);
            formData.append('FullName', FullName);
            formData.append('Sex', Sex);
            formData.append('BirthDay', cdate);
            formData.append('Address', Address);
            formData.append('ProvincialCode', ProvincialCode);
            formData.append('DistrictCode', DistrictCode);
            formData.append('WardCode', WardCode);
            formData.append('Mobile', Mobile);
            formData.append('ImageUrl', this.state.file);
            formData.append('UserID', UserID);
            formData.append('UserType', UserType);
            this.props.onUpdateUser(formData);



        } else {
            formData.append('RowID', Math.floor(Math.random() * 10000000000));
            formData.append('UserName', UserName);
            formData.append('Pass_Word', Pass_Word);
            formData.append('FullName', FullName);
            formData.append('Sex', Sex);
            formData.append('BirthDay', ngaysinh ? moment(ngaysinh).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY'));
            formData.append('Address', Address);
            formData.append('ProvincialCode', ProvincialCode);
            formData.append('DistrictCode', DistrictCode);
            formData.append('WardCode', WardCode);
            formData.append('Mobile', Mobile);
            formData.append('ImageUrl', this.state.file);
            formData.append('UserID', UserID);
            formData.append('UserType', UserType);

            this.props.onAddUsers(formData);


        }
        /* var user = {
                RowID: Math.floor(Math.random() * 10000000000),
                UserName: UserName,
                Pass_Word: Pass_Word,
                FullName: FullName,
                Sex: Sex,
                BirthDay: BirthDay ? moment(BirthDay).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY'),
                Address: Address,
                ProvincialCode: ProvincialCode,
                DistrictCode: DistrictCode,
                WardCode: WardCode,
                Mobile: Mobile,
                UserID: UserID,
                UserType:UserType
            }

            this.props.onAddUsers(user);
            if (this.state.file) {
        	
                const formData = new FormData();
                formData.append('ImageUrl', this.state.file);
                formData.append('UserName', UserName);

                let token = JSON.parse(localStorage.getItem('token'));
                const config = {
                    headers: { 'content-type': 'multipart/form-data', 'x-access-token': `${token}` }
                };
                axios.post("http://192.168.1.250:8090/api/upload", formData, config)
                    .then((response) => {

                    }).catch((error) => {
                    });
            }*/
        // history.goBack(window.location.reload(true));
        //  window.location.href = '/user';

    }
    handleChangeBirthDay = ngaysinh => {
        this.setState({
            ngaysinh
        });
    };

    handleChangeGioiTinh(selectedOption) {
        this.setState({ Sex: selectedOption.value });
    }
    categoryUserType() {
        var UserTypes = [
            {
                UserType: 1,
                lable: 'Bệnh nhân'
            },
            {
                UserType: 2,
                lable: 'Phòng khám'
            },
            {
                UserType: 3,
                lable: 'Quản trị'
            }
        ];

        return (
            UserTypes.map((cate, index) => {
                return (<option key={index} value={cate.UserType} >{cate.lable}</option>);
            })
        )

    }
    render() {

        var { UserName, Pass_Word, FullName, Sex, Address, ProvincialCode, DistrictCode, WardCode, Mobile, ImageUrl, UserID, UserType, ngaysinh } = this.state;

        // var dateParts = BirthDay.split("/");
        // console.log('zxczxc',dateParts)
        // var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        //console.log('mmm',dateObject, new Date(BirthDay) )
        var GioiTinhActive = Sex === 1 ? '1' : '0';

        // var showimage = ImageUrl?  `url(${API_URL_UP}user/${ImageUrl}) center no-repeat` : `url (http://localhost:3000/dist/img/logo.png) center no-repeat`;
       //  var showimage = ImageUrl ==='' || ImageUrl === null ? `http://localhost:3000/dist/img/logo.png`:  `${API_URL_UP}user/${ImageUrl}` ;
        const GioiTinh = [
            { value: '', label: 'Chọn' },
            { value: '1', label: 'Nam' },
            { value: '0', label: 'Nữ' }
        ];
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">User</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >UserName</label>
                                                <input type="text"
                                                    name="UserName"
                                                    className="form-control"
                                                    value={UserName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mật khẩu</label>
                                                <input type="password"
                                                    name="Pass_Word"
                                                    className="form-control"
                                                    value={Pass_Word ? Pass_Word : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Họ tên</label>
                                                <input type="text"
                                                    name="FullName"
                                                    className="form-control"
                                                    value={FullName ? FullName : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Số điện thoại</label>
                                                <input type="number"
                                                    name="Mobile"
                                                    className="form-control"
                                                    value={Mobile}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Giới tính</label>
                                                {Sex === 1 || Sex === 0 ?
                                                    <Select
                                                        value={GioiTinh.filter(({ value }) => value === GioiTinhActive)}
                                                        onChange={this.handleChangeGioiTinh}
                                                        options={GioiTinh}
                                                    />
                                                    :
                                                    <Select
                                                        value={GioiTinh.filter(({ value }) => value === Sex)}
                                                        onChange={this.handleChangeGioiTinh}
                                                        options={GioiTinh}
                                                    />
                                                }




                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Ngày sinh</label>
                                                <div className="form-group">
                                                    <DatePicker className="form-control" name="BirthDay" selected={ngaysinh ? new Date(ngaysinh) : new Date()} onChange={this.handleChangeBirthDay}
                                                        dateFormat="dd/MM/yyyy" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Địa chỉ</label>
                                                <input type="text"
                                                    name="Address"
                                                    className="form-control"
                                                    value={Address ? Address : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã tỉnh</label>
                                                <input type="text"
                                                    name="ProvincialCode"
                                                    className="form-control"
                                                    value={ProvincialCode ? ProvincialCode : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã quận</label>
                                                <input type="text"
                                                    name="DistrictCode"
                                                    className="form-control"
                                                    value={DistrictCode ? DistrictCode : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Mã phường</label>
                                                <input type="text"
                                                    name="WardCode"
                                                    className="form-control"
                                                    value={WardCode ? WardCode : ''}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >UserID</label>
                                                <input type="text"
                                                    name="UserID"
                                                    className="form-control"
                                                    value={UserID}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Đối tượng</label>
                                                <select value={UserType} className="form-control" onChange={this.onChange} name="UserType" >
                                                    <option value="">Chọn</option>
                                                    {this.categoryUserType()}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label >Hình ảnh</label>
                                        <div className="avatar-upload">
                                            <div className="avatar-edit">
                                                <input type='file' id="imageUpload" name="ImageUrl" onChange={this.onChangeImage} accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload"> <i className="fa fa-plus" aria-hidden="true"></i></label>
                                            </div>
                                            <div className="avatar-preview">
                                                {this.state.id ?
                                                <div id="imagePreview"  style={ {
                                                    backgroundImage: ImageUrl !== 'null' ? `url(${API_URL_UP}user/${ImageUrl})` : `url(http://localhost:3000/dist/img/logo.png)`, backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover'
                                                }}></div>
                                                    :
                                                 <div id="imagePreview" ></div>
                                                }
                                            </div>
                                        </div>


                                        {/*<div className="custom-file">
                                            <input type="file" className="custom-file-input" name="ImageUrl" id="exampleInputFile" onChange={this.onChangeImage} />
                                            <label className="custom-file-label" >Choose file</label>
                                        </div>
                                        {ImageUrl? <img src={`${API_URL_UP}user/${ImageUrl}`} className="ImgUrlUser" alt="User" />: <img src="http://localhost:3000/dist/img/logo.png" className="ImgUrlUser" alt="User" /> 
                                        }
                                        {this.state.id ? <img src={showimage} className="ImgUrlUser" alt="User" />
                                            : null}*/}
                                    </div>



                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/user" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        ItemEditingUser: state.ItemEditingUser
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddUsers: (users) => {
            dispatch(addUserRequest(users));
        },
        onEditUser: (id) => {
            dispatch(eidtUserRequest(id));
        },
        onUpdateUser: (users) => {
            dispatch(updateUserRequest(users));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(UserActionPage);