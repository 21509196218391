import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import * as Url from '../../utils/urlImage';
import { addNewsRequest, eidtNewsRequest, updateNewsRequest } from '../../actions/News/AcNews';
/*function FormError(props) {
    if (props.isHidden) { return null; }
    return (
        <div className="form-warning">
            {props.errorMessage}
        </div>
    )
}*/

const validateInput = (checkingText) => {

    const regexp = /^\d{10,11}$/;
    // regular expression - checking if phone number contains only 10 - 11 numbers

    if (regexp.exec(checkingText) !== null) {
        return {
            isInputValid: true,
            errorMessage: ''
        };
    } else {
        return {
            isInputValid: false,
            errorMessage: 'Số điện thoại phải có 10 - 11 chữ số.'
        };
    }
}
class NewsActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            NewsName: '',
            ImageUrl: '',
            NewsMota: '',
            NewsNote: '',

            value: '',
            isInputValid: true,
            errorMessage: '',
            file: null

        }
        this.onChangeImage = this.onChangeImage.bind(this);


    }
    onChangeImage(e) {
        this.setState({ file: e.target.files[0] });
    }
    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    onChangeEditor = (e) => {
        this.setState({
            NewsNote: e
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditNews(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.EditingNews !== this.props.EditingNews) {

                this.props.EditingNews.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        NewsName: menuItem.NewsName,
                        ImageUrl: menuItem.ImageUrl,
                        NewsMota: menuItem.NewsMota,
                        NewsNote: menuItem.NewsNote

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, NewsName, NewsMota, NewsNote } = this.state;
        // var { history } = this.props;
        e.preventDefault();
        const formData = new FormData();
        if (id) {
            formData.append('RowID', id);
            formData.append('NewsName', NewsName);
            formData.append('ImageUrl', this.state.file);
            formData.append('NewsMota', NewsMota);
            formData.append('NewsNote', NewsNote);
            this.props.onUpdateNews(formData);

        } else {
            formData.append('RowID', Math.floor(Math.random() * 10000000000));
            formData.append('NewsName', NewsName);
            formData.append('ImageUrl', this.state.file);
            formData.append('NewsMota', NewsMota);
            formData.append('NewsNote', NewsNote);
            this.props.onAddNews(formData);
        }
        

    }
    ///Hàm kiểm tra

    handleInput = event => {
        const { value } = event.target;
        this.setState({ value });
    }

    handleInputValidation = event => {
        const { isInputValid, errorMessage } = validateInput(this.state.Mobile);
        this.setState({
            isInputValid: isInputValid,
            errorMessage: errorMessage
        })

    }


    render() {
        var { NewsName, ImageUrl, NewsMota, NewsNote } = this.state;
        const defaultFonts = [
            "Arial",
            "Comic Sans MS",
            "Courier New",
            "Impact",
            "Georgia",
            "Tahoma",
            "Trebuchet MS",
            "Verdana"
        ];
        const sortedFontOptions = [
            "Logical",
            "Salesforce Sans",
            "Garamond",
            "Sans-Serif",
            "Serif",
            "Times New Roman",
            "Helvetica",
            ...defaultFonts
        ].sort();

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Tin tức</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Tiêu đề</label>
                                                <input type="text"
                                                    name="NewsName"
                                                    className="form-control"
                                                    value={NewsName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Hình ảnh</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="ImageUrl"  onChange={this.onChangeImage} />
                                                    <label className="custom-file-label" >Choose file</label>
                                                </div>
                                                {this.state.id ? <img src={ImageUrl == null ? 'http://localhost:3000/dist/img/logo.png' : `${Url.API_URL_UP}news/${ImageUrl}`} className="ImgUrlUser" alt="User" />
                                                    : null}
                                            </div>

                                         

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label >Mô tả</label>
                                        <textarea
                                            name="NewsMota"
                                            className="form-control"
                                            value={NewsMota}
                                            onChange={this.onChange}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="form-group" id="dynamic_field">
                                        <label >Nội dung</label>

                                        <SunEditor
                                            plugin=""
                                            setContents={NewsNote}
                                            onChange={this.onChangeEditor}
                                            setOptions={{
                                                buttonList: [
                                                    ["undo", "redo"],
                                                    ["font", "fontSize"],
                                                    // ['paragraphStyle', 'blockquote'],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript"
                                                    ],
                                                    ["fontColor", "hiliteColor"],
                                                    ["align", "list", "lineHeight"],
                                                    ["outdent", "indent"],

                                                    ["table", "horizontalRule", "link", "image", "video"],
                                                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                    // ["fullScreen", "showBlocks", "codeView"],
                                                    ["preview", "print"],
                                                    ["removeFormat"]

                                                    // ['save', 'template'],
                                                    // '/', Line break
                                                ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                defaultTag: "div",
                                                minHeight: "300px",
                                                showPathLabel: false,
                                                font: sortedFontOptions
                                            }}
                                        />

                                        <textarea
                                           name='NewsNote'
                                            disabled
                                            value={JSON.stringify(NewsNote, null, 2)}
                                            style={{ display:'none' }}
                                        />

                                    </div>
                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/news" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        EditingNews: state.EditingNews
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddNews: (news) => {
            dispatch(addNewsRequest(news));
        },
        onEditNews: (id) => {
            dispatch(eidtNewsRequest(id));
        },
        onUpdateNews: (news) => {
            dispatch(updateNewsRequest(news));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(NewsActionPage);