import React, { Component } from 'react';
class PatientsList extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="Tableauto">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                             <th width="200">Tên BN</th>
                                            <th width="100">Mã PK</th>
                                            <th width="100">Mã BN</th>
                                            <th width="200">Tên</th>
                                            <th width="100">Nghề nghiệp</th>
                                            <th width="100">Quốc tịch</th>
                                            <th width="100">Tỉnh thành</th>
                                            <th width="100">Quận/Huyện</th>
                                            <th width="100">Phường/Xã</th>
                                            <th width="200">Địa chỉ</th>
                                            <th width="100">Giới Tính</th>
                                            <th width="100">Ngày sinh</th>
                                            <th width="50">Tuổi</th>
                                            <th width="100">Điện thoại</th>
                                            <th width="200">Email</th>
                                            <th width="200">Tiền sử</th>
                                            <th width="200">Dị ứng</th>
                                            <th width="100" >Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.children}
                                    </tbody>

                                </table>
                           
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default PatientsList;

