import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Url from '../../utils/urlImage';

import { addSloganRequest, eidtSloganRequest, updateSloganRequest } from '../../actions/Slogan/AcSlogan';

class SloganActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            SloganName: '',
            ImageUrl: '',
            SloganMota: '',
            backgroundColor: '',
            file: null,
        }
        this.onChangeImage = this.onChangeImage.bind(this);
    }
    onChangeImage(e) {
        this.setState({ file: e.target.files[0] });
    }

    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }
    componentDidMount() {
        var { match } = this.props;
        if (match) {
            var id = match.params.id;
            this.props.onEditSlogan(id);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem('token');
        if (token === null) {
            window.location.href = '/';
        } else {
            if (prevProps.itemEditingSlogan !== this.props.itemEditingSlogan) {

                this.props.itemEditingSlogan.map((menuItem) => (
                    this.setState({
                        id: menuItem.RowID,
                        SloganName: menuItem.SloganName,
                        SloganMota: menuItem.SloganMota,
                        backgroundColor: menuItem.backgroundColor,
                        ImageUrl: menuItem.ImageUrl

                    })
                ))
            }
        }
    }

    onSave = (e) => {
        var { id, SloganName, SloganMota, backgroundColor } = this.state;
        const formData = new FormData();
        // var { history } = this.props;
        e.preventDefault();
        if (id) {
            formData.append('RowID', id);
            formData.append('SloganName', SloganName);
            formData.append('ImageUrl', this.state.file);
            formData.append('SloganMota', SloganMota);
            formData.append('backgroundColor', backgroundColor);

            this.props.onUpdateSlogan(formData);
        } else {

            formData.append('RowID', Math.floor(Math.random() * 10000000000));
            formData.append('SloganName', SloganName);
            formData.append('ImageUrl', this.state.file);
            formData.append('SloganMota', SloganMota);
            formData.append('backgroundColor', backgroundColor);

            this.props.onAddSlogan(formData);

        }
        

    }
    render() {
        const { SloganName, SloganMota, backgroundColor, ImageUrl } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="clinicsTitle">
                                    <h3 className="card-title">Giới thiệu</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onSave} >

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Tên</label>
                                                <input type="text"
                                                    name="SloganName"
                                                    className="form-control"
                                                    value={SloganName}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >BackgroundColor</label>
                                                <input type="text"
                                                    name="backgroundColor"
                                                    className="form-control"
                                                    value={backgroundColor}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label >Mô tả</label>
                                        <textarea
                                            className='textMota'
                                            name='SloganMota'
                                            value={SloganMota}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label >Hình ảnh</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" name="ImageUrl" onChange={this.onChangeImage} />
                                            <label className="custom-file-label" >Choose file</label>
                                        </div>
                                        {this.state.id ? <img src={ImageUrl == null ? '../dist/img/logo.png' : `${Url.API_URL_UP}slogan/${ImageUrl}`} className="ImgUrlUser" alt="User" />
                                            : null}
                                    </div>

                                    <div className="btnAdd">

                                        <button type="submit" className="btn btn-primary">
                                            {this.state.id ? 'Cập nhật' : 'Thêm'}
                                        </button>
                                        <Link to="/slogan" className="btn btn-info" >Trở lại</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

}


const mapStateToProps = state => {
    return {

        itemEditingSlogan: state.itemEditingSlogan
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {

        onAddSlogan: (slogans) => {
            dispatch(addSloganRequest(slogans));
        },
        onEditSlogan: (id) => {
            dispatch(eidtSloganRequest(id));
        },
        onUpdateSlogan: (slogans) => {
            dispatch(updateSloganRequest(slogans));
        }

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(SloganActionPage);