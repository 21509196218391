import * as Types from '../../constants/Abouts/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchAboutsRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('abouts', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchAbouts(res.data));
                }
            })
        }
    }
 
}
export const actFetchAbouts = (abouts) => {
    return {
        type: Types.FETCH_ABOUTS,
        abouts
    }
}

export const deleteAboutsRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-abouts/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteAbouts(id));
        })
    }
}
export const deleteAbouts = (id) => {
    return {
        type: Types.DELETE_ABOUTS,
        id
    }
}



export const addAboutsRequest = (abouts) => {
    return (dispatch) => {
        return UrlApi('add-abouts', 'POST', abouts, authHeader()).then(res => {
            dispatch(addAbbouts(res.data));
            window.location.href = '/abouts';
        })
    }
}

export const addAbbouts = (abouts) => {
    return {
        type: Types.ADD_ABOUTS,
        abouts
    }
}


export const eidtAboutsRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`abouts/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editAbouts(res.data));
            }

        })
    }
}


export const editAbouts = (abouts) => {
    return {
        type: Types.EDIT_ABOUTS,
        abouts
    }
}
export const updateAboutsRequest = (abouts) => {
    return (dispatch) => {
        return UrlApi(`update-abouts/${abouts.id}`, 'PUT', abouts, authHeader()).then(res => {
            dispatch(updateAbouts(res.data));
            window.location.href = '/abouts';

        })
    }
}
export const updateAbouts = (abouts) => {
    return {
        type: Types.UPDATE_ABOUTS,
        abouts
    }
}

export const updateisLockedAboutRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`isclocked-abouts/${id}`, 'GET', null, authHeader()).then(res => {
            dispatch(updateisLockedAbout(id));

        })
    }
}
export const updateisLockedAbout = (id) => {
    return {
        type: Types.UPDATE_STATUS_ABOUTS,
        id
    }
}






