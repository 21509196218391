import * as Types from '../../constants/ServiceLaboratoryEntry/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchServiceLaboratoryEntryRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('service-laboratory-entry', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchServiceLaboratoryEntry(res.data));
                }
            })
        }
    }
 
}
export const actFetchServiceLaboratoryEntry = (servicelaboratorys) => {
    return {
        type: Types.FETCH_SERVICE_LABORATORY_ENTRY,
        servicelaboratorys
    }
}

export const deleteServiceLaboratoryEntryRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-service-laboratory-entry/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteServiceLaboratoryEntry(id));
        })
    }
}
export const deleteServiceLaboratoryEntry = (id) => {
    return {
        type: Types.DELETE_SERVICE_LABORATORY_ENTRY,
        id
    }
}

export const addServiceLaboratoryEntryRequest = (servicelaboratorys) => {
    return (dispatch) => {
        return UrlApi('add-service-laboratory-entry', 'POST', servicelaboratorys, authHeader()).then(res => {
            dispatch(addServiceLaboratoryEntry(res.data));
        })
    }
}

export const addServiceLaboratoryEntry = (servicelaboratorys) => {
    return {
        type: Types.ADD_SERVICE_LABORATORY_ENTRY,
        servicelaboratorys
    }
}


export const eidtServiceLaboratoryEntryRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`service-laboratory-entry/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editServiceLaboratoryEntry(res.data));
            }

        })
    }
}
export const editServiceLaboratoryEntry = (servicelaboratorys) => {
    return {
        type: Types.EDIT_SERVICE_LABORATORY_ENTRY,
        servicelaboratorys
    }
}


export const updateServiceLaboratoryEntryRequest = (servicelaboratorys) => {
    return (dispatch) => {
        return UrlApi(`update-service-laboratory-entry/${servicelaboratorys.id}`, 'PUT', servicelaboratorys, authHeader()).then(res => {
            dispatch(updateServiceLaboratoryEntry(res.data));

        })
    }
}
export const updateServiceLaboratoryEntry = (servicelaboratorys) => {
    return {
        type: Types.UPDATE_SERVICE_LABORATORY_ENTRY,
        servicelaboratorys
    }
}
