import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const tieudecls = (state = initialState, action) => {
    switch (action.type) {
        case Types.TIEUDE_CLS_ADMIN:
           state = action.tieudecls;
           return [...state];
           //return state;

        default: return [...state];
        //default: return state;;
    }
}
export default tieudecls;