import * as Types from '../../constants/DSBNAdmin/ActionTypes';
var initialState = [];
const chitietthuocAd = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_THUOC_ADMIN:
           state = action.chitietthuocAd;
            return [...state];

        default: return [...state];
    }
}
export default chitietthuocAd;