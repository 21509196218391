import * as Types from '../../constants/DSBNPhongKham/ActionTypes';
var initialState = [];
const itemEditing = (state = initialState, action) => {
    switch (action.type) {
        case Types.VIEW_BN_PK:
            state = action.dsbnphongkhams;
            return [...state];

        default: return [...state];
    }
}
export default itemEditing;