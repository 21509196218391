import * as Types from '../../constants/ReceiveDocumentImage/ActionTypes';
var initialState = [];

var findIndex = (docimages, id) => {
    var result = -1;
    docimages.forEach((docimage, index) => {
        if (docimage.id === id) {
            result = index;
        }
    })
    return result;
}
const docimages = (state = initialState, action) => {
    var index = -1;
    var { id, docimages } = action;
    switch (action.type) {
        case Types.FETCH_DOC_IMAGE:
            state = action.docimages;
            return [...state];
        case Types.DELETE_DOC_IMAGE:
            index = findIndex(state, id);
            state.splice(index, 1);
            return [...state];
        case Types.ADD_DOC_IMAGE:
            state.push(action.docimages);
            return [...state];

        case Types.UPDATE_DOC_IMAGE:
            index = findIndex(state, docimages.id);
            state[index] = docimages;
            return [...state];
        
        default: return [...state];
    }
}
export default docimages;