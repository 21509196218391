import * as Types from '../../constants/SuggestedServiceReceipt/ActionTypes';
import UrlApi from '../../utils/UrlApi';
import { authHeader } from '../helpers';

export const actFetchSuggServiceRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('suggested-service', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchSuggService(res.data));
                }
            })
        }
    }
 
}
export const actFetchSuggService = (suggestedservices) => {
    return {
        type: Types.FETCH_SUGGESTED_SERVICE,
        suggestedservices
    }
}

export const deleteSuggServiceRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`delete-suggested-service/${id}`, 'DELETE', null, authHeader()).then(res => {
            dispatch(deleteSuggService(id));
        })
    }
}
export const deleteSuggService = (id) => {
    return {
        type: Types.DELETE_SUGGESTED_SERVICE,
        id
    }
}

export const addSuggServiceRequest = (suggestedservices) => {
    return (dispatch) => {
        return UrlApi('add-suggested-service', 'POST', suggestedservices, authHeader()).then(res => {
            dispatch(addSuggService(res.data));
        })
    }
}

export const addSuggService = (suggestedservices) => {
    return {
        type: Types.ADD_SUGGESTED_SERVICE,
        suggestedservices
    }
}


export const eidtSuggServiceRequest = (id) => {
    return (dispatch) => {
        return UrlApi(`suggested-service/${id}`, 'GET', null, authHeader()).then(res => {
            const token = localStorage.getItem('token');
            if (token === null) {
                window.location.href = '/';
            } else {
                dispatch(editSuggService(res.data));
            }

        })
    }
}
export const editSuggService = (suggestedservices) => {
    return {
        type: Types.EDIT_SUGGESTED_SERVICE,
        suggestedservices
    }
}
export const updateSuggServiceRequest = (suggestedservices) => {
    return (dispatch) => {
        return UrlApi(`update-suggested-service/${suggestedservices.id}`, 'PUT', suggestedservices, authHeader()).then(res => {
            dispatch(updateSuggService(res.data));

        })
    }
}
export const updateSuggService = (suggestedservices) => {
    return {
        type: Types.UPDATE_SUGGESTED_SERVICE,
        suggestedservices
    }
}


export const actFetchSuggServiceCateRequest = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        window.location.href = '/';
    }else{
        return (dispatch) => {
            return UrlApi('suggested-service-category', 'GET', null, authHeader()).then(res => {
                if(res.data === 'TOKEN_KHONG_HOP_LE'){
                    localStorage.removeItem("token")
                    window.location.reload(true);
                }else{
                    dispatch(actFetchSuggServiceCate(res.data));
                }
            })
        }
    }
 
}
export const actFetchSuggServiceCate = (suggestedservices) => {
    return {
        type: Types.FETCH_SUGGESTED_SERVICE_CATE,
        suggestedservices
    }
}

