
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactJsAlert from "reactjs-alert";
//import firebase from './../../utils/firebase';
import { userLoginFetch } from './../../actions/AcLogin';
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Pass_Word: "",
      loggedIn: false,
      thongbao: true,
      isDisabled: false,
      Mobile: '',
      Message: '',
      isDangKy: false

    }
  }
  handleChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    })
  }
  loginSubmit = event => {
    event.preventDefault()
    const { Mobile, Pass_Word } = this.state;
    if (Mobile === '' || Pass_Word === '') {
      this.setState({
        Message: 'Vui lòng nhập đầy đủ thông tin'
      })
    } else {
      this.props.userLoginFetch(this.state);
    }
  }

  /* handleSubmitPhone = event => {
     event.preventDefault()
     const { mobile } = this.state;
     if (mobile) {
       this.props.userLoginFetch(this.state);
     }
   }*/
  /*setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          console.log("Captcha Resolved");
          this.handleSubmitPhone();
        },
        defaultCountry: "IN",
      }
    );
  }*/
  /*handleSubmitPhone = e => {
    e.preventDefault();
    this.setUpRecaptcha();
    let phoneNumber = "+84" + this.state.mobile;
    let appVerifier = window.recaptchaVerifier;
     firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
         window.confirmationResult = confirmationResult;
       // console.log("OTP is sent");
      })
      .catch(function (error) {
       // console.log(error);
      });

  }*/

  onSubmitOtp = e => {
    this.setState({
      isDisabled: true
    });
    e.preventDefault();
    let otpInput = this.state.otp;
    let optConfirm = window.confirmationResult;
    optConfirm
      .confirm(otpInput)
      /* .then(function (result) {
         console.log(result);
         // abc = result.user;
       //  this.setState({xacnhanSDT:result.user})
         // let user = result.user;
          //this.props.userLoginFetch(this.state.mobile);
       
       })*/
      .then((result) => {
        this.props.userLoginFetch(this.state);
      })
      .catch(function (error) {
        alert("Nhập sai mã OTP");

      });

  }
  /* handleSubmitClicked() {
     this.setState({
       isDisabled: true
     });
 
     setTimeout(
       function() {
         this.enableComponents()
       }.bind(this),
       3000
     );
   }
   enableComponents() {
     this.setState({
       isDisabled: false
     });
   }
 */
  render() {
    if (this.props.payload.currentUser === 'TAIKHOAN_KHONGTONTAI') {
      return (
        <ReactJsAlert
          status={this.state.thongbao}
          type="warning"   // success, warning, error, info
          title="Tài khoản không tồn tại hoặc đã bị khóa !"
          Close={() => { this.setState({ thongbao: false }); window.location.reload(true); }}
          button='Đăng nhập'
        />

      );
    }
    return (
      <div className="LoginFrm">
        <div className={this.state.isDangKy === true ? 'LoginFrmN' : 'login-box'}>
          <div className="card">
            <div className="login-from login-card-body">
              <p className="title_dangnhap">ĐĂNG NHẬP</p>
              <div className='title_login'>HỒ SƠ KHÁM BỆNH ĐIỆN TỬ</div>
              <div className="Message">{this.state.Message}</div>
              <form className='frmaction' onSubmit={this.loginSubmit}>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" name='Mobile' placeholder='Tên đăng nhập'
                    value={this.state.Mobile}
                    onChange={this.handleChange} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>

                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder='Mật khẩu'
                    name='Pass_Word'
                    value={this.state.Pass_Word}
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block">ĐĂNG NHẬP</button>
                  </div>
                </div>
              </form>
              {/*<div className='txtDangKy'>Bạn chưa có tài khoản <span onClick={()=> this.setState({isDangKy:true}) } >đăng ký</span> tại đây !</div>*/}

              {/*
              <form onSubmit={this.handleSubmitPhone}>
                <div id="recaptcha-container"></div>
                <div className="input-group mb-3">
                  <input type="number" className="form-control" name='mobile'
                    onChange={this.handleChange} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="fa fa-phone-square"></i>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" data-toggle="modal" data-target="#exampleModalLong" className="btn btn-primary btn-block">Đăng nhập</button>
                  </div>
                </div>
              </form>
              <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">Xác nhận</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                      <div className="modal-body">
                        <form onSubmit={this.onSubmitOtp}>
                          <div className="input-group mb-3">
                            <input className="form-control"
                              id="otp"
                              type="number"
                              name="otp"
                              placeholder="OTP"
                              onChange={this.handleChange} />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button type="submit" 
                              disabled={this.state.isDisabled}
                              

                              className="btn btn-primary btn-block">Xác nhận</button>
                            </div>
                          </div>
                        </form>

                      </div>
                     
                  </div>
                </div>
              </div>
               */}

            </div>
          </div>
        </div>
        <div className={this.state.isDangKy === true? 'frmdangky':'frmdangkyfalse'}></div>
      </div>
    )

  }
}
const mapStateToProps = state => {
  return {
    payload: state.payload
  }

}
const mapDispatchToProps = (dispatch, props) => {
  return {
    userLoginFetch: (payload) => {
      dispatch(userLoginFetch(payload));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

